import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Modal from "react-modal";

const customStyles = {
  overlay: {
    backgroundColor: " rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100vh",
    zIndex: "10",
    position: "fixed",
    top: "0",
    left: "0",
  },
  content: {
    width: "80%",
    height: "100%",
    maxWidth: "500px",
    maxHeight: "250px",
    zIndex: "150",
    position: "absolute",
    display: "inlne-flex",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    backgroundColor: "#fff",
    justifyContent: "center",
    overflow: "auto",
  },
};

const ModalUserDelete = ({ isOpen, onRequestClose, handleRefresh }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
      <ModalStyle>
        <div className="modalWrap">
          <div>
            <h2>탈퇴 완료</h2>
            <h3>탈퇴 처리가 성공적으로 완료되었습니다.</h3>
          </div>
          <button onClick={handleRefresh}>확인</button>
        </div>
      </ModalStyle>
    </Modal>
  );
};

export default ModalUserDelete;

export const ModalStyle = styled.div`
  h1,
  h2,
  h3,
  h4,
  ul,
  li,
  button,
  a,
  label {
    font-family: "Pretendard";
    margin: 0;
    padding: 0;
    word-break: keep-all;
    outline: none;
  }
  .modalWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 200px;
  }
  h2 {
    margin: 16px 0;
    font-size: 24px;
    font-weight: 700;
  }
  h3 {
    font-size: 18px;
    font-weight: 500;
  }
  button {
    width: 100%;
    font-size: 20px;
    font-weight: 700;
    background: none;
    outline: none;
    border: none;
  }
`;
