export const WebRoute = {
   HOME: '/',
   
   SERVICE: '/service',
   FAQ: '/faq',
   COMPANY: '/company',
   EVENT : '/event',
   USERDELETE : '/throo/user/unsubscribe',
   
   PROMOTION: '/route/promotion',
   QRCODE: '/route/qrcode',
   FOODIEBOOSTER: '/foodiebooster',

   EVENTKBLIFE: '/kblife',
   EVENTKBLIFECOUPON: '/kblife/coupon',

   EVENTCAFESHOW_THROO: '/cafeshow',
   EVENTCAFESHOW_BEHIND: '/cafeshow/behind',
   EVENTCAFESHOW_OWN: '/cafeshow/own',
   EVENTCAFESHOW_MARKETING01: '/cafeshow/marketing01',
   EVENTCAFESHOW_MARKETING02: '/cafeshow/marketing02',
   EVENTCAFESHOW_MARKETING03: '/cafeshow/marketing03',
   EVENTCAFESHOW_MARKETING04: '/cafeshow/marketing04',
};
