import React from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import '../../../assets/css/font.css';

import { WebRoute } from '../../../navigation/WebRoutes';

// img
import SVG from 'react-inlinesvg';
import closeIcon from "../../../assets/svg/homeMainSvg/closeIcon.svg";
import logo from "../../../assets/svg/homeMainSvg/throologo.svg";
import company from "../../../assets/img/header/h_company.png";
import service from "../../../assets/img/header/h_service.png";
import ceopage from "../../../assets/img/header/h_ceopage.png";
import joinForm from "../../../assets/img/header/h_joinForm.png";
import posDownfrom from "../../../assets/img/header/h_posDown.png";

const HeaderSliderbar = ({ isOpen, toggle }) => {

    const history = useHistory();
    const moveToPath = async (sIndex) => {
        if (sIndex === "CEO") {
            window.open('https://ceo.throo.co.kr', '_blank');
        } else if (sIndex === "HOME") {
            history.push(WebRoute.HOME);
        } else if (sIndex === "SERVICE") {
            history.push({
                pathname: WebRoute.SERVICE,
                state: { showKakao: "false" }
            });
        } 
    }

    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <div className="container">
                <Icon onClick={toggle}>
                    <div className="closeIcon">
                        <SVG src={logo} className="logo" width="63px" height="24px" title="logo" onClick={() => moveToPath("HOME")} />
                        <SVG src={closeIcon} className="icon" width="22px" height="22px" title="closeIcon"/>
                    </div>
                </Icon>
                <SidebarText>
                    <ul>
                        <li><img src={company} alt="회사소개이미지" width="100%" /><Link to={WebRoute.COMPANY}>회사소개</Link></li>
                        <li><img src={service} alt="고객센터이미지" /><Link to={WebRoute.SERVICE}>고객센터</Link></li>
                        <li onClick={() => moveToPath("CEO")}><img src={ceopage} alt="사장님창구 이미지" />사장님창구</li>
                        <li><a href="https://ceo.throo.co.kr/landing" target="_blank"><img src={joinForm} alt="스루소개 이미지" />입점문의</a></li>
                        <li><a href="https://ceo.throo.co.kr/posdownload" target="_blank"><img src={posDownfrom} alt="포스설치 이미지" />포스설치</a></li>
                    </ul>
                </SidebarText>
            </div>
        </SidebarContainer>
    )
}

export default HeaderSliderbar;

export const SidebarContainer = styled.aside`
    * {
        font-family: 'NanumSquare';
        font-weight: 700;
    }

    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    background: #fff;
    transition: 0.5s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
    right: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
    color: #333;

    .container {
        min-width: 100%;
        padding: 0 5vw;
    }
`;


export const Icon = styled.div`
    .closeIcon {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px 0;
    }
`;
export const SidebarText = styled.div`
    a {
        text-decoration: none;
        color: #333;
        font-weight: 900;
    }
    ul,li {
        list-style: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
    }
    ul {
        padding-bottom: 30px;
    }
    li {
        width: 100%;
        font-size: 26px;
        font-weight: 900;
        padding: 18px 0;
        color: #333333;
    }
    li:nth-of-type(1) {
        padding-top: 18px;
        margin-top: 10px;
    }
    li:hover {
        background-color: rgba(196, 196, 196, .5);
    }
    img {
        margin-right: 10px;
        width:46px;
        height: 46px;
    }
    p {
        margin: 0;
        font-weight: 900;
    }
`;
