//cafeshow_스루

import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import "../../assets/css/font.css";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// IMG
import cafeShow01 from "../../assets/img/cafeshow/behind/cafeshow_behind01.png";
import cafeShow02 from "../../assets/img/cafeshow/behind/cafeshow_behind02.png";
import cafeShow03 from "../../assets/img/cafeshow/behind/cafeshow_behind03.png";
import cafeShow04 from "../../assets/img/cafeshow/behind/cafeshow_behind04.png";
import arrowDown from "../../assets/img/cafeshow/throo/arrowDown.png";
import arrowTop from "../../assets/img/cafeshow/throo/arrowTop.png";
import floatingButton from "../../assets/img/cafeshow/behind/floatingButton.png";
import CustomAlert from "../custom_alert";

const Content = (oProps) => {
    const [faqOpen, setFaqOpen] = useState(false);
    const [showSubmitTxt, setShowSubmitTxt] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [consultingTimeValue, setConsultingTimeValue] = useState("");
    const [sotreNameValue, setSotreNameValue] = useState("");
    const [sotreAddressValue, setSotreAddressValue] = useState("");
    const [phoneNumberValue, setPhoneNumberValue] = useState("");
    const [privacy, setPrivacy] = useState(true);
    const [consultingType, setConsultingType] = useState("");
    const consultingFormRef = useRef();

    const sotreNameChange = (event) => {
        setSotreNameValue(event.target.value);
    };
    const sotreAddressChange = (event) => {
        setSotreAddressValue(event.target.value);
    };
    const consultingTimeValueChange = (event) => {
        setConsultingTimeValue(event.target.value);
    };

    const phoneNumberChange = (event) => {
        const value = event.target.value;
        const regex = /^[0-9]*$/;

        let modPhone = value
            .trim()
            .replace(/[^0-9]/g, "")
            .replace(
                /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
                "$1-$2-$3"
            )
            .replace("--", "-");
        setPhoneNumberValue(modPhone);
    };

    const AlertMessage = ({
        consultingType,
        consultingTimeValue,
        sotreNameValue,
        sotreAddressValue,
        phoneNumberValue,
        privacy,
    }) => {
        if (consultingType === "") {
            return <CustomAlert message={"상담 문의 유형을 선택해주세요"} />;
        }
        if (consultingTimeValue === "") {
            return <CustomAlert message={"희망 상담 시간을 입력해주세요"} />;
        }
        if (sotreNameValue === "") {
            return <CustomAlert message={"상호명을 입력해주세요"} />;
        }
        if (sotreAddressValue === "") {
            return <CustomAlert message={"매장위치를 입력해주세요"} />;
        }
        if (phoneNumberValue === "" || phoneNumberValue.length < 12) {
            return <CustomAlert message={"올바른 전화번호를 입력해주세요"} />;
        }
        if (privacy === false) {
            return <CustomAlert message={"개인 정보 활용 동의를 체크해주세요."} />;
        }
        return null;
    };

    const handleSubmit = async () => {
        try {
            if (
                consultingType === "" ||
                consultingTimeValue === "" ||
                sotreNameValue === "" ||
                sotreAddressValue === "" ||
                phoneNumberValue === "" ||
                phoneNumberValue.length < 12 ||
                privacy === false
            ) {
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                }, 1000);
            } else {
                const response = await axios.post(
                    "https://api-behind.ivid.kr/api/event/setSurvey",
                    {
                        type: "cafeshow_behind",
                        "1q1a": consultingType,
                        "2s": consultingTimeValue,
                        name: sotreNameValue,
                        "3s": sotreAddressValue,
                        phone_number: phoneNumberValue.replace(/-/g, ""),
                    }
                );
                console.log("response", response);
                if (response && response.data.success === true) {
                    console.log("response", response.data);
                    setShowSubmitTxt("신청이 완료되었습니다.");
                    window.location.reload();
                } else {
                    console.log("등록실패 사유:", response.data.message);
                    setShowSubmitTxt(response.data.message);
                }
            }
            setTimeout(() => {
                setShowSubmitTxt("");
            }, 1000);
        } catch (error) {
            console.log("API 요청 오류:", error);
        }
    };

    const updateManifest = () => {
        //const oDocument = window.document.getElementById("manifest-placeholder");
        //if (oDocument) {
        //  oDocument.setAttribute("href", process.env.REACT_APP_API_URL + "/web/" + id + "/manifest.json");
        //}
        const manifestDetails = {
            name: "드라이브스루",
            short_name: "드라이브스루",
            start_url: "https://throo.co.kr/cafeshow",
            display: "standalone",
            orientation: "portrait",
            theme_color: "#000000",
            background_color: "#ffffff",
            icons: [
                {
                    src: "https://prd-throo-store-product.s3.ap-northeast-2.amazonaws.com/store-etc/throo_logo.png",
                    sizes: "64x64 32x32 24x24 16x16",
                    type: "image/x-icon",
                },
                {
                    src: "https://prd-throo-store-product.s3.ap-northeast-2.amazonaws.com/store-etc/throo_logo.png",
                    type: "image/png",
                    sizes: "192x192",
                },
                {
                    src: "https://prd-throo-store-product.s3.ap-northeast-2.amazonaws.com/store-etc/throo_logo.png",
                    type: "image/png",
                    sizes: "512x512",
                },
            ],
        };

        const stringManifest = JSON.stringify(manifestDetails);
        const blob = new Blob([stringManifest], { type: "application/json" });
        const manifestURL = URL.createObjectURL(blob);

        const oDocument = window.document.getElementById("manifest-placeholder");
        if (oDocument) {
            oDocument.setAttribute("href", manifestURL);
        }
    };

    useEffect(() => {
        updateManifest();
        setTimeout(() => {
            oProps.fSetDocumentTitle("비하인드");
        }, 150);
    });

    // 아코디언
    const toggleFaq = () => {
        setFaqOpen(!faqOpen);
    };

    // 개인정보 동의
    const PrivacyCheckboxChange = () => {
        setPrivacy(!privacy);
    };

    // 신청폼 이동
    const scrollToConsultingForm = () => {
        if (consultingFormRef.current) {
            consultingFormRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <LandingStyle>
            <div className="landingWrap">
                <img src={cafeShow01} alt="카페쇼_오운 랜딩페이지01" width={"100%"} />
                <img src={cafeShow02} alt="카페쇼_오운 랜딩페이지02" width={"100%"} />
                <img src={cafeShow03} alt="카페쇼_오운 랜딩페이지03" width={"100%"} />
                <img src={cafeShow04} alt="카페쇼_오운 랜딩페이지04" width={"100%"} />
                <div className="formWrap" ref={consultingFormRef}>
                    <div>
                        <div className="formTitle">
                            <h2>상담 문의</h2>
                        </div>
                        <div className="radioWrap">
                            <label>
                                <input
                                    type="checkBox"
                                    value="1"
                                    checked={consultingType === "1"}
                                    onChange={() => setConsultingType("1")}
                                />
                                <p>
                                    바로 우리매장에 설치하고 싶어요.
                                    <br /> <span>( 1:1 담당자 즉시 배정 )</span>
                                </p>
                            </label>
                            <label>
                                <input
                                    type="checkBox"
                                    value="2"
                                    checked={consultingType === "2"}
                                    onChange={() => setConsultingType("2")}
                                />
                                <p>상담후 설치할지 결정하겠습니다.</p>
                            </label>
                            <label>
                                <input
                                    type="checkBox"
                                    value="3"
                                    checked={consultingType === "3"}
                                    onChange={() => setConsultingType("3")}
                                />
                                <p>아직 창업전이지만 상담을 원해요.</p>
                            </label>
                            <label>
                                <input
                                    type="checkBox"
                                    value="4"
                                    checked={consultingType === "4"}
                                    onChange={() => setConsultingType("4")}
                                />
                                <p>
                                    다른 서비스(제품)을 이용중인데 바꿀 수 있는지 상담 원해요.
                                </p>
                            </label>
                        </div>
                    </div>
                    <div>
                        <div className="formTitle">
                            <h2>희망 상담 시간</h2>
                        </div>
                        <input
                            type="text"
                            placeholder="ex) 평일 오전 10시"
                            value={consultingTimeValue}
                            onChange={consultingTimeValueChange}
                        />
                    </div>
                    <div>
                        <div className="formTitle">
                            <h2>사장님 정보</h2>
                        </div>
                        <h3>상호명</h3>
                        <input
                            type="text"
                            placeholder="ex) 스루카페 삼성점"
                            value={sotreNameValue}
                            onChange={sotreNameChange}
                        />
                        <h3>매장 위치</h3>
                        <input
                            type="text"
                            placeholder="ex) 서울 강남구"
                            value={sotreAddressValue}
                            onChange={sotreAddressChange}
                        />
                        <h3>전화번호</h3>
                        <input
                            type="text"
                            inputmode="numeric"
                            placeholder="01012345678 (- 없이 숫자만 기재)"
                            value={phoneNumberValue}
                            onChange={phoneNumberChange}
                        />
                    </div>
                    <div>
                        <label className="checkBoxWrap">
                            <input
                                type="checkbox"
                                checked={privacy}
                                onChange={PrivacyCheckboxChange}
                            />
                            <p className="checkBoxText">
                                [필수] 개인정보 수집 이용 동의 상담을 위한 필수 동의/상담 후
                                폐기됩니다.
                            </p>
                        </label>
                    </div>
                    <button onClick={handleSubmit} className="submitButton">
                        상담 신청하기
                    </button>
                </div>
                <div className="line"></div>
                <div className="faqWrap">
                    <div className="faqTitle" onClick={toggleFaq}>
                        <h2>자주 묻는 질문 FAQ</h2>
                        {!faqOpen ? (
                            <img src={arrowDown} alt="아래화살표" width={"28px"} />
                        ) : (
                            <img src={arrowTop} alt="아래화살표" width={"28px"} />
                        )}
                    </div>
                    <div className={`accordionFaqWrap ${faqOpen ? "open" : ""}`}>
                        <div className="accordionFaqContent">
                            <div
                                style={{
                                    maxHeight: faqOpen ? "380px" : "-10px",
                                    opacity: faqOpen ? 1 : 0,
                                }}
                            >
                                <div>
                                    <h3>Q. 품절 처리 및 가격 변경이 가능한가요?</h3>
                                    <p>
                                        네 가능합니다.
                                        <br /> 사장님 전용 관리사이트에서 품절 처리 및 메뉴 추가,
                                        수정이 가능합니다.
                                    </p>
                                </div>
                                <div>
                                    <h3>Q. 기존에 사용하는 POS와 연동이 가능한가요?</h3>
                                    <p>
                                        네 가능합니다.
                                        <br /> 스루는 POS, 노트북, 태블릿 , 스마트폰 모두 설치
                                        가능합니다.
                                    </p>
                                </div>
                                <div>
                                    <h3>Q. 매출 정산은 언제되나요?</h3>
                                    <p>월요일~일요일의 매출이 매주 수요일에 정산되고 있습니다.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="floatingButton">
                    <button onClick={scrollToConsultingForm}>
                        <img src={floatingButton} alt="가입문의" width={"100%"} />
                    </button>
                </div>
            </div>
            {showAlert && (
                <AlertMessage
                    consultingType={consultingType}
                    consultingTimeValue={consultingTimeValue}
                    sotreNameValue={sotreNameValue}
                    sotreAddressValue={sotreAddressValue}
                    phoneNumberValue={phoneNumberValue}
                    privacy={privacy}
                />
            )}
            {showSubmitTxt !== "" && <CustomAlert message={showSubmitTxt} />}
        </LandingStyle>
    );
};

export default Content;

export const LandingStyle = styled.div`
  p,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  li,
  a,
  button,
  input {
    font-family: "Pretendard";
  }
  .landingWrap {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
  }
  .planContent {
    margin: 60px 20px;
  }
  .manualWrap {
    position: relative;
  }
  .accordionButton img {
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  /* accordion효과 */
  .accordionButton {
    cursor: pointer;
  }
  .accordionContent,
  .accordionFaqContent {
    overflow: hidden;
    transition: max-height 0.8s, opacity 0.8s;
    max-height: 0;
    opacity: 0;
  }
  .accordionWrap.open .accordionContent {
    height: 1200px;
    max-height: 1200px;
    opacity: 1;
  }
  .accordionFaqWrap.open .accordionFaqContent {
    height: 360px;
    max-height: 360px;
    opacity: 1;
  }

  /* form */
  .radioWrap {
    width: 100%;
    padding: 0 3vw;

    label {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 20px;

      input {
        width: 20px;
        min-width: 20px;
        height: 20px;
        margin-right: 12px;
      }
      p {
        margin: 0;
        margin-top: -4px;
        font-size: 18px;
        font-weight: 500;
        color: #111;
        word-break: keep-all;
      }
      span {
        font-size: 14px;
      }
    }
  }
  .formWrap {
    width: 100%;
    padding: 0 3vw;

    input {
      width: 100%;
      padding: 12px;
      border: 1px solid rgba(157, 157, 157, 0.6);
      border-radius: 4px;
    }
    input::placeholder {
      color: #c4c4c4;
      opacity: 1;
    }
    h3 {
      margin: 7px 0;
      font-size: 16px;
      font-weight: 500;
      color: #333;
    }
  }
  .formTitle {
    margin-top: 44px;
    margin-bottom: 12px;
    border-radius: 8px;
    background: #6a7ab1;

    h2 {
      padding: 8px 16px;
      font-size: 18px;
      font-weight: 700;
      color: #fff;
    }
  }
  .checkBoxWrap {
    display: flex;
    margin-top: 44px;

    input {
      width: 24px;
      height: 24px;
      margin-right: 12px;
      border: 1px solid #838383;
    }
    .checkBoxText {
      font-size: 14px;
      font-weight: 500;
      color: #838383;
      word-break: keep-all;
    }
  }
  .submitButton {
    width: 100%;
    padding: 12px;
    margin-bottom: 44px;
    background: #1a7cff;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 8px;
    font-size: 20px;
  }

  /* 자주묻는질문 */
  .line {
    width: 100%;
    height: 5px;
    background: #f1f3f7;
  }
  .faqWrap {
    width: 100%;
    margin-bottom: 100px;
    padding: 36px 3vw;

    .faqTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      cursor: pointer;
    }
    h2 {
      margin: 0;
      font-size: 20px;
      font-weight: 700;
      color: #191f28;
    }
    h3 {
      margin: 23px 0 6px 0;
      font-size: 16px;
      font-weight: 700;
      color: #191f28;
    }
    p {
      margin-left: 17px;
      font-size: 16px;
      font-weight: 400;
      color: #191f28;
    }
  }

  /* floatingButton */
  .floatingButton {
    width: 100%;
    position: fixed;
    bottom: 12px;
    display: flex;
    justify-content: center;

    button {
      width: 100%;
      padding: 0 3vw;
      background: none;
      outline: none;
      border: none;
      max-width: 700px;
    }
  }
`;
