import React from 'react';
import {
    isMobile
} from "react-device-detect";

import M_ceoLanding from "./M_ceoLanding";
import D_ceoLanding from "./D_ceoLanding";

const MainCompany = (oProps) => {
    const companyRender = () => {
        if (isMobile) {
            return <M_ceoLanding {...oProps} />
        }

        return <D_ceoLanding {...oProps} />
    }

    return (
        <>
            {companyRender()}
        </>
    )
}

export default MainCompany;