// bbq랜딩페이지
//foodiebooster?=foodie-report

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { message } from 'antd';
import Header from './header'
import InformationModal from './foodieModal';
import AddressModal from '../../daumPostModal/addressModal';

// img
import foodieBoosterIcon01 from "../../../assets/img/foodieBooster/foodieBoosterIcon01.png";

const Content = (oProps) => {
   const axios = require("axios");
   const [storeName, set_storeName] = useState("");
   const [ceoeName, set_ceoeName] = useState("");
   const [sBusinessNm, set_sBusinessNm] = useState('')
   const [businessNmResult, set_businessNmResult] = useState('')
   const [phoneNumber, set_phoneNumber] = useState("");
   const [sAddress, setAddress] = useState("");
   const [sExtraAddress, setExAddress] = useState("");
   const [posType, set_posType] = useState("");
   const [posId, set_posId] = useState("");
   const [posPassword, set_posPassword] = useState("");
   const [bType, set_bType] = useState('selfStore');
   const [platform, set_platform] = useState('true');
   const [marketing, set_marketing] = useState('true');
   const [deliveryId01, set_deliveryId01] = useState('');
   const [deliveryPassword01, set_deliveryPassword01] = useState('');
   const [deliveryId02, set_deliveryId02] = useState('');
   const [deliveryPassword02, set_deliveryPassword02] = useState('');
   const [delivery03_name, set_delivery03_name] = useState('');
   const [deliveryId03, set_deliveryId03] = useState('');
   const [deliveryPassword03, set_deliveryPassword03] = useState('');
   const [mExperience, set_mExperience] = useState('');
   const [bvisitType01, set_visitType01] = useState('10대');
   const [bvisitType02, set_visitType02] = useState('10대');
   const [bvisitType03, set_visitType03] = useState('10대');
   const [tableType, set_tableType] = useState('1명');
   const [sSituation, set_sSituation] = useState('');
   const [sKeyword, set_sKeyword] = useState('');
   const [bFormComplete, set_bFormComplete] = useState(false);

   const [isCheckedList, setIsCheckedList] = useState({
      checkbox1: false,
      checkbox2: false,
      checkbox3: false
   });
   const [addressModal, setAddressModal] = useState(false);
   const [privacy, set_Privacy] = useState(false);
   const [isModalOpen, setIsModalOpen] = useState(false);

   // input값
   const storeNameChange = (event) => {
      set_storeName(event.target.value);
   }
   const ceoeNameChange = (event) => {
      set_ceoeName(event.target.value);
   }
   const phoneNumberChange = (event) => {
      set_phoneNumber(event.target.value);
   }
   const posTypeChange = (event) => {
      set_posType(event.target.value);
   }
   const posIdChange = (event) => {
      set_posId(event.target.value);
   }
   const posPasswordChange = (event) => {
      set_posPassword(event.target.value);
   }
   const ceoeBusinessNmChange = (e) => {
      set_sBusinessNm(e.target.value);
   }
   const mExperienceChange = (e) => {
      set_mExperience(e.target.value);
   }

   // 주소입력
   const inputExAddress = (e) => {
      setExAddress(e.target.value);
   }
   const addressCloseModal = () => {
      setAddressModal(false);
   };

   // 배달 플랫폼
   const deliveryId01Change = (event) => {
      set_deliveryId01(event.target.value);
   }
   const deliveryPassword01Change = (event) => {
      set_deliveryPassword01(event.target.value);
   }
   const deliveryId02Change = (event) => {
      set_deliveryId02(event.target.value);
   }
   const deliveryPassword02Change = (event) => {
      set_deliveryPassword02(event.target.value);
   }
   const delivery03_name_Change = (event) => {
      set_delivery03_name(event.target.value);
   }
   const deliveryId03Change = (event) => {
      set_deliveryId03(event.target.value);
   }
   const deliveryPassword03Change = (event) => {
      set_deliveryPassword03(event.target.value);
   }

   // radio/checkbox
   const bTypeChange = (event) => {
      set_bType(event.target.value);
   }
   const platformChange = (event) => {
      set_platform(event.target.value);
   }
   const marketingChange = (event) => {
      set_marketing(event.target.value);
   }
   const handleCheckboxChange = (event) => {
      setIsCheckedList({
         ...isCheckedList,
         [event.target.name]: event.target.checked
      });
   };
   const visitType01Change = (event) => {
      set_visitType01(event.target.value);
   }
   const visitType02Change = (event) => {
      set_visitType02(event.target.value);
   }
   const visitType03Change = (event) => {
      set_visitType03(event.target.value);
   }
   const tableTypeChange = (event) => {
      set_tableType(event.target.value);
   }
   const sSituationChange = (event) => {
      set_sSituation(event.target.value);
   }
   const sKeywordChange = (event) => {
      set_sKeyword(event.target.value);
   }
   const PrivacyCheckboxChange = (event) => {
      set_Privacy(event.target.checked);
      if (event.target.checked) {
         setIsModalOpen(true);
      } else {
         setIsModalOpen(false);
      }
   };
   // 사업자번호 확인
   const tradersAuthorize = async () => {
      let oResult = false;
      var sQuery = { "b_no": [sBusinessNm] };

      try {
         const oRes = await axios({
            url: 'https://api.odcloud.kr/api/nts-businessman/v1/status?serviceKey=DL2Li3A7xuz6dJ09MIn6zht9byZsUCZADkDRfzCL9oVfzQPW04EL4mjxumbga30hj%2B73jMC1Q8y8AEJnFSkb9w%3D%3D',
            method: "post",
            timeout: (15 * 1000),
            dataType: "JSON",
            headers: {
               'Content-Type': "application/json",
            },
            data: JSON.stringify(sQuery),
            transformResponse: [(data) => {
               return data;
            }],

         });
         if (oRes.status != undefined && oRes.status === 200) {
            let sRes = oRes.data;
            if (sRes !== undefined && sRes !== null) {
               let oParsedRes = JSON.parse(sRes);
               if (oParsedRes.data !== undefined && oParsedRes.data !== null) {
                  console.log('*', oParsedRes.data[0])
                  if (oParsedRes.data[0].b_stt_cd !== "" && oParsedRes.data[0].b_stt_cd === "01") {
                     oResult = true;
                  }

                  set_businessNmResult(oResult)
               }
            }
         }
      } catch (error) {
         console.log('tradersAuthorize', error);
      }

      return oResult;
   }

   // 개인정보 동의 모달
   const handleCloseModal = () => {
      setIsModalOpen(false);
   };

   // 신청하기 버튼(submit)
   const submit = async () => {
      if (storeName === "" || storeName === undefined) {
         errorMessage('매장명을 입력해주세요.')
      } else if (ceoeName === "" || ceoeName === undefined) {
         errorMessage('대표자명을 입력해주세요.')
      } else if (sBusinessNm === "" || sBusinessNm === undefined) {
         errorMessage('사업자번호를 입력해주세요.')
      } else if (sBusinessNm !== "" && businessNmResult === false) {
         errorMessage('등록되지 않은 사업자등록번호입니다. 사업자 번호는 숫자만 입력해주세요')
      } else if (phoneNumber === "" || phoneNumber === undefined) {
         errorMessage('휴대폰번호를 입력해주세요.')
      } else if (sAddress === "" || sAddress === undefined) {
         errorMessage('매장주소를 입력해주세요.')
      } else if (posType === "" || posType === undefined) {
         errorMessage('포스사를 입력해주세요.')
      } else if (posId === "" || posId === undefined) {
         errorMessage('포스 계정 아이디를 입력해주세요.')
      } else if (posPassword === "" || posPassword === undefined) {
         errorMessage('포스 계정 비밀번호를 입력해주세요.')
      } else if (sSituation === "" || sSituation === undefined) {
         errorMessage('우리 매장의 가장 큰 고민을 입력해주세요.')
      } else if (privacy !== true) {
         errorMessage('개인정보 수집 이용 동의를 해주세요')
      } else {

         let oSendData = {
            "storeName": storeName,
            "ceoName": ceoeName,
            "sBusinessNm": sBusinessNm,
            "phoneNumber": phoneNumber,
            "sAddress": sAddress,
            "sExtraAddress": sExtraAddress,
            "posType": posType,
            "posId": posId,
            "posPassword": posPassword,
            "bType": bType,
            "platform": platform,
            "marketing": marketing,
            "deliveryId01": deliveryId01,
            "deliveryPassword01": deliveryPassword01,
            "deliveryId02": deliveryId02,
            "deliveryPassword02": deliveryPassword02,
            "delivery03_name": delivery03_name,
            "deliveryId03": deliveryId03,
            "deliveryPassword03": deliveryPassword03,
            "bvisitType01": bvisitType01,
            "bvisitType02": bvisitType02,
            "bvisitType03": bvisitType03,
            "tableType": tableType,
            "sSituation": sSituation,
            "sKeyword": sKeyword,
            "marketingOther": mExperience,
            "isCheckedList": isCheckedList,
            "privacy": privacy
         }

         let oRes = await oProps.oManager.fDoAxios("/fb/report", "post", null, oSendData);
         if (oRes && oRes.success === true) {
            successMessage('신청이 완료되었습니다.')
            set_bFormComplete(true);
         } else if (oRes) {
            errorMessage(oRes.result_msg)
         }
      }

   };

   // 완료, 오류 안내창
   const successMessage = (sIndex) => {
      message.success({
         content: sIndex,
         className: 'messageStyles',
         style: {
            fontSize: "14px",
            fontWeight: "500"
         }
      });
   };
   const errorMessage = (sIndex) => {
      message.error({
         content: sIndex,
         duration: '2',
         style: {
            fontFamily: 'Pretendard',
            fontSize: "14px",
            fontWeight: "700",
            padding: '20px 0',
            maxWidth: '80%',
            margin: '0 auto',
            wordBreak: 'keep-all'
         }
      });
   };

   useEffect(() => {
      setTimeout(() => {
         oProps.fSetDocumentTitle("foodiebooster 신청하기");
      }, 150);
   })
   useEffect(() => {
      tradersAuthorize()
   })

   if (bFormComplete === true) {
      return (
         <LandingStyle>
            <div className="foodieBooster">
               <div className="container">
                  <h1 className="foodie-h1">신청이 완료되었습니다.</h1>
               </div>
            </div>
         </LandingStyle>
      )
   }

   return (
      <>

         <LandingStyle>
            <div className='header_sticky' >
               <Header />
            </div>
            <div className="foodieBooster">

               <div className="container">
                  <div className="title">
                     <h1>리포트 신청하기</h1>
                     <img src={foodieBoosterIcon01} alt="확성기 아이콘" width="76px" />
                  </div>
                  <div className="subTitle">
                     <h2>리포트 분석에 필요한 필수 정보이오니 반드시 기입하여 주시기 바랍니다.</h2>
                     <p>위 정보는 암호화되어 저장됩니다.</p>
                  </div>
                  <div className="input_content">
                     <input type="text" placeholder='*매장명' value={storeName} onChange={storeNameChange} />
                     <input type="text" placeholder='*대표자명' value={ceoeName} onChange={ceoeNameChange} />
                     <input type="number" placeholder='*사업자번호 (숫자만 입력해주세요)' value={sBusinessNm} onChange={ceoeBusinessNmChange} />
                     {sBusinessNm.length > 9 && businessNmResult === true && <div className='bSuccess'>사업자 번호가 인증되었습니다.</div>}
                     {sBusinessNm.length > 9 && businessNmResult === false && <div className='bError'>등록되지 않은 사업자등록번호입니다. 숫자만 다시 입력해주세요.</div>}
                     <input type="number" placeholder='*휴대폰 번호' value={phoneNumber} onChange={phoneNumberChange} />
                     <div className="address">
                        <input type="text" value={sAddress} placeholder='*매장 주소' />
                        <button onClick={() => { setAddressModal(true) }}>검색</button>
                     </div>
                     <input placeholder='*매장 상세 주소' value={sExtraAddress} onChange={inputExAddress} />
                     <input type="text" placeholder='*포스사 입력( ex)이지포스 )' value={posType} onChange={posTypeChange} />
                     <input type="text" placeholder='*포스 계정 아이디 (메뉴분석에 반드시 필요한 정보에요)' value={posId} onChange={posIdChange} />
                     <input type="text" placeholder='*포스 계정 비밀번호' value={posPassword} onChange={posPasswordChange} />
                  </div>
                  <h3>*사업자 업종 형태를 선택해주세요.</h3>
                  <div className="choiceWrap">
                     <label>
                        <input
                           type="radio"
                           value="selfStore"
                           checked={bType === 'selfStore'}
                           onChange={bTypeChange}
                        />
                        개인가맹점
                     </label>
                     <label>
                        <input
                           type="radio"
                           value="franchise"
                           checked={bType === 'franchise'}
                           onChange={bTypeChange}
                        />
                        프랜차이즈
                     </label>
                  </div>
                  <h3>*배달 플랫폼을 사용중이신가요?</h3>
                  <div className="choiceWrap">
                     <label>
                        <input
                           type="radio"
                           value="true"
                           checked={platform === 'true'}
                           onChange={platformChange}
                        />
                        사용함
                     </label>
                     <label>
                        <input
                           type="radio"
                           value="false"
                           checked={platform === 'false'}
                           onChange={platformChange}
                        />
                        사용안함
                     </label>
                  </div>
                  {platform === 'true' &&
                     <div className='addItem'>
                        <h4>배달의 민족</h4>
                        <div className="input_content">
                           <input value={deliveryId01} onChange={deliveryId01Change} type="text" placeholder='계정 아이디(정확한 메뉴 및 매출 분석을 위해 필요해요)' />
                           <input value={deliveryPassword01} onChange={deliveryPassword01Change} type="text" placeholder='계정 비밀번호(정확한 메뉴 및 매출 분석을 위해 필요해요)' />
                        </div>
                        <h4>쿠팡이츠</h4>
                        <div className="input_content">
                           <input value={deliveryId02} onChange={deliveryId02Change} type="text" placeholder='계정 아이디(정확한 메뉴 및 매출 분석을 위해 필요해요)' />
                           <input value={deliveryPassword02} onChange={deliveryPassword02Change} type="text" placeholder='계정 비밀번호(정확한 메뉴 및 매출 분석을 위해 필요해요)' />
                        </div>
                        <h4>기타</h4>
                        <div className="input_content">
                           <input value={delivery03_name} onChange={delivery03_name_Change} type="text" placeholder='배달 플랫폼명' />
                           <input value={deliveryId03} onChange={deliveryId03Change} type="text" placeholder='계정 아이디(정확한 메뉴 및 매출 분석을 위해 필요해요)' />
                           <input value={deliveryPassword03} onChange={deliveryPassword03Change} type="text" placeholder='계정 비밀번호(정확한 메뉴 및 매출 분석을 위해 필요해요)' />
                        </div>
                     </div>
                  }
                  <h3>*식당 마케팅 진행 경험이 있으신가요?</h3>
                  <div className="choiceWrap">
                     <label>
                        <input
                           type="radio"
                           value="true"
                           checked={marketing === 'true'}
                           onChange={marketingChange}
                        />
                        있음
                     </label>
                     <label>
                        <input
                           type="radio"
                           value="false"
                           checked={marketing === 'false'}
                           onChange={marketingChange}
                        />
                        없음
                     </label>
                  </div>
                  {marketing === 'true' &&
                     <div className='addItem'>
                        <h3>진행 경험이 있으시다면 어떤 마케팅을 진행하셨나요? </h3>
                        <div className="choiceWrap">
                           <label>
                              <input
                                 type="checkbox"
                                 name="checkbox1"
                                 checked={isCheckedList.checkbox1}
                                 onChange={handleCheckboxChange}
                              />
                              체험단
                           </label>
                           <label>
                              <input
                                 type="checkbox"
                                 name="checkbox2"
                                 checked={isCheckedList.checkbox2}
                                 onChange={handleCheckboxChange}
                              />
                              영수증리뷰
                           </label>
                           <label>
                              <input
                                 type="checkbox"
                                 name="checkbox3"
                                 checked={isCheckedList.checkbox3}
                                 onChange={handleCheckboxChange}
                              />
                              기타
                              <input type="text" value={mExperience} onChange={mExperienceChange} style={{ width: '80%', border: 'none', borderBottom: '1px solid #bbb' }} />
                           </label>
                        </div>
                     </div>
                  }
                  <h3>*매장 방문 고객의 주 연령대가 높은 순서대로 선택해주세요.</h3>
                  <span>1순위</span>
                  <div className="choiceWrap">
                     <label>
                        <input
                           type="radio"
                           value="10대"
                           checked={bvisitType01 === '10대'}
                           onChange={visitType01Change}
                        />
                        10대
                     </label>
                     <label>
                        <input
                           type="radio"
                           value="20대"
                           checked={bvisitType01 === '20대'}
                           onChange={visitType01Change}
                        />
                        20대
                     </label>
                     <label>
                        <input
                           type="radio"
                           value="30대"
                           checked={bvisitType01 === '30대'}
                           onChange={visitType01Change}
                        />
                        30대
                     </label>
                     <label>
                        <input
                           type="radio"
                           value="40대"
                           checked={bvisitType01 === '40대'}
                           onChange={visitType01Change}
                        />
                        40대
                     </label>
                     <label>
                        <input
                           type="radio"
                           value="50대 이상"
                           checked={bvisitType01 === '50대 이상'}
                           onChange={visitType01Change}
                        />
                        50대 이상
                     </label>
                  </div>
                  <span>2순위</span>
                  <div className="choiceWrap">
                     <label>
                        <input
                           type="radio"
                           value="10대"
                           checked={bvisitType02 === '10대'}
                           onChange={visitType02Change}
                        />
                        10대
                     </label>
                     <label>
                        <input
                           type="radio"
                           value="20대"
                           checked={bvisitType02 === '20대'}
                           onChange={visitType02Change}
                        />
                        20대
                     </label>
                     <label>
                        <input
                           type="radio"
                           value="30대"
                           checked={bvisitType02 === '30대'}
                           onChange={visitType02Change}
                        />
                        30대
                     </label>
                     <label>
                        <input
                           type="radio"
                           value="40대"
                           checked={bvisitType02 === '40대'}
                           onChange={visitType02Change}
                        />
                        40대
                     </label>
                     <label>
                        <input
                           type="radio"
                           value="50대 이상"
                           checked={bvisitType02 === '50대 이상'}
                           onChange={visitType02Change}
                        />
                        50대 이상
                     </label>
                  </div>
                  <span>3순위</span>
                  <div className="choiceWrap">
                     <label>
                        <input
                           type="radio"
                           value="10대"
                           checked={bvisitType03 === '10대'}
                           onChange={visitType03Change}
                        />
                        10대
                     </label>
                     <label>
                        <input
                           type="radio"
                           value="20대"
                           checked={bvisitType03 === '20대'}
                           onChange={visitType03Change}
                        />
                        20대
                     </label>
                     <label>
                        <input
                           type="radio"
                           value="30대"
                           checked={bvisitType03 === '30대'}
                           onChange={visitType03Change}
                        />
                        30대
                     </label>
                     <label>
                        <input
                           type="radio"
                           value="40대"
                           checked={bvisitType03 === '40대'}
                           onChange={visitType03Change}
                        />
                        40대
                     </label>
                     <label>
                        <input
                           type="radio"
                           value="50대 이상"
                           checked={bvisitType03 === '50대 이상'}
                           onChange={visitType03Change}
                        />
                        50대 이상
                     </label>
                  </div>
                  <h3>*한 테이블당 평균 고객수가 어떻게 되나요?</h3>
                  <div className="choiceWrap">
                     <label>
                        <input
                           type="radio"
                           value="1명"
                           checked={tableType === '1명'}
                           onChange={tableTypeChange}
                        />
                        1명
                     </label>
                     <label>
                        <input
                           type="radio"
                           value="2~3명"
                           checked={tableType === '2~3명'}
                           onChange={tableTypeChange}
                        />
                        2~3명
                     </label>
                     <label>
                        <input
                           type="radio"
                           value="4명"
                           checked={tableType === '4명'}
                           onChange={tableTypeChange}
                        />
                        4명
                     </label>
                     <label>
                        <input
                           type="radio"
                           value="8명"
                           checked={tableType === '8명'}
                           onChange={tableTypeChange}
                        />
                        8명 이상
                     </label>
                  </div>
                  <h3>*우리 매장의 가장 큰 고민을 말씀해주세요.</h3>
                  <div className='otherOpinion'>
                     <textarea value={sSituation} onChange={sSituationChange} placeholder='예시)오피스 상권에서 카페 운영중인데 주변에 경쟁 카페가 너무 많아요~' />
                  </div>
                  <h3>우리 매장의 메인 메뉴와 주력으로 밀고 싶은 키워드를 알려주세요. </h3>
                  <div className='otherOpinion'>
                     <textarea value={sKeyword} onChange={sKeywordChange} placeholder='메인 메뉴와 주력으로 밀고 싶은 키워드를 작성해주세요.' />
                  </div>
                  <div className="choiceWrap">
                     <label>
                        <input
                           type="checkbox"
                           checked={privacy}
                           onChange={PrivacyCheckboxChange}
                        />
                        [필수] 개인정보 수집 이용 동의
                     </label>
                  </div>

                  <button className='submitBtn' onClick={submit}>신청하기</button>
                  {isModalOpen && <InformationModal isModalOpen={isModalOpen} onClose={handleCloseModal} />}
                  {addressModal && <AddressModal addressModal={addressModal} onClose={addressCloseModal} setAddress={setAddress} setAddressModal={setAddressModal} />}
               </div>

            </div>
         </LandingStyle>
      </>
   )
}

export default Content;


export const LandingStyle = styled.div`
   /* 공통css */
   .original_header {
      display: none!important;
   }
   footer {
      display: none!important;
   }
   .fWeb {
      display: none;
   }
   .fMobile{
      max-width: 100%;
      display:block;
   }

   h1,h2,h3,h4,p,a,span,button,ul,li,input,label,textarea {
      font-family: 'Pretendard';
      color: #000;
   }
   h1 {
      font-size: 26px;
      font-weight: 700;
   }
   h2 {
      margin: 4px 0;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      word-break: keep-all;
   }
   h3,h4,label {
      margin-top: 40px;
      margin-bottom: 6px;
      font-size: 18px;
      font-weight: 400;
      color: #888;
   }
   h4 {
      margin-bottom: 0px;
   }
   label {
      margin-top: 0;
   }
   p {
      font-size: 16px;
      font-weight: 400;
      color: #888;
   }
   span {
      font-size: 16px;
      font-weight: 700;
      color: #888;
      text-decoration: underline;
      text-underline-offset: 4px;
   }
   textarea {
      width: 100%;
      min-height: 200px;
      padding: 20px;
      font-size: 15px;
      font-weight: 400;
      color: #000;
      border-radius: 8px;
   }
   textarea::placeholder {
      padding: 0!important;
   }

   /* header */
   .header_sticky {
      position: sticky;
      top: 0;
      z-index: 999;

      span{
         text-decoration: none;
      }
   }

   /* wrap */
   * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
   }
   .foodieBooster {
      width: 100%;
      min-height: 100vh;
      padding: 5vh 0;
      background: #F8F9FA;

      .container {
         min-width: 100%!important;
         max-width: 100%!important;
         padding: 0 30vw!important;
      }

      .foodie-h1 {
         text-align: center;
      }
   }

   /* title */
   .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
   }

   /* input_content */
   .input_content {
      display: flex;
      flex-direction: column;

      input {
         margin-top: 14px;
         padding: 12px;
         border-radius: 6px;
         border: 1px solid #ECEEF2;
      }
      input::placeholder {
         opacity: 1; 
         font-size: 14px;
         font-weight: 400;
         color: #888888;
      }

      input:-ms-input-placeholder { 
         font-size: 14px;
         font-weight: 400;
         color: #888888;
      }

      input::-ms-input-placeholder { 
         font-size: 14px;
         font-weight: 400;
         color: #888888;
      }
   }
   .choiceWrap {
      width: 100%;
      display: flex;

      label {
         display: flex;
         align-items: center;
         margin-right: 40px;
         white-space: nowrap;

         input {
               margin-right: 5px;
               width: 20px;
               height: 20px;
         }
         
      }
   }
   .choiceWrap:nth-last-of-type(1){
      margin-top: 50px;
   }
   .bSuccess, .bError {
      margin-top: 5px;
      font-family: 'Pretendard';
      font-size: 14px;
      font-weight: 700;
      color: #001E62;
   } 
   .bError {
      color: red;
   }

   /* 매장주소 */
   .address {
      input {
         width: 81%;
         margin-right: 2%;
      }
      button {
         width: 17%;
         padding: 10px;
         border-radius: 6px;
         background: #212634;
         color: #fff;
         font-size: 16px;
         font-weight: 600;
         border: none;
         outline: none;
      }
   }
   
   /* 선택항목 */
   .addItem {
      margin-bottom: 15px;
      padding: 10px 10px 20px 10px;
      background: #fff;
      border-radius: 8px;
      border: 1px solid #ddd;

      .choiceWrap {
         margin-top: 15px;
      }
   }

   /* textarea */
   .otherOpinion {
      min-width: 100%;
      margin-top: 20px;
   }

   /* submitBtn */
   .submitBtn {
      width: 100%;
      position: sticky;
      bottom: 1%;
      margin-top: 10px;
      padding: 15px 0;
      text-align: center;
      background: #1A7CFF;
      font-size: 18px;
      font-weight: 600;
      color:#fff;
      border-radius: 8px;
      border: none;
      outline: none;
   }

   /* 모달 */
   .modalWrap h2 {
      font-size: 16px;
      background-color: red!important;

   }

   /* 모바일 */
   @media screen and (max-width: 1200px) {
      .foodieBooster .container {
         padding: 0 10vw!important;
      }
   }   
   @media screen and (max-width: 1000px) {
      .foodieBooster .container {
         padding: 0 5vw!important;
         height: calc(var(--vh, 1vh) * 100)!important;
      }
      .input_content input::placeholder {
         opacity: 1; 
         font-size: 12px;
         font-weight: 400;
         color: #888888;
      }
      textarea::placeholder {
         font-size: 14px;
         line-height: 20px;
      }

      /* 마케팅 선택항목 */
      .addItem .choiceWrap {
         display: flex;
         flex-direction: column;

         label {
               margin-top: 5px;
         }
      }
      .choiceWrap:nth-last-of-type(4) {
         label {
               margin-right: 10px;
         }
      }
      .choiceWrap:nth-last-of-type(5),.choiceWrap:nth-last-of-type(6),.choiceWrap:nth-last-of-type(7){
         margin-top: 7px;
         label {
               margin-right: 5px;
               font-size: 15px;
         }
      }
      .submitBtn {
         margin-top: 20px;
         margin-bottom: 50px;
      }
   }   
      
   
    
   
`