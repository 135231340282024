import React, { useState } from 'react';
import Modal from 'react-modal';

const mobileStyles = {
    content : {
        position    : 'absolute',
        top         : '22%',
        left        : '17%',
        right       : '17%',
        bottom      : '5%',
        background  : 'transparent',
        border      : 'none',
        opacity: 1, 
        padding: '0',
    },
    overlay: {
        zIndex: 10,
        opacity: 1,
        backgroundColor: "rgba(0,0,0,.75)"
    },
};

const pcStyles = {
    content : {
        position    : 'absolute',
        top         : '20%',
        left        : '40%',
        right       : '40%',
        bottom      : '5%',
        background  : 'transparent',
        border      : 'none',
        opacity: 1,
        padding: 0,
        width: '350px'
    },
    overlay: {
        zIndex: 10,
        opacity: 1,
        backgroundColor: "rgba(0,0,0,.75)"
    },
    
};

Modal.setAppElement('#root');

const AppModal = React.forwardRef((oProps, oRef) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [state, setState] = useState(
        {
            showModal: false,
            viewComp: <div />,
            sTemplate: 'noti'
        }
    )

    React.useImperativeHandle(oRef,() => ({
        _refIsVisible() {
            return modalVisible;
        },
        _refShowModal(bBoolean, ViewComponent, sTemplate) {
            let sDefaultTemplate = 'alert';
            if(sTemplate !== undefined && sTemplate !== ''){
                sDefaultTemplate = sTemplate;
            }

            setState({ viewComp: ViewComponent, sTemplate: sDefaultTemplate });
            setModalVisible(bBoolean);
        },
        _refHideModal(fCb) {
            setModalVisible(false);
        },
            
    }),)

    const _hideSideMenu = () => {
        setModalVisible(false);
    }

    return (
        <div>
        {state.sTemplate === 'mobile' &&
           <>
           <Modal
                appElement={document.getElementById('app')}
                isOpen={modalVisible}
                onRequestClose={() => _hideSideMenu()}
                style={mobileStyles}
            >
                {state.viewComp}

            </Modal>
        </>
        }
        {state.sTemplate === 'PC' &&
            <Modal
                isOpen={modalVisible}
                onRequestClose={() => _hideSideMenu()}
                style={pcStyles}
            >
                {state.viewComp}

            </Modal>
        }
        </div>
    );
})


export default AppModal;

