import React, { useEffect, useState } from 'react';

const CustomAlert = ({ message, onClose }) => {
    return (
        <div style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}> 
            <div className="custom_alert"
                style={{
                    background: '#ff5732',
                    padding: '12px 30px',
                    position: 'fixed',
                    top: '20%',
                    textAlign:'center',
                    borderRadius: 99,
                }}
            >
                <p style={{
                    fontFamily: 'NanumSquare',
                    fontSize: '18px',
                    margin: 0,
                    fontWeight: 700,
                    color: '#fff'
                }}>{message}</p>
            </div>
        </div>
    );
};
export default CustomAlert;

