// bbq랜딩페이지
// /route/promotion?=20210810-burger

import React, { useEffect } from 'react';
import styled from 'styled-components';
import '../../assets/css/font.css';

// IMG
import welcarImg01 from "../../assets/img/landing/welcar01.png";
import welcarImg02 from "../../assets/img/landing/welcar02.png";
import welcarImg03 from "../../assets/img/landing/welcar03.png";
import welcarImg04 from "../../assets/img/landing/welcar04.png";
import welcarImg05 from "../../assets/img/landing/welcar05.png";
import welcarImg06 from "../../assets/img/landing/welcar06.png";

// SVG
import rightArrow from '../../assets/svg/rightArrow.svg'

const Content = (oProps) => {

    useEffect(() => {
        setTimeout(() => {
            oProps.fSetDocumentTitle("스루X웰카 웰컴 이벤트");
        }, 150);
    })

    return (
        <LandingStyle>
            <div className="landgingWelcarImg">
                <div className="container">
                    <div className="content1"><img className="landing01" src={welcarImg01} alt="throo 이용방법" width="100%" /></div>
                    <div className="content2"><img className="landing02" src={welcarImg02} alt="throo 이용방법" width="100%" /></div>
                    <div className="content3"><img className="landing03" src={welcarImg03} alt="throo 이용방법" width="100%" /></div>
                    <div className="content4"><img className="landing04" src={welcarImg04} alt="throo 쿠폰" width="100%" /></div>
                    <div className="content5">
                        <img className="landing05" src={welcarImg05} alt="throo 쿠폰" width="100%" />
                        <div className="phoneNumber">
                            <h2>이벤트 참여하기</h2>
                            <input className="phone" type="text" placeholder="전화번호를 입력해주세요" />
                            <div className="checkBox">
                                <input type="checkbox" />
                                <p>개인정보 수집/이용에 동의합니다.</p>
                            </div>
                        </div>
                        <div className="btn">
                            <button>이벤트 참여하기</button>
                        </div>
                    </div>

                    <div className="content6"><img className="landing06" src={welcarImg06} alt="유의사항" width="100%" /></div>
                </div>
            </div>
        </LandingStyle>
    )
}

export default Content;

export const LandingStyle = styled.div`

    .container {
        margin: 0 auto;
        padding: 0;
        box-sizing: border-box;
        max-width: 1000px;
    }
    
    .content5 {
        max-width: 1000px;
        margin-top: -1px;
        
    }
    .landing05 {
        max-width: 1000px;
        position: absolute;
    }
    .phoneNumber {
        position: relative;
        top: 50px;
        left: 40px;
        max-width: 1000px;
        z-index: 100;
    }

    .landing06 {
        max-width: 1000px;

    }
`