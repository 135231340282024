import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import SVG from 'react-inlinesvg';
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import '../../../screens/home/MainPage';
import styled from 'styled-components';
import { WebRoute } from '../../../navigation/WebRoutes'

// img
import facebookSVG from '../../../assets/svg/facebook.svg';
import naverSVG from '../../../assets/svg/naver.svg';
import instagramSVG from '../../../assets/svg/instagram.svg';

const Footer = (oProps) => {
    const [scrollPosition, setScrollPosition] = useState("footer");
    const [removeKakao, setRemoveKakao] = useState("remove");

    const history = useHistory();

    useEffect(() => {
        history.location.pathname === "/landing" ? setScrollPosition("landing_footer") : setScrollPosition("footer");
        history.location.pathname === "/service" ? setRemoveKakao("kakao_remove") : setRemoveKakao("remove");
    }, []);

    const openKakao = () => {
        if (isMobile) {
            window.open('https://pf.kakao.com/_sxhlvK/chat', '_blank');
        } else {
            window.open('https://pf.kakao.com/_sxhlvK/chat', '_blank', "height=900,width=500,location=no,directories=no,resizable=no,status=no,toolbar=no,menubar=no");
        }
    }

    const openCEOKakao = () => {
        if (isMobile) {
            window.open('https://pf.kakao.com/_tzxkNK/chat', '_blank');
        } else {
            window.open('https://pf.kakao.com/_tzxkNK/chat', '_blank');
        }
    }

    return (
        <D_footer>
            <div className={scrollPosition}>
                <div className={removeKakao} id={'kakaoButton'}>
                    <div id="footer">
                        <div className="fContainer">
                            <div className="footerPage">
                                <div className="footerContent">
                                    <ul className="content">
                                        <li><h3>서비스</h3></li>
                                        <li><p><Link to={WebRoute.COMPANY}>회사소개</Link></p></li>
                                        <li><p><Link to={WebRoute.SERVICE}>고객센터</Link></p></li>
                                    </ul>
                                    <ul className="content">
                                        <li><h3>포스 다운로드</h3></li>
                                        <li><a className="window" href="https://api-pos.throo.kr/throopos/download.html" target="_blank" rel="noreferrer"><p><span>윈도우</span></p></a></li>
                                        <li><a className="android" href="https://play.google.com/store/apps/details?id=kr.throo.pos" target="_blank" rel="noreferrer"><p><span>안드로이드</span></p></a></li>
                                    </ul>
                                    <ul className="content">
                                        <li><h3>앱 다운로드</h3></li>
                                        <li><a className="googleplay" href="https://play.google.com/store/apps/details?id=kr.throo" target="_blank" rel="noreferrer"><p><span>안드로이드</span></p></a></li>
                                        <li><a className="appstore" href="https://apps.apple.com/kr/app/throo/id1531656544" target="_blank" rel="noreferrer"><p><span>IOS</span></p></a></li>
                                    </ul>
                                    <ul className="content">
                                        <li><h3>입점문의</h3></li>
                                        <li><a href="mailto:partners@ivid.kr"><p>메일: <span>partners@ivid.kr</span></p></a></li>
                                        <li><p onClick={openCEOKakao} >카카오톡(사장님): <span>@스루 사장님 창구</span></p></li>
                                    </ul>
                                    <ul className="content">
                                        <li><h3>고객문의</h3></li>
                                        <li><a href="mailto:cs@ivid.kr"><p>메일: <span>cs@ivid.kr</span></p></a></li>
                                        <li><p onClick={openKakao} >카카오톡(고객): <span> @스루 고객센터</span></p></li>
                                    </ul>
                                    <div className="content">
                                        <h3>전화문의</h3>
                                        <p><a href='tel:1670-5324'>1670-5324 (평일 09:00 - 18:00)</a></p>
                                    </div>
                                </div>
                                <div className="companyInfo">
                                    <h1>인비저블아이디어(주)</h1>
                                    <p>주소: 서울특별시 강남구 선릉로 622 (삼성동, 대영빌딩) 2층</p>
                                    <p>전화: 1670-5324</p>
                                    <p>통신판매업신고: 2020-서울서초-3341</p>
                                    <p>사업자등록번호: 159-86-01794, 대표자: 윤언식</p>
                                    <p>© INVISIBLE IDEAS All rights reserved.</p>
                                </div>
                                <div className="sns">
                                    <a href="https://www.facebook.com/throo2020/" target="_blank" aria-label="Facebook" rel="noreferrer">
                                        <SVG src={facebookSVG} width="2vw" height='100%' title="facebookSVG" />
                                    </a>
                                    <a href="https://blog.naver.com/throo2020" target="_blank" aria-label="naver" rel="noreferrer">
                                        <SVG src={naverSVG} width="2vw" height='100%' title="naverSVG" />
                                    </a>
                                    <a href="https://www.instagram.com/throo_official/" target="_blank" aria-label="Instagram" rel="noreferrer">
                                        <SVG src={instagramSVG} width="2vw" height='100%' title="instagramSVG" strokeWidth="2000px" rel="noreferrer" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </D_footer>
    )
}

export default Footer;

export const D_footer = styled.aside`
   html {
        overflow: hidden;
    }

    html,
    body {
        display: block;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        font-family: 'NanumSquare';
    }
    /* reset */
    ul,ol,li {
        font-family: 'Pretendard-Bold';
        text-decoration: none;
        list-style: none;
    }
    a,li,ul,span {color: #888; text-decoration: none; outline: none; font-weight:500; }
    a:hover, a:active {text-decoration: none; color:#666; font-weight:700; }
    
    
    /* footer */
    #footer {
        padding: 60px 0;
        border-top: 1px solid #EBEBEB;
    }
    .fContainer {
        max-width: 1346px;
        margin: 0 auto;
    }
    .footerPage {
        padding: 0 50px;
    }
    .footerContent {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 50px;
    }
    .footerContent ul {
        padding: 0;
    }
    .content h3 {
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: 700;
        color: #000;
    }
    .content p {
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: 500;
        color: #888888;
        cursor: pointer;
    }
    .content li p span:hover {
        color: #666;
        font-weight: 700;
    }

    /* companyInfo */
    .companyInfo {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 50px;
    }
    .companyInfo h1 {
        font-size: 12px;
        font-weight: 700;
        color: #888888;
        line-height: 18px;
    }
    .companyInfo p {
        font-size: 14px;
        font-weight: 500;
        color: #888888;
        line-height: 18px;
    }
    /* sns */
    .sns {
        width: 100%;
    }
    .sns svg {
        margin-right: 6px;
    }

    .main_footer {
        display: block;
    }

    
`