import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ModalUserDelete from "../../components/modal/ModalUserDelete";
import { Button, message, Space } from 'antd';

const UserDelete = (oProps) => {
  const [seconds, setSeconds] = useState(180);
  const [isCounting, setIsCounting] = useState(false);
  const [resend, setResend] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [phoneNumberValue, setPhoneNumberValue] = useState("");
  const [privacy, setPrivacy] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const [phoneNumberErrorText, setPhoneNumberErrorText] = useState("");
  const [authenticationErrorText, setAuthenticationErrorText] = useState("");
  const [privacyErrorText, setPrivacyErrorText] = useState("");
  const [successAuthenticationNumber, setSuccessAuthenticationNumber] = useState(""); //인증번호
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const isValidEmail = (sVal) => {
    let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (sVal.match(validRegex)) {
      return true;
    }
    return false;
  }

  // 이메일
  const emailChange = (event) => {
    const value = event.target.value;
    setEmailValue(value);
  };

  // 휴대폰 번호
  const phoneNumberChange = (event) => {
    const value = event.target.value;

    let modPhone = value
      .trim()
      .replace(/[^0-9]/g, "")
      .replace(
        /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
        "$1-$2-$3"
      )
      .replace("--", "-");
    setPhoneNumberValue(modPhone);
  };

  // 인증번호 입력
  const authenticationNumberChange = (event) => {
    let onlyNumbers = event.target.value.replace(/\D/g, "");
    setSuccessAuthenticationNumber(onlyNumbers);
  };
  // 약관동의
  const privacyCheckboxChange = (event) => {
    setPrivacy(event.target.checked);
  };

  //카운트 다운
  const authenticationNumberSend = async () => {
    if (emailValue === "") {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setEmailErrorText("이메일 주소를 입력해주세요.");
      return;
    }
    if (isValidEmail(emailValue) === false) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setEmailErrorText("올바른 이메일 주소를 입력해주세요.");
      return;
    }
    setEmailErrorText("");

    if (phoneNumberValue !== "" && phoneNumberValue.length > 11) {
      setPhoneNumberErrorText("");
      if (seconds === 0 || !isCounting) {

        const oResponse = await oProps.oManager.fDoAxios(process.env.REACT_APP_API_URL + "/user/sendsms", "post", null, { phonenumber: phoneNumberValue.trim().replace(/[^0-9]/g, ""), deviceuuid: "del:" + phoneNumberValue.trim().replace(/[^0-9]/g, ""), email: emailValue, check_user_exist: true });

        if (oResponse !== undefined) {
          if (oResponse.success === true) {
            setSeconds(180);
            setResend(false);
            setAuthenticationErrorText("");
            setIsCounting((prevIsCounting) => !prevIsCounting);
            message.success('인증번호가 전송되었습니다.');
          } else {
            message.error(oResponse.message);
          }
        }
      }
    } else {
      setPhoneNumberErrorText("올바른 휴대폰 번호를 입력해주세요.");
    }
  };
  const formatTime = () => {
    const minutes = Math.floor(seconds / 60)
      .toString()
      .padStart(2, "0");
    const remainingSeconds = (seconds % 60).toString().padStart(2, "0");
    return `${minutes}:${remainingSeconds}`;
  };

  // 탈퇴하기
  const UserDeleteButton = async () => {
    if (emailValue === "") {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setEmailErrorText("이메일 주소를 입력해주세요.");
      return;
    }

    if (phoneNumberValue === "") {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setPhoneNumberErrorText("휴대폰번호를 입력해주세요.");
      //} else if (successAuthenticationNumber !== "1234") {
      //  window.scrollTo({ top: 0, behavior: "smooth" });
      //  setAuthenticationErrorText("인증번호를 확인해주세요.");
    } else if (privacy === false) {
      setPrivacyErrorText("탈퇴시 유의사항 내용 확인 버튼을 클릭해주세요.");
    } else {

      const oResponse = await oProps.oManager.fDoAxios(process.env.REACT_APP_API_URL + "/web/user/unsubscribe", "post", null, { phonenumber: phoneNumberValue.trim().replace(/[^0-9]/g, ""), deviceuuid: "del:" + phoneNumberValue.trim().replace(/[^0-9]/g, ""), sms_code: successAuthenticationNumber, email: emailValue });

      if (oResponse !== undefined) {
        if (oResponse.success === true) {
          setPhoneNumberErrorText("");
          setAuthenticationErrorText("");
          setPrivacyErrorText("");
          setModalIsOpen(true);
          //message.success(oResponse.message);
        } else {
          message.error(oResponse.message);
        }
      }
    }
  };

  // 탈퇴성공모달
  const closeModal = () => {
    setModalIsOpen(false);
  };

  // 새로고침
  const handleRefresh = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
    window.location.reload();
  };

  useEffect(() => {
    let intervalId;

    if (isCounting) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          } else {
            setIsCounting(false);
            setResend(true);
            setAuthenticationErrorText(
              "입력시간이 초과되었습니다. 재전송 버튼을 클릭해주세요."
            );
            return 0;
          }
        });
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isCounting]);

  return (
    <Style>
      <div className="wrapper">
        <h1>계정 관리</h1>
        <div className="inputWrap">
          <h2>이메일 주소</h2>
          <div className="authenticationWrap">
            <input
              value={emailValue}
              onChange={emailChange}
              placeholder="이메일 주소를 입력해주세요"
              type="text"
            />
          </div>
          <p className="errorText">{emailErrorText}</p>
        </div>
        <div className="inputWrap">
          <h2>휴대폰 번호</h2>
          <div className="authenticationWrap">
            <input
              value={phoneNumberValue}
              onChange={phoneNumberChange}
              placeholder="휴대폰 번호를 입력해주세요"
              type="text"
              inputmode="numeric"
            />
            <button
              onClick={authenticationNumberSend}
              className="authenticationButton"
            >
              인증번호 받기
            </button>
          </div>
          <p className="errorText">{phoneNumberErrorText}</p>
        </div>
        <div className="inputWrap">
          <h2>인증번호 입력</h2>
          <div className="authenticationNumberWrap">
            <div className="authenticationWrap">
              <input
                value={successAuthenticationNumber}
                onChange={authenticationNumberChange}
                placeholder="인증번호를 입력해주세요"
                maxLength={6}
                max={6}
              />
              {isCounting && <p className="titmer">{formatTime()}</p>}
            </div>
            {resend && (
              <button onClick={authenticationNumberSend}>재전송</button>
            )}
          </div>
          <p className="errorText">{authenticationErrorText}</p>
        </div>
        <div className="noticeWrap">
          <h2>유의사항</h2>
          <ul>
            <li>
              스루 회원에서 탈퇴하시면 현재 사용 중이신 계정을 더 이상 사용할 수
              없게 됩니다. 또한 현재 사용 중이신 계정으로 로그인 되어 있던 모든
              디바이스에서 자동으로 로그아웃 됩니다.
            </li>
            <li>
              한 번 삭제된 계정은 이전 상태로 복구할 수 없습니다. 또한 계정이
              삭제되면 해당 계정의 정보 및 사용이력을 더 이상 조회할 수
              없게됩니다.
            </li>
            <li>
              회원탈퇴 시 보유하고 계신 모든 스루 포인트 및 쿠폰은 즉시
              소멸되며, 재가입해도 복원되지 않습니다.
            </li>
          </ul>
        </div>
        <label className="privacyWrap" htmlFor="checkbox">
          <input
            type="checkbox"
            id="checkbox"
            checked={privacy}
            onChange={privacyCheckboxChange}
          />
          <p>
            탈퇴 후 쿠폰 및 포인트가 소멸되는것을 동의하며 위 내용을 모두
            확인했습니다.
          </p>
        </label>
        <p className="errorText">{privacyErrorText}</p>
        <button onClick={UserDeleteButton} className="deleteButton">
          탈퇴하기
        </button>
        <ModalUserDelete
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          handleRefresh={handleRefresh}
        />
      </div>
    </Style>
  );
};

export default UserDelete;

export const Style = styled.aside`
  h1,
  h2,
  h3,
  h4,
  ul,
  li,
  button,
  a,
  label {
    font-family: "Pretendard";
    margin: 0;
    padding: 0;
    word-break: keep-all;
    outline: none;
  }
  .wrapper {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 60px;
    padding: 60px 3vw;
  }
  h1 {
    font-size: 36px;
    font-weight: 700;
  }
  h2 {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 500;
  }
  li {
    font-size: 16px;
  }

  /* inputWrap */
  .inputWrap {
    margin-top: 28px;

    input {
      width: 100%;
      padding: 8px 12px;
      border-radius: 4px;
      border: 1px solid #cecece;
      font-size: 16px;
      font-weight: 500;
      outline: none;
    }
    input::placeholder {
      color: #aaa;
      opacity: 1;
    }
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  /* noticeWrap */
  .noticeWrap {
    margin-top: 28px;

    h2 {
      color: #40977f;
      font-weight: 700;
    }
    ul {
      padding: 12px 16px 12px 32px;
      border: 1px solid #4dd2ae;
      border-radius: 4px;
      background: #ecf5f3;
    }
    li {
      margin-bottom: 12px;
      color: #40977f;
      font-size: 16px;
      font-weight: 400;
    }
  }

  /* privacyWrap */
  .privacyWrap {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    margin-top: 16px;

    input {
      width: 16px;
      height: 16px;
      margin-top: 5px;
    }
    p {
      margin: 0 0 0 12px;
      font-size: 16px;
    }
  }

  .deleteButton {
    width: 100%;
    margin-top: 36px;
    padding: 6px;
    font-size: 24px;
    font-weight: 500;
    border-radius: 4px;
    background: #0a1d42;
    color: #fff;
    border: none;
    outline: none;
  }

  /* 인증번호 */
  .authenticationWrap {
    position: relative;
  }
  .authenticationButton,
  .titmer {
    position: absolute;
    margin: 0;
    padding: 0;
    top: 0;
    right: 0;
    font-size: 14px;
    height: 100%;
  }
  .authenticationButton {
    width: 30%;
    max-width: 100px;
    background: #6490e7;
    border: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #fff;
  }

  /* 인증번호 확인 */
  .authenticationNumberWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 12px;

    .authenticationWrap {
      width: 100%;
    }
    input {
      flex: 1;
    }
    .titmer {
      top: 26%;
      right: 12px;
      color: #ff3542;
      font-size: 14px;
      font-weight: 500;
    }
    button {
      width: 20%;
      max-width: 50px;
      background: none;
      border: none;
      border-bottom: 1px solid #cecece;
      font-size: 14px;
      font-weight: 600;
      white-space: nowrap;
      color: gray;
      outline: none;
    }
  }

  .errorText {
    margin-top: 6px;
    font-size: 14px;
    font-weight: 700;
    color: #ff3542;
  }
`;
