import React from 'react';
import axios from 'axios';
import AppModal from './AppModal';

import ReducerSetter from '../../reducers/ReducerSetter';

const WebManager = (oProps) => {
   const oReducerSetter = React.useRef();
   const oAppModal = React.useRef();

   const _ipAxios = async () => {
      try {
         const response = await axios({
            url: "http://ip-api.com/json",
            method: "get",
            timeout: (15 * 1000),
            headers: { 'Content-Type': 'application/json' },
            transformResponse: [(data) => {
               return data;
            }],
         });
         let data = response.data || {};
         data = JSON.parse(data);

         if (data.status.toString() !== "success") {
            data = "Unauthorized";
         }

         return data;

      } catch (error) {
         return "Unauthorized";
      }
   }

   const _doAxios = async (sUrl, sMethod, sHeader, oData) => {
      let oUserConfig = oReducerSetter.current._refGetUserConfig();
      let oDefHeader = {
         'Content-Type': 'application/json'
      };

      if (sHeader !== undefined && sHeader !== null && typeof sHeader === 'string') {
         if (sHeader === 'login') {
            oDefHeader = {
               'Authorization': oUserConfig.Token,
               'Content-Type': 'application/json',
               'refresh-token': oUserConfig.RefreshToken
            };
         } else if (sHeader === 'multipart') {
            oDefHeader = {
               'Content-Type': 'multipart/form-data'
            };
         }
      }

      let sCallUrl = process.env.REACT_APP_INIT_URL + sUrl;
      if (sUrl.indexOf("http") !== -1) {
         sCallUrl = sUrl;
      }

      try {
         const oResponse = await axios({
            url: sCallUrl,
            method: sMethod,
            timeout: (15 * 1000),
            headers: oDefHeader,
            data: oData,
            transformResponse: [(data) => {
               return data;
            }],
         });
         if (oResponse !== undefined && oResponse.name !== undefined && oResponse.name.toLowerCase() === 'error') {
            if (oResponse.message !== undefined && oResponse.message.toLowerCase().indexOf('401') !== -1) {
               return "Unauthorized";
            }
         }

         if (oResponse.headers !== undefined && oResponse.headers['refreshed-token'] !== undefined) {
            oUserConfig['Token'] = oResponse.headers['refreshed-token'];
            oReducerSetter.current._refSetUserConfig(oUserConfig);
         }

         if (oResponse !== undefined && oResponse.data !== undefined) {
            return JSON.parse(oResponse.data);
         } else {
            return false;
         }

      } catch (err) {
         if (err.message.toLowerCase().indexOf('401') !== -1) {
            return "Unauthorized";
         }
      }

   }

   const _showModal = (bBoolean, ViewComponent, sTemplate) => {
      oAppModal.current._refShowModal(bBoolean, ViewComponent, sTemplate);
   }

   const _hideModal = () => {
      oAppModal.current._refHideModal();
   }

   const _setReduxUserConfig = (oUserConfigData) => {
      if (oReducerSetter.current !== undefined) {
         oReducerSetter.current._refSetUserConfig(oUserConfigData);
      }
      return false;
   }

   const _getReduxUserConfig = () => {
      if (oReducerSetter.current !== undefined) {
         return oReducerSetter.current._refGetUserConfig();
      }
      return false;
   }

   const _getReduxUserBasket = () => {
      if (oReducerSetter.current !== undefined) {
         return oReducerSetter.current._refGetUserBasket();
      }
      return false;
   }

   const _setReduxUserBasket = (oUserBasketData) => {
      oReducerSetter.current._refSetUserBasket(oUserBasketData);
   }

   const oThisManager = {
      fDoAxios: _doAxios,
      fIpAxios: _ipAxios,
      fSetReduxUserConfig: _setReduxUserConfig,
      fGetReduxUserConfig: _getReduxUserConfig,
      fGetReduxUserBasket: _getReduxUserBasket,
      fSetReduxUserBasket: _setReduxUserBasket,

      fShowModal: _showModal,
      fHideModal: _hideModal,

   };

   return (
      <>
         {React.cloneElement(oProps.children, { oManager: oThisManager })}
         <ReducerSetter {...oProps} ref={oReducerSetter} />
         <AppModal {...oProps} ref={oAppModal} />
      </>
   );
}

export default WebManager;