import ReactModal from 'react-modal';
import styled from 'styled-components';
import {
    isMobile
} from "react-device-detect";

function FoodieModal(props) {
    const customStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,.8)',
            overflow: 'hidden',
            height: '100%',
            zIndex: 100
        },
        content: {
            position: 'fixed',
            width: '40%',
            height: '80vh',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            margin: '20px 0'
        }
    };
    const mobileCustomStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,.8)',
            overflow: 'hidden',
            height: '100%',
            zIndex: 100
        },
        content: {
            position: 'fixed',
            width: '100%',
            height: '100vh',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
        }
    };

    const foodieCheckModal = () => {
        if (isMobile) {
            return (
                <ReactModal
            isOpen={props.isModalOpen}
            onRequestClose={props.onClose}
            contentLabel="Modal"
            style={mobileCustomStyles}
        >
            <ModalStyle>
                <div className='modalWrap'>
                    <h2>푸디부스트 개인정보 수집 이용 동의</h2>
                    <p>인비저블아이디어(주) (이하 ‘회사’)은 원활한 서비스 제공을 위해 최소한의 범위 내에서 아래와 같이 개인정보를 수집 이용합니다.</p>

                    <h3>1. 수집 이용 항목</h3>
                    <p>(1) 회사는 회원 가입 시 아이디, 비밀번호, 이름, 닉네임, 생년월일, 성별, 휴대폰번호, 이메일주소, CI, DI, 가게정보(업종, 지역, 시작연도)를 수집 이용합니다.</p>
                    <p>(2) 서비스 이용과정에서 아래와 같은 자동 수집 정보가 생성되어 수집, 저장, 조합, 분석될 수 있습니다.</p>
                    <p>- 접속지 정보(IP주소), 쿠키, 방문기록, 서비스 이용기록, 기기정보(기기고유번호, OS버전, 모델명, 제조사 정보 등), 광고ID</p>

                    <h3>2. 수집 이용 목적</h3>
                    <p>회원가입 및 선택서비스에서 수집한 개인정보는 아래 목적으로 이용됩니다.</p>
                    <p>- 회원 가입 의사 확인, 이용자 식별, 본인확인, 회원제 서비스 제공, 회원관리</p>
                    <p>- 서비스 제공 및 관리(제휴서비스 포함), 서비스 개선, 신규 서비스 개발</p>
                    <p>- 유료서비스 및 상품 구매 및 결제(환불 포함), 배송 서비스(교환/반품/환불 포함)</p>
                    <p>- 정산 대금 지급</p>
                    <p>- 민원처리 및 고객상담</p>
                    <p>- 고지사항 전달</p>
                    <p>- 불법 및 부정이용 방지, 특정금융거래정보의 보고 및 이용 등에 관한 법률에 따른 고객 확인, 의심거래보고 등 자금세탁방지</p>
                    <p>- 서비스 방문 및 이용기록 통계 및 분석</p>
                    <p>- 서비스 만족도 조사 및 관리</p>
                    <p>- 알림 서비스</p>

                    <h3>3. 보유 및 이용 기간</h3>
                    <p>(1) 수집한 개인정보는 회원 탈퇴 시 즉시 파기합니다.</p>
                    <p>(2) 관련 법령에 따라 회원탈퇴 이후에도 보관해야하는 경우에는 법령에서 정한 보관기간을 따릅니다.</p>
                    <p>(3) 회사는 회원가입 남용, 불법 및 부정 이용 방지를 위해 아이디, DI를 회원탈퇴 1년 후 파기합니다.</p>
                    <p>개인정보 수집 이용에 동의하지 않으실 수 있으며, 동의하지 않는 경우 회원가입이 제한됩니다.</p>
                    <p>그 밖의 개인정보 처리에 관한 사항은 개인정보 처리방침에서 확인하실 수 있습니다.</p>
                    <button onClick={props.onClose}>확인</button>
                </div>
            </ModalStyle>
        </ReactModal >
            )
        }

        return (
            <ReactModal
            isOpen={props.isModalOpen}
            onRequestClose={props.onClose}
            contentLabel="Modal"
            style={customStyles}
        >
            <ModalStyle>
                <div className='modalWrap'>
                    <h2>푸디부스트 개인정보 수집 이용 동의</h2>
                    <p>인비저블아이디어(주) (이하 ‘회사’)은 원활한 서비스 제공을 위해 최소한의 범위 내에서 아래와 같이 개인정보를 수집 이용합니다.</p>

                    <h3>1. 수집 이용 항목</h3>
                    <p>(1) 회사는 회원 가입 시 아이디, 비밀번호, 이름, 닉네임, 생년월일, 성별, 휴대폰번호, 이메일주소, CI, DI, 가게정보(업종, 지역, 시작연도)를 수집 이용합니다.</p>
                    <p>(2) 서비스 이용과정에서 아래와 같은 자동 수집 정보가 생성되어 수집, 저장, 조합, 분석될 수 있습니다.</p>
                    <p>- 접속지 정보(IP주소), 쿠키, 방문기록, 서비스 이용기록, 기기정보(기기고유번호, OS버전, 모델명, 제조사 정보 등), 광고ID</p>

                    <h3>2. 수집 이용 목적</h3>
                    <p>회원가입 및 선택서비스에서 수집한 개인정보는 아래 목적으로 이용됩니다.</p>
                    <p>- 회원 가입 의사 확인, 이용자 식별, 본인확인, 회원제 서비스 제공, 회원관리</p>
                    <p>- 서비스 제공 및 관리(제휴서비스 포함), 서비스 개선, 신규 서비스 개발</p>
                    <p>- 유료서비스 및 상품 구매 및 결제(환불 포함), 배송 서비스(교환/반품/환불 포함)</p>
                    <p>- 정산 대금 지급</p>
                    <p>- 민원처리 및 고객상담</p>
                    <p>- 고지사항 전달</p>
                    <p>- 불법 및 부정이용 방지, 특정금융거래정보의 보고 및 이용 등에 관한 법률에 따른 고객 확인, 의심거래보고 등 자금세탁방지</p>
                    <p>- 서비스 방문 및 이용기록 통계 및 분석</p>
                    <p>- 서비스 만족도 조사 및 관리</p>
                    <p>- 알림 서비스</p>

                    <h3>3. 보유 및 이용 기간</h3>
                    <p>(1) 수집한 개인정보는 회원 탈퇴 시 즉시 파기합니다.</p>
                    <p>(2) 관련 법령에 따라 회원탈퇴 이후에도 보관해야하는 경우에는 법령에서 정한 보관기간을 따릅니다.</p>
                    <p>(3) 회사는 회원가입 남용, 불법 및 부정 이용 방지를 위해 아이디, DI를 회원탈퇴 1년 후 파기합니다.</p>
                    <p>개인정보 수집 이용에 동의하지 않으실 수 있으며, 동의하지 않는 경우 회원가입이 제한됩니다.</p>
                    <p>그 밖의 개인정보 처리에 관한 사항은 개인정보 처리방침에서 확인하실 수 있습니다.</p>
                    <button onClick={props.onClose}>확인</button>
                </div>
            </ModalStyle>
        </ReactModal >
        )
    }

    return (
        <>
            {foodieCheckModal()}
        </>
    )
}

export default FoodieModal;

export const ModalStyle = styled.div`
    h1,h2,h3,h4,p,a,button,ul,li,input,label {
        font-family: 'Pretendard';
        color: #000;
    }

    .modalWrap {
        padding: 10px;
    }
    h2 {
        font-size: 25px;
        font-weight: 700;
    }
    h3 {
        margin-top: 40px;
        font-size: 18px;
        font-weight: 600;
    }
    p {
        font-size: 16px;
        font-weight: 400;
        color:#000;
    }
    button {
        width: 100%;
        margin-top: 15px;
        padding: 15px 0;
        text-align: center;
        background: #1A7CFF;
        font-size: 18px;
        font-weight: 600;
        color:#fff;
        border-radius: 8px;
        border: none;
        outline: none;
    }
`