// 사장님창구랜딩페이지
//route/promotion?=kblife-landingPage

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import '../../assets/css/font.css';
import axios from 'axios';

// IMG
import logo from "../../assets/img/landing/kbLife/kb_logo.png";
import banner from "../../assets/img/landing/kbLife/kb_bannre.png";
import moblieBanner from "../../assets/img/landing/kbLife/mob_kb_bannre.png";
import CustomAlert from '../custom_alert';

const Content = (oProps) => {
   const [phoneNumberValue, setPhoneNumberValue] = useState('');
   const [showContent, setShowContent] = useState(true);
   const [showSubmitTxt, setShowSubmitTxt] = useState('');
   const [showAlert, setShowAlert] = useState(false);
   const [successUrl, setSuccessUrl] = useState(false);
   const [sName, set_sName] = useState('');

   const phoneNumberChange = (event) => {
      const value = event.target.value;
      const regex = /^[0-9]*$/;

      //if (value === '' || regex.test(value)) {
      let modPhone = value.trim().replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3").replace("--", "-");
      setPhoneNumberValue(modPhone);
      //}
   };

   const AlertMessage = () => {
      if (phoneNumberValue === "") {
         return (
            <CustomAlert message={"전화번호를 입력해주세요"} />
         )
      }
   }

   const handleCouponSubmit = async () => {
      try {
         if (phoneNumberValue === "") {
            setShowAlert(true);
            setTimeout(() => {
               setShowAlert(false);
            }, 1000);
         } else {
            const urlQuery = new URLSearchParams(oProps.props.location.search);
            if (urlQuery) {
               const sCode = urlQuery.get('code');
               const response = await axios.post("https://api.throo.kr/invite/marketing", {
                  inviter: "kblifepartners",
                  phone_number: phoneNumberValue.replace(/-/g, ''),
                  code: sCode
               })
               if (response && response.data.success === true) {
                  console.log('response', response.data)
                  setShowContent(false);

               } else {
                  console.log("등록실패 사유:", response.data.message);

                  setShowSubmitTxt(response.data.message);
                  setShowContent(true);
               }
            }
         }
         setTimeout(() => {
            setShowSubmitTxt('');
         }, 1000);
      } catch (error) {
         console.log("API 요청 오류:", error);
      }
   }

   const checkCode = async () => {
      const urlQuery = new URLSearchParams(oProps.props.location.search);
      if (urlQuery) {
         const sCode = urlQuery.get('code');
         let sUrl = 'https://api.throo.kr/invite/marketing/kblifelink/vaild';
         //let sUrl = 'http://192.168.168.103:11500/invite/marketing/kblifelink/vaild';
         const response = await axios.post(sUrl, {
            code: sCode
         })
         if (response && response.data.success === true) {
            //console.log('response', response.data);
            setSuccessUrl(true);
            set_sName(response.data.name);
         } else {

         }
      }
   }

   useEffect(() => {
      setTimeout(() => {
         oProps.fSetDocumentTitle("kb라이프파트너스 스루 전용쿠폰팩 발급");
         checkCode();
      }, 150);
   })
   return (
      <LandingStyle>
         <div><h2>마케팅04</h2></div>
      </LandingStyle>
   )
}

export default Content;

export const LandingStyle = styled.div`
    p, a, h1, h2, h3, h4, h5, h6, ul, li, a, button, input {
        font-family: 'NanumSquare';
        transform: skew(-0.1deg);
    }
    .moblie_banner{
        display: none;
    }

    .kb_life_wrap{
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .header {
       width: 100%;
       padding: 28px 36px;
    }
    .kb_logo {
        width: 100%;
        max-width: 255px;
    }
    .content_wrap {
        width: 100%;
        background: #F6F6F6;
        padding: 64px 0 128px 0;
    }
    .content_header {
        width: 70%;
        margin: 0 auto;
        padding: 8px 16px;
        background: #575757;
        border-radius: 4px;

        h1 {
            margin: 0;
            font-size: 28px;
            font-weight: 700;
            color:#fff;
        }
    }
    .content {
        width: 70%;
        margin: 0 auto;
        padding: 96px 128px;
        background: #fff;
        box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.12);
        border-radius: 4px;

        h2 {
            margin-top: 20px;
            font-size: 28px;
            font-weight: 700;
            color:#000;
        }
        h3 {
            margin: 48px 0 32px 0;
            font-size: 20px;
            font-weight: 700;
            color: '#111'
        }
    }
    .customer_info {
        margin: 48px 0 32px 0;

        h2 {
            font-size:  20px;
            font-weight: 700;
            color:#111;
        }
        input {
            width: 100%;
            padding: 12px 17px;
            border: 1px solid #D8D5D2;
            border-radius: 8px;
            outline: none;
            color:#000;
        }
        input::placeholder {
            color: #333333;
            font-weight: 600;
        }
        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
    .cupon_submit,.close_ment_wrap{
        display: flex;
        margin: 0 auto;
        padding: 12px 64px;
        border-radius: 999px;
        background: #FFCC23;
        border: none;
        outline: none;

        h1{
            font-size: 20px;
            font-weight: 700;
            text-align: center;
            color:#111;
            margin: 0;
        }
    }
    .close_ment_wrap {
        width: 100%;
        max-width: 300px;
        justify-content: center;
        align-items: center;
        background: #fff;
        border: 1px solid #FFCC23;
    }
    .error_ment_wrap {
        width: 60%;
        margin: 0 auto;
        margin-top: 150px;
        padding: 20px 64px;
        background: #fff;
        border: 1px solid #FFCC23;
        border-radius: 999px;
        text-align: center;

        h1 {
            margin: 0;
            font-weight: 700;
            font-size: 24px;
            line-height: 34px;
        }
    }
    .notice{
        padding: 24px;
        margin-top: 48px;
        background: #F6F0E7;
        border-radius: 4px;

        h2 {
            margin: 0 0 8px 0;
            font-size: 14px;
            font-weight: 700;
            color:#666;
        }
        ul {
            margin: 0;
            padding: 0 24px ;

            li {
                padding-bottom: 8px;
                font-size: 14px;
                font-weight: 400;
                color:#666
            }
        }
    }
    @media (max-width: 990px){
        .content_wrap{
            padding: 0;
            background: #fff;

            .content_header {
                width: 100%;
            }
        }
        .content {
            width: 100%;
            padding: 59px 3vw;
            box-shadow: 0;
            border-radius: 0;
        }
        .notice{
            padding: 24px 16px;

            ul {
                padding: 0 16px;
            }
        }
        
        .error_ment_wrap{
            width: 90%;
            padding: 30px 10px;

            h1 {
                font-size: 16px;
                line-height: 28px;
            }
        }
    }
    @media (max-width: 700px){
        .web_banner {
            display: none;
        }
        .moblie_banner {
            display: block;
        }
    }
   
`