import React from 'react';
import { connect } from 'react-redux';

import { setUserConfig } from "./UserConfigReducer";

const ReducerSetter = React.forwardRef((oProps, oRef) => {
   React.useImperativeHandle(
      oRef,
      () => ({
         _refSetUserConfig(oUserConfig) {
            oProps.reduxSetUserConfig(oUserConfig);
         },
         _refGetUserConfig() {
            return oProps.UserConfigReducer;
         },
      }),
   )

   return (
      <>
      </>
   )
})

const mapStateToProps = state => {
   return {
      UserConfigReducer: state.UserConfigReducer,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      reduxSetUserConfig: (oData) => dispatch(setUserConfig(oData)),
   };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ReducerSetter);