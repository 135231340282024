// bbq랜딩페이지
//route/promotion?=20210702

import React, {useEffect} from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

// IMG
import pizzahut01 from "../../assets/img/landing/pizzahut01.png";
import pizzahut02 from "../../assets/img/landing/pizzahut02.png";
import pizzahut03 from "../../assets/img/landing/pizzahut03.png";
import pizzahut04 from "../../assets/img/landing/pizzahut04.png";

// SVG
import rightArrow from '../../assets/svg/rightArrow.svg'

const Content = (oProps) => {
    
    useEffect(() => {
        setTimeout(() => {
            oProps.fSetDocumentTitle("첫주문 피자헛 고객이벤트");
        }, 150);
    })

    return (
        <LandingStyle>
            <div className="landgingPizzahut">
                <div className="container">
                    <div className="content1">
                        <img className="landing01" src={pizzahut01} alt="throo 이용방법" width="100%" />
                        <a href="https://throo.page.link/RNhM" className="fixedDown">
                            <button className="download">
                                <p>만원팩 쿠폰 다운받으러가기</p> 
                                <SVG src={rightArrow} width="15px" height="15px" title="클릭버튼" />  
                            </button>
                        </a>
                    </div>
                    <div className="content2"><img className="landing02" src={pizzahut02} alt="throo 이용방법" width="100%" /></div>
                    <div className="content3"><img className="landing03" src={pizzahut03} alt="throo 이용방법" width="100%" /></div>
                    <div className="content4"><img className="landing04" src={pizzahut04} alt="throo 쿠폰" width="100%" /></div>
                    <div className="floating">
                        <button>
                            <a href="https://throo.page.link/RNhM">
                                <p>스루앱 다운받고 주문하기</p>    
                                <SVG src={rightArrow} width="15px" height="15px" title="클릭버튼" />  
                            </a> 
                        </button> 
                    </div>
                      
                </div>
            </div>
        </LandingStyle>
    ) 
}

export default Content;

export const LandingStyle = styled.div`
    .container {
        margin: 0 auto;
        padding: 0;
        box-sizing: border-box;
        max-width: 1000px;
    }
    a {
        color: #fff;
        text-decoration: none;
    }
    svg path {
        stroke: #fff;
    }

    .content1 {
        position: relative;
        margin: 0 auto;
        width: 100%;
        
        .fixedDown {
            max-width: 100%;
            min-width: 100%;
            background-color: blue;
            display: flex;
            justify-content: center;
        }
        .download {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 90%;
            min-width: 90%;
            bottom: 20px;
            background-color: #D0112B;
            padding: 25px 0;
            border: none;
            color: #fff;
            font-size: 18px;
            font-weight: 700;

            p {
                margin: 0 6px 0 0;
            }
        }
    }
    .floating {
        position: fixed;
        bottom: 0px;
        left: 0;
        right: 0;
        width: 100%;

        button {
            width:100%;
            background-color: #D0112B;
            padding: 25px 0;
            outline: none;
            border: none;
            font-size: 18px;
            font-weight: 700;

            p {
                margin: 0 6px 0 0;
            }
        }
        button a {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .content4 {
        padding-bottom: 60px;
        background-color: #F2F2F4;
    }
    .original_header {
        display: none;
    }
    footer {
        display: none;
    }
`