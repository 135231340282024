import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import { WebRoute } from '../../../navigation/WebRoutes'

// IMG
import logo from "../../../assets/img/foodieBooster/foodieBoosterLogo.png";

const Header = ({ scrollToProduct, scrollToReport }) => {
    const Report_web_link = () => {
        window.open('https://bit.ly/3Kt4rki');
    };
    const fReport_moblie_link = () => {
        window.open('https://bit.ly/3nGNjyE');
    };

    return (
        <HeaderStyle>
            <div className='header_wrap' >
                {/* 웹 */}
                <div className="fWeb">
                    <div className="container">
                        <div className="header">
                            <div className='logo'>
                                <Link to={{ pathname: "/foodiebooster" }} >
                                    <img src={logo} alt="푸디 부스터 로고" width="100%" />
                                </Link>
                            </div>
                            <ul className='header_link'>
                                <li>
                                    <Link to={{pathname: "/foodiebooster/company"}} >
                                        <span>기업 소개</span>
                                    </Link>
                                </li>
                                <li onClick={scrollToProduct}>
                                    <Link to={{  pathname: "/foodiebooster", state: {scrollTo: 'product' }}} spy={true} smooth={true}>
                                        <span>상품 소개</span>
                                    </Link>
                                </li>
                                <li onClick={scrollToReport}>
                                    <Link to={{ pathname: "/foodiebooster", state: {scrollTo: 'report' }}} spy={true} smooth={true}>
                                        <span>경영진단 리포트</span>
                                    </Link>
                                </li>
                            </ul>
                            <div className="freeButton" onClick={Report_web_link}>
                                <button>무료 체험하기</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 모바일 */}
                <div className="fMobile" >
                    <div className="container">
                        <div className="header">
                            <div className='logo'>
                                <Link to={{ pathname: "/foodiebooster" }} >
                                    <img src={logo} alt="푸디 부스터 로고" width="100%" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </HeaderStyle >
    )

}
export default Header;

export const HeaderStyle = styled.div`
    /* 공통 css */
    * {
        margin: 0;
        padding: 0;
        
    }
    h1,h2,h3,h4,p,a,span,button,ul,li,input,label,textarea {
      font-family: 'Pretendard';
      color: #000;
    }
    ul,li,ol,a {
        text-decoration: none;
        list-style: none;
    }

    .header_wrap {
        width: 100%;
        background: #fff;
        /* height: 60px; */
    }
    .container {
        min-width: 100%!important;
        padding:  0 20px 0 10%!important;
    }
    .header {
        padding: 13px 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    /* 로고 */
    .logo {
        margin-right: auto;
        img{
            max-width: 180px;
        }
    }
    
    /* 페이지 이동 */
    .header_link {
        display: flex;
        align-items: center;
        margin-right: 135px;

        li {
            margin-left: 42px;
        }
        span{
            font-size: 16px;
            font-weight: 700;
            color: #333;
        }
    }
    /* 무료 체험하기 버튼 */
    .freeButton button {
        padding: 8px 14px;
        background: #1A7CFF;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        border-radius:4px;
        border: none;
        outline: none;
    }

    .fWeb{
        
    }
    /* 모바일 */
    .fMobile{
        display:none;
    }
    @media screen and (max-width: 950px) {
        .fWeb {
            display: none;
        }
        .fMobile{
            max-width: 100%;
            display:block;

            .container{
                padding: 0 4vw;
                margin: 0;
            }
        }
    }
`