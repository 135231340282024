import React, { useState } from "react";
import "../../../screens/home/MainPage";
import styled from "styled-components";
import Modal from "../../modal/ModalMain";
import ModalSmartStore from "../../modal/ModalSmartStore";

// IMG
import banner from "../../../assets/img/homeMain/desktop/main_banner.jpg";
import contentImg01 from "../../../assets/img/homeMain/desktop/D_content1.png";
import contentImg02 from "../../../assets/img/homeMain/desktop/D_content2.png";
import contentImg03 from "../../../assets/img/homeMain/desktop/D_content3.png";
import contentImg04 from "../../../assets/img/homeMain/desktop/D_content4.png";
import contentImg05 from "../../../assets/img/homeMain/desktop/D_content5.png";
import contentImg06 from "../../../assets/img/homeMain/desktop/D_content6.png";

const Content = (oProps) => {
  // 모달창
  const [modal, setModal] = useState(true);
  const [smartStoreModal, setSmartStoreModal] = useState(true);

  return (
    <D_ContentsStyle>
      <div className="mainPage">
        <div className="banner">
          {/* {modal == true ? <Modal /> : null} */}
          <div className="container hide fade_in">
            <h2>대한민국 1등 드라이브스루 앱</h2>
            <h1>
              운전자를 위한 <br />
              스마트한 주문, 스루
            </h1>
            <p>
              언제 어디서나 드라이브스루-
              <br />
              스루를 만나보세요.
            </p>
          </div>
        </div>
        <div className="sBanner">
          <div className="rotating-text">
            <p>우리동네</p>
            <p className="wordTotal">
              <span className="word alizarin">음식점</span>
              <span className="word wisteria">디저트</span>
              <span className="word peter-river">편의점</span>
              <span className="word emerald">꽃가게</span>
              <span className="word sun-flower">밀키트</span>
            </p>
            <p>도 드라이브스루!</p>
          </div>
        </div>
        <div className="content01">
          <div className="container">
            <div className="content">
              <img
                className="hide fade_up"
                src={contentImg01}
                alt="스루 메인지도화면 이미지"
                width="360px"
              />
              <div className="text">
                <h2>
                  근처 매장,
                  <br />
                  지도에서 한눈에
                </h2>
                <p>
                  내 위치 기준으로 주변 매장을 확인해 보세요.
                  <br />
                  매장 준비 시간을 확인하고 주문할 수 있어요!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="content02">
          <div className="container">
            <div className="content">
              <div className="text">
                <h2>
                  이동 경로
                  <br />
                  맞춤 추천
                </h2>
                <p>
                  목적지를 설정해 보세요!
                  <br />
                  가는 길에 스루 가능한 매장을 추천해드려요.
                </p>
              </div>
              <img
                className="hide fade_up"
                src={contentImg02}
                alt="스루 이동경로맞춤추천화면 이미지"
                width="360px"
              />
            </div>
          </div>
        </div>
        <div className="content03">
          <div className="container">
            <div className="content">
              <img
                className="hide fade_up"
                src={contentImg03}
                alt="매장 카테고리 이미지"
                width="400px"
              />
              <div className="text">
                <h2>
                  카테고리별
                  <br />
                  다양하게 검색
                </h2>
                <p>
                  부담 없이, 더 쉽게 선택해 보세요!
                  <br />
                  커피와 음식뿐만 아니라 밀키트와 꽃집도 가능해요.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="content04">
          <div className="container">
            <div className="content">
              <div className="text">
                <h2>
                  내가 원하는
                  <br />
                  픽업 시간 설정
                </h2>
                <p>
                  매장 준비 시간보다 늦게 도착할까 봐 걱정하지 마세요.
                  <br />
                  픽업 시간을 직접 설정할 수 있어요.
                </p>
              </div>
              <img
                className="hide fade_up"
                src={contentImg04}
                alt="스루 픽업시간설정화면 이미지"
                width="390px"
              />
            </div>
          </div>
        </div>
        <div className="content05">
          <div className="container">
            <div className="content">
              <img
                className="hide fade_up"
                src={contentImg05}
                alt="스루 휙오더 이미지"
                width="544px"
              />
              <div className="text">
                <h2>
                  휙오더로
                  <br />더 빠르고, 쉽게
                </h2>
                <p>
                  자주 먹는 메뉴는 휙 오더로 등록해 보세요!
                  <br />홈 화면에서 바로 빠른 결제가 가능해요.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="content06">
          <div className="container">
            <div className="content">
              <div className="text">
                <h2>
                  매장에서 바로픽업
                  <br />
                  워크스루
                </h2>
                <p>
                  드라이브스루 주문 말고 매장에서 받기도 가능해요.
                  <br />
                  걸어서 갈 때도 기다리지 말고 스루로 즉시 받아보세요!
                </p>
              </div>
              <img
                className="hide fade_up"
                src={contentImg06}
                alt="워크스루 오픈 안내 이미지"
                width="500px"
              />
            </div>
          </div>
        </div>
        <div className="fContent">
          <h1 className="hide fade_in">
            이제, 새로운 드라이브스루
            <br />
            <span>스루</span>를 만나보세요!{" "}
          </h1>
        </div>
      </div>
    </D_ContentsStyle>
  );
};

export default Content;

export const D_ContentsStyle = styled.aside`
  html {
    overflow: hidden;
  }

  html,
  body {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: "NanumSquare";
  }

  /* reset */
  ul,
  ol,
  li {
    text-decoration: none;
    list-style: none;
    font-family: "NanumSquare";
  }
  a,
  li,
  ul {
    color: #888;
    text-decoration: none;
    outline: none;
    font-family: "NanumSquare";
  }
  a:hover,
  a:active {
    text-decoration: none;
    color: #666;
    font-weight: 700;
    font-family: "NanumSquare";
  }
  p,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  button,
  input,
  textarea {
    transform: skew(-0.1deg);
    color: #000;
    font-family: "NanumSquare";
  }

  /* 글자 사진 애니메이션 */
  .in_view {
    opacity: 1;
    transition: all 1s ease-in-out;
  }
  .fade_in {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
  .fade_up {
    opacity: 0;
    transform: translate3d(0, 15%, 0);
  }
  .in_view.fade_in,
  .in_view.fade_up {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  /* container */
  .container {
    min-width: 1296px;
    max-width: 1296px;
    margin: 0 auto;
    padding: 0;
  }

  /* banner */
  .banner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    min-height: 100vh;
    height: auto;
    background-image: url(${banner});
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 40px;

    h1 {
      margin: 0px 0 30px 0;
      font-size: 64px;
      font-weight: 900;
      line-height: 82px;
      color: #fff;
    }
    h2 {
      font-size: 20px;
      font-weight: 400;
      color: #eee;
      margin-bottom: 10px;
    }
    p {
      margin: 0;
      font-size: 22px;
      font-weight: 500;
      line-height: 30px;
      color: #fff;
    }
  }

  /* sBanner */
  .sBanner {
    background-color: #171b26;
    width: 100%;
    padding: 177px;

    p {
      font-weight: 600;
      font-size: 36px;
      color: white;
    }
    .wordTotal {
      display: flex;
      justify-content: flex-end;
      width: 180px;
    }

    .rotating-text {
      display: flex;
      justify-content: center;
      color: white;
      position: relative;
    }

    .rotating-text p {
      font-family: "NanumSquare";
      font-size: 60px;
      font-weight: 900;
      margin: 0;
      vertical-align: top;
    }

    .rotating-text p .word {
      position: absolute;
      display: flex;
      opacity: 0;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .rotating-text p .word .letter {
      transform-origin: center center 25px;
    }

    .rotating-text p .word .letter.out {
      transform: rotateX(90deg);
      transition: 0.32s cubic-bezier(0.6, 0, 0.7, 0.2);
    }

    .rotating-text p .word .letter.in {
      transition: 0.38s ease;
    }

    .rotating-text p .word .letter.behind {
      transform: rotateX(-90deg);
    }

    .alizarin {
      color: #fc553f;
    }

    .wisteria {
      color: #f640bf;
    }

    .peter-river {
      color: #00e4bb;
    }

    .emerald {
      color: #d5f003;
    }

    .sun-flower {
      color: #0ab0f6;
    }
  }

  /* 공통 css */
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 135px;
  }
  .text {
    h2 {
      margin-bottom: 20px;
      font-size: 56px;
      font-weight: 900;
      line-height: 72px;
      color: #000;
      text-align: left;
      letter-spacing: -1px;
    }
    p {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      color: #000;
      letter-spacing: -1px;
    }
  }

  /* content01 */
  .content01 {
    padding: 86px 0;
    background-color: #f6f8fb;
  }
  /* content02 */
  .content02 {
    padding: 57px 0;
  }
  /* content03 */
  .content03 {
    padding: 115px 0;
    background-color: #f6f8fb;
  }
  /* content04 */
  .content04 {
    padding: 157px 0;
  }
  /* content05 */
  .content05 {
    padding: 115px 0 92px 0;
    background-color: #f6f8fb;
  }
  /* content06 */
  .content06 {
    padding: 237px 0;
  }

  /* fContent */
  .fContent {
    padding: 159px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #6490e8;

    h1 {
      font-size: 56px;
      font-weight: 900;
      line-height: 72px;
      color: #fff;
      transition-delay: 0.5s;

      span {
        color: #341f1f;
      }
    }
  }
`;
