import React, { Fragment, useEffect, useState } from 'react';
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { connect } from 'react-redux';

import { WebRoute } from './WebRoutes';
import { setUserConfig } from "../reducers/UserConfigReducer";

import ScrollToTop from "../utils/ScrollToTop";
import MainHeader from "../components/header/MainHeader";
import FooterPage from "../components/footer/MainFooter";

import MainPage from "../screens/home/MainPage";
import ServiceScreen from "../screens/service/mainServiceCenter";
import CompanyScreen from "../screens/company/CompanyPage";
import ErrorScreen from "../screens/error/Error";
import EventPage from "../screens/subPage/event/Main";
import QRCodePage from "../screens/subPage/qr/Main";
import UserDelete from "../screens/user/userDelete";
import KbLife from "../screens/subPage/event/KbLife";
import Cafeshow from "../screens/subPage/event/Cafeshow";

// 푸디 부스터
import Foodiepage from "../screens/subPage/foodieBooster/Main";
import Companypage from "../screens/subPage/foodieBooster/Company";
import Reportpage from "../screens/subPage/foodieBooster/Report";

const Routes = (oProps) => {
   const [isHide, setHide] = useState(false);

   const pathConnect = async () => {
      if (window.location.pathname === "/route/promotion" || window.location.pathname === "/route/qrcode" || window.location.pathname === "/foodiebooster" || window.location.pathname === "/foodiebooster/company" || window.location.pathname === "/foodiebooster/report" || window.location.pathname.indexOf('kblife') !== -1 || window.location.pathname.indexOf('cafeshow') !== -1){
         setHide(true);
      } else {
         setHide(false);
      }
   };

   useEffect(() => {
      pathConnect();
   }, []);

   return (
      <BrowserRouter>
         <Fragment>
            <ScrollToTop />
            {!isHide && <MainHeader />}
            <Switch>
               <Route exact path={WebRoute.HOME} component={() => MainPage(oProps)} />
               <Route path={WebRoute.SERVICE} component={() => ServiceScreen(oProps)} />
               <Route path={WebRoute.COMPANY} component={() => CompanyScreen(oProps)} />
               <Route path={WebRoute.PROMOTION} component={() => EventPage(oProps)} />
               <Route path={WebRoute.FOODIEBOOSTER} component={() => FoodieBoosterRoutes(oProps)} />
               <Route path={WebRoute.QRCODE} component={() => QRCodePage(oProps)} />
               <Route path={WebRoute.USERDELETE} component={() => UserDelete(oProps)} />

               <Route path={WebRoute.EVENTKBLIFE} component={(props) => <KbLife {...oProps} props={props} />} />
               <Route path={WebRoute.EVENTKBLIFECOUPON} component={(props) => <KbLife  {...oProps} props={props} />} />

               <Route path={WebRoute.EVENTCAFESHOW_THROO} component={(props) => <Cafeshow {...oProps} props={props} />} />
               <Route path={WebRoute.EVENTCAFESHOW_BEHIND} component={(props) => <Cafeshow  {...oProps} props={props} />} />
               <Route path={WebRoute.EVENTCAFESHOW_OWN} component={(props) => <Cafeshow  {...oProps} props={props} />} />
               <Route path={WebRoute.EVENTCAFESHOW_MARKETING01} component={(props) => <Cafeshow  {...oProps} props={props} />} />
               <Route path={WebRoute.EVENTCAFESHOW_MARKETING02} component={(props) => <Cafeshow  {...oProps} props={props} />} />
               <Route path={WebRoute.EVENTCAFESHOW_MARKETING03} component={(props) => <Cafeshow  {...oProps} props={props} />} />
               <Route path={WebRoute.EVENTCAFESHOW_MARKETING04} component={(props) => <Cafeshow  {...oProps} props={props} />} />
               <Route component={() => ErrorScreen(oProps)} />
            </Switch>
            {!isHide && <FooterPage />}
         </Fragment>
      </BrowserRouter>
   );
};


const FoodieBoosterRoutes = (oProps) => {
   const [isHide, setHide] = useState(false);

   const pathConnect = async () => {
      if (window.location.pathname === "/foodiebooster") {
         setHide(true);
      }
      if (window.location.pathname === "/foodiebooster/company") {
         setHide(true);
      }
      if (window.location.pathname === "/foodiebooster/report") {
         setHide(true);
      }
   };

   useEffect(() => {
      pathConnect();
   }, []);
   return (
      <>
         <Switch>
            <Route path={WebRoute.FOODIEBOOSTER} exact component={(props) => Foodiepage(oProps, props)} />
            <Route path={WebRoute.FOODIEBOOSTER + '/company'} exact component={() => Companypage(oProps)} />
            <Route path={WebRoute.FOODIEBOOSTER + '/report'} exact component={() => Reportpage(oProps)} />
            {/* {!isHide && <FooterPage />} */}
         </Switch>

      </>
   )
}

const mapStateToProps = (state) => {
   return {
      AppConfigReducer: state.AppConfigReducer,
      UserConfigReducer: state.UserConfigReducer
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      reduxSetUserConfig: (oData) => dispatch(setUserConfig(oData))
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);

