import React, { useState } from 'react';
import {
    isMobile
} from "react-device-detect";

import M_HeaderPage from "./mobile/M_header";
import D_HeaderPage from "./desktop/D_header";
import HeaderSliderbar from "./mobile/HeaderSliderbar";

const MainHeader = (oProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }
    const headerRender = () => {
        if (isMobile) {
            return <>
                <HeaderSliderbar isOpen={isOpen} toggle={toggle} oProps={oProps} />
                <M_HeaderPage toggle={toggle} oProps={oProps} />
            </>
        }

        return <D_HeaderPage toggle={toggle} oProps={oProps} />
    }

    return (
        <>
            {headerRender()}
        </>
    )
}

export default MainHeader;