import React, { useEffect } from 'react';
import styled from 'styled-components';
import randtoken from 'rand-token';
import {
    isMobile,
    osName,
} from "react-device-detect";
import '../../../assets/css/font.css';

const PageService = (oProps) => {
    const checkInfo = async () => {
        let oUserConfig = {};
        let token = oProps.UserConfigReducer.Token;

        const currentPath = window.location.pathname;
        if (token === '') {
            token = randtoken.generate(32);
            oUserConfig['Token'] = token;
            oProps.reduxSetUserConfig(oUserConfig);
        }
        await oProps.oManager.fDoAxios("/home/checkInfo", "post", null, { token, isMobile, osName, currentPath });
    }

    const openKakao = () => {
        if (isMobile) {
            window.open('https://pf.kakao.com/_sxhlvK/chat', '_blank');
        } else {
            window.open('https://pf.kakao.com/_sxhlvK/chat', '_blank', "height=900,width=500,location=no,directories=no,resizable=no,status=no,toolbar=no,menubar=no");
        }
    }

    useEffect(() => {
        checkInfo();
        document.title = "고객센터";
        document.querySelector('meta[name="keywords"]').setAttribute("content", '스루 고객센터');
        document.querySelector('meta[name="description"]').setAttribute("content", '불편사항 접수, 장애신고');
        document.querySelector('link[rel="canonical"]').setAttribute("href", 'https://throo.co.kr/service');
        document.querySelector('meta[property="og:title"]').setAttribute("content", '스루 고객센터');
        document.querySelector('meta[property="og:description"]').setAttribute("content", '불편사항 접수, 장애신고');
    }, []);

    return (
        <ServiceStyle>
            <div className="sContent">
                <div className="container">
                    <h2>스루 사용 중<br />문제가 발생하셨나요?</h2>
                    <p>
                        사장님 걱정마세요!<br />
                        시스템의 오류나 장애로 인한 피해라면<br />
                        반드시 보상해드립니다.
                    </p>
                    <div className="time">
                        <p>평일 9시-18시</p>
                    </div>
                </div>
            </div>
            <div className="floating" style={{zIndex:"999"}}>
                <div className="topGradation"></div>
                <div className="bottomBtn">
                    <div className="pBtn"><a href='tel:1670-5324'><p>전화 상담</p></a></div>
                    <button style={{ border: "none", backgroundColor: "#1A7CFF", color: "#fff" }} onClick={openKakao}>카톡 상담</button>
                </div>
            </div>
        </ServiceStyle>
    )
}
export default PageService;

export const ServiceStyle = styled.aside`
    p, a, h1, h2, h3, h4, h5, h6, button, input, label {
        transform: skew(-0.1deg);
        color: #212329;
        font-family: 'NanumSquare';
    }

    html,
    body {
        margin: 0;
        padding: 0;
    }
    .container {
        width: 100%;
        padding : 0 5vw;
        margin: 0 auto;
    } 

    .sContent {
        height: calc(100vh - 170px);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #EEF6F3;
        text-align: center;
    }
    h2 {
        margin-top: 60px;
        font-size: 30px;
        font-weight: 900;
        line-height: 40px;
    }
    p {
        margin: 18px 0 32px 0;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
    }

    .time{
        margin-top: 32px;

        p {
            margin: 0 auto;
            max-width: 170px;
            padding: 14px 26px;
            font-size: 16px;
            font-weight: 900;
            line-height: 34px;
            background-color: #E3EEEF;
            border-radius: 50px;
        } 
    }

    /* 플로팅버튼 */
    .floating {
        position: fixed;
        flex-direction: column;
        bottom:0;
        width: 100%;
        display: flex;
        align-items: center;
        
        .topGradation {
            height: 34px;
            width: 100%;
            background: linear-gradient(to top, rgb(255, 255, 255), rgba(255, 255, 255, 0));
            /* background-color: red; */
        }
        .bottomBtn {
            padding: 15px 5vw 20px 5vw ;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;

            button, .pBtn {
                flex: 0 0 49%;
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 60px;
                border-radius: 6px;
                font-size: 16px;
                font-weight: 900;
                background-color: #fff;
                border: 1px solid #1A7CFF;
                color: #fff;
                outline: none;
            }
            p {
                margin: 0;
                color: #1A7CFF;
            }
        } 
    }
`

