export const Types = {
   SETUSERCONFIG: "user/SETUSERCONFIG"
};

// Initial State
const initialState = {
   LoggedIn: false,
   Token: '',
   RefreshToken: '',
   UUID: "",
   BannerImg: false,
   BannerList: [],
   BannerMobileList: [],
   PopUpClose : "",
   PopUpParam: 0
};

export function setUserConfig(oResult) {
   return {
      type: Types.SETUSERCONFIG,
      payload: {
         result: oResult
      }
   };
}

const UserConfigReducer = (state = initialState, action) => {
   switch (action.type) {
      case Types.SETUSERCONFIG: {
         let oMerged = { ...state, ...action.payload.result };
         return oMerged;
      }
      default: {
         return state;
      }
   }
};

// Exports
export default UserConfigReducer;