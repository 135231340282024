import ReactModal from 'react-modal';
import DaumPostcode from 'react-daum-postcode';
import {
    isMobile
} from "react-device-detect";


function FoodieModal(props) {
    // 주소검색
    const handleComplete = async (data) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }
        props.setAddress(fullAddress);
        props.setAddressModal(false);
    }

    // style
    const customStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,.8)',
            overflow: 'hidden',
            height: '100%',
            zIndex: 100
        },
        content: {
            position: 'fixed',
            width: '50%',
            height: 'auto',
            top: '25%',
            left: '25%',
            right: '25%',
            bottom: '25%',
            margin: '20px 0'
        }
    };
    const mobileCustomStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,.8)',
            overflow: 'hidden',
            height: '100%',
            zIndex: 999999
        },
        content: {
            position: 'fixed',
            width: '100%',
            height: 'auto',
            top: '0',
            left: '0',
            bottom: '0',
        },
        
    };
    const daumPostcodeModal = () => {
        if (isMobile) {
            return (
                <ReactModal
                    isOpen={props.addressModal}
                    onRequestClose={props.onClose}
                    contentLabel="Modal"
                    style={mobileCustomStyles}
                >
                    <DaumPostcode
                        onComplete={handleComplete}
                    />
                </ReactModal>
            )
        }

        return (
            <ReactModal
                isOpen={props.addressModal}
                onRequestClose={props.onClose}
                contentLabel="Modal"
                style={customStyles}
            >
                <DaumPostcode
                    onComplete={handleComplete}
                />
            </ReactModal>
        )
    }

    return (
        <>
            {daumPostcodeModal()}
        </>
    )
}

export default FoodieModal;
