import React from 'react';
import styled from 'styled-components';
import { useHistory } from "react-router-dom";
import SVG from 'react-inlinesvg';
import '../../../screens/home/MainPage';
import '../../../assets/css/font.css';
import './HeaderSliderbar'

import { WebRoute } from '../../../navigation/WebRoutes';

// SVG
import logo from "../../../assets/svg/homeMainSvg/throologo.svg";
import hamburger from "../../../assets/svg/homeMainSvg/hamburgerBtn.svg";


const HeaderPage = ({ toggle, oProps }) => {

    const history = useHistory();
    const moveToPath = async (sIndex) => {
        if (sIndex === "HOME") {
            history.push(WebRoute.HOME);
        } else if (sIndex === "CEO") {
            window.open('https://ceo.throo.co.kr', '_blank');
        }
    }

    return (
        <M_headerStyle>
            <div className="header">
                <div className="hContainer">
                    <div className="heaerContent">
                        <SVG className='logo' onClick={() => moveToPath("HOME")} src={logo} title="스루로고" />
                        <button className='ceoPageBtn' onClick={() => moveToPath("CEO")}>사장님창구</button>
                        <MobileIcon onClick={toggle} >
                            <SVG src={hamburger} width="36px" title="메뉴더보기버튼"  />
                        </MobileIcon>
                    </div>
                </div>
            </div>
        </M_headerStyle>
    )
}

export default HeaderPage;

export const M_headerStyle = styled.aside`
    /* reset */
    ul,ol,li {
        font-family: 'NanumSquare';
        text-decoration: none;
        list-style: none;
    }
    p, a, h1, h2, h3, h4, h5, h6 {
        transform: skew(-0.1deg);
    }

    html,
    body {
        display: block;
        max-width: 100%;
        min-width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        font-family: 'NanumSquare';
    }
    
    /* container */
    .hContainer {
        max-width: 100%;
        height: 60px;
        margin: 0 auto;
    }

    /* header */
    .header {
        position: fixed;
        max-width: 100%;
        min-width: 100%;
        height: 60px;
        padding: 0 5vw;
        background-color: #fff;
        top: 0;
        border-bottom: 1px solid rgba(238, 238, 238, .3);
        z-index: 10;
    }
    .heaerContent {
        max-width: 100%;
        min-width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .logo {
        margin-right: auto;
    }
    .ceoPageBtn {
        margin-right: 15px;
        padding: 6px 10px;
        background: #001E62;
        color: #fff;
        border: none;
        outline: none;
        font-size: 16px;
        font-weight: 700;
        border-radius: 6px;
    }
`

export const MobileIcon = styled.div`
    
    
`;