import React, { useEffect }  from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

// IMG
import landing01 from "../../assets/img/landing/landing01.png";
import landing02 from "../../assets/img/landing/landing02.png";
import landing03 from "../../assets/img/landing/landing03.png";
import landing04 from "../../assets/img/landing/landing04.png";
import landing05 from "../../assets/img/landing/landing05.png";
import landing06 from "../../assets/img/landing/landing06.png";

// SVG
import download from '../../assets/svg/landingDownload.svg';
import floating from '../../assets/svg/floatingDownload.svg';

const Content = (oProps) => {
    
    useEffect(() => {
        document.title = "다운로드";
        document.querySelector('meta[name="keywords"]').setAttribute("content", '스루 앱다운로드');
        document.querySelector('meta[name="description"]').setAttribute("content", '스루앱 다운로드, 스로포스 다운로드 페이지입니다.');
        document.querySelector('link[rel="canonical"]').setAttribute("href", 'https://throo.co.kr/down');
        document.querySelector('meta[property="og:title"]').setAttribute("content", '스루 앱다운로드');
        document.querySelector('meta[property="og:description"]').setAttribute("content", '스루앱 다운로드, 스로포스 다운로드 페이지입니다.');
    }, []);

    return (
        <LandingStyle>
            <div className="landingpage">
                <div className="center">
                    <div className="content1">
                        <img className="landing01" src={landing01} alt="throo 이용방법" width="100%" />
                        <a href="https://throo.page.link/PfBF"><SVG className="download" src={download} width="380px" height='106px' title="혜택다운버튼" /></a>
                        
                    </div>
                    <div className="content2"><img className="landing02" src={landing02} alt="throo 이용방법" width="100%" /></div>
                    <div className="content3"><img className="landing03" src={landing03} alt="throo 이용방법" width="100%" /></div>
                    <div className="content4"><img className="landing04" src={landing04} alt="throo 쿠폰" width="100%" /></div>
                    <div className="content5"><img className="landing05" src={landing05} alt="throo 인기매장" width="100%" /></div>
                    <div className="content6"><img className="landing06" src={landing06} alt="throo어플 사용법" width="100%" /></div>
                    <a href="https://throo.page.link/PfBF"><SVG className="floating" src={floating} width="852px" height='130px' title="혜택다운고정버튼" /></a>
                    
                </div>
            </div>
        </LandingStyle>
    ) 
}

export default Content;

export const LandingStyle = styled.div`
    .center {
        margin: 0 auto;
        padding: 0;
        box-sizing: border-box;
        max-width: 1000px;
    }
    .content1 {
        position: relative;
        margin: 0 auto;
        
        .download {
            position: absolute;
            bottom: 107px;
            left: 75px;
            right: 0;
        }
    }
    .floating {
        position: fixed;
        max-width: 100%;
        height: auto;
        bottom: 0%;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .original_header {
        display: none;
    }
    footer {
        display: none;
    }

    @media (max-width: 900px){
        .content1 {
            .download {
                width: 230px;
                left: 60px;
            }
        }
    }

    @media (max-width: 700px){
        .content1 {
            .download {
                bottom: 50px;
                left: 40px;
                right: 0;
            }
        }
    }

    @media (max-width: 550px){
        .content1 {
            .download {
                width: 180px;
                left: 30px;
                bottom: 30px;
            }
        }
    }
    @media (max-width: 450px){
        .content1 {
            .download {
                bottom: 15px;
            }
        }
    }
`