// bbq랜딩페이지
//route/promotion?=20230131-youtubeEvent

import React, { useEffect } from 'react';
import styled from 'styled-components';

// IMG
import youtube01 from "../../assets/img/landing/youtube_landing01.png";
import youtube02 from "../../assets/img/landing/youtube_landing02.png";
import youtube03 from "../../assets/img/landing/youtube_landing03.png";
import youtube04 from "../../assets/img/landing/youtube_landing04.png";


const Content = (oProps) => {

    useEffect(() => {
        setTimeout(() => {
            oProps.fSetDocumentTitle("장사 권프로X스루 입점 EVENT");
        }, 150);
    })

    return (
        <LandingStyle>
            <div className="landgingyoutube">
                <div className="container">
                    <div className="content1"><img className="landing01" src={youtube01} alt="유튜브 장사 권프로 입점 이벤트" width="100%" /></div>
                    <div className="content2"><img className="landing02" src={youtube02} alt="유튜브 장사 권프로 입점 이벤트" width="100%" /></div>
                    <div className="content3"><img className="landing03" src={youtube03} alt="유튜브 장사 권프로 입점 이벤트" width="100%" /></div>
                    <div className="content4"><img className="landing04" src={youtube04} alt="유튜브 장사 권프로 입점 이벤트" width="100%" /></div>
                    <div className="floating">
                        <button>
                            <a href="http://bit.ly/40qPJAu" target="_blank">
                                <p>입점 신청하러 가기</p>
                            </a>
                        </button>
                    </div>
                </div>
            </div>
        </LandingStyle>
    )
}

export default Content;

export const LandingStyle = styled.div`
    .container {
        margin: 0 auto;
        padding: 0;
        box-sizing: border-box;
        max-width: 1000px;
    }
    a {
        color: #fff;
        text-decoration: none;
    }

    .content1 {
        position: relative;
        margin: 0 auto;
        width: 100%;
        
        .fixedDown {
            max-width: 100%;
            min-width: 100%;
            background-color: blue;
            display: flex;
            justify-content: center;
        }
        .download {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 90%;
            min-width: 90%;
            bottom: 20px;
            background-color: #D0112B;
            padding: 25px 0;
            border: none;
            color: #fff;
            font-size: 18px;
            font-weight: 700;

            p {
                margin: 0 6px 0 0;
            }
        }
    }
    .floating {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 60%;
        min-width: 300px;
        max-width: 800px;
        margin: 0 auto;
        margin-bottom: 40px;

        button {
            width:100%;
            background-color: #1A7CFF;
            padding: 18px 42px;
            outline: none;
            border: none;
            font-size: 20px;
            font-weight: 800;
            border-radius: 6px
        }
        button a p{
            margin:0;
        }
    }
    
    .original_header {
        display: none;
    }
    footer {
        display: none;
    }
`