import React, { useState, useEffect } from 'react';
import KblifeLanding from '../../../components/event/landing_kblife';
import KblifeLandingManager from '../../../components/event/landing_kblifeManager';

const EventPage = (oProps) => {

   useEffect(() => {
      document.title = "첫주문 고객이벤트";
      document.querySelector('meta[name="keywords"]').setAttribute("content", '스루, throo, 드라이브스루, 드라이브스루 솔루션, drive thru, throo, drive through');
      document.querySelector('meta[name="description"]').setAttribute("content", '드라이브스루 무료 솔루션 - 스루의 홈페이지 입니다.');
      document.querySelector('link[rel="canonical"]').setAttribute("href", 'https://throo.co.kr');
      document.querySelector('meta[property="og:title"]').setAttribute("content", "'스루'만 있으면 모든 곳이 드라이브스루");
      document.querySelector('meta[property="og:description"]').setAttribute("content", '드라이브스루 무료 솔루션 - 스루의 홈페이지 입니다.');
   })

   const setDocumentTitle = (sTitle) => {
      document.title = sTitle;
   }

   useEffect(() => {

   }, [])

   if (oProps && oProps.props && oProps.props.location) {
      if (oProps.props.location.pathname.indexOf('/kblife/coupon') > -1) {
         return (
            <div>
               <KblifeLanding fSetDocumentTitle={setDocumentTitle} {...oProps} />
            </div>
         )
      }
   }

   return (
      <div>
         <KblifeLandingManager fSetDocumentTitle={setDocumentTitle} {...oProps} />
      </div>
   )
}

export default EventPage;
