// 노량진수산시장 5월이벤트
//route/promotion?=20220429-fishmarketEvent

import React, { useEffect } from 'react';
import styled from 'styled-components';

// IMG
import fishmarket01 from "../../assets/img/landing/fishmarket2_01.png";
import fishmarket02 from "../../assets/img/landing/fishmarket03.png";
import fishmarket03 from "../../assets/img/landing/fishmarket2_02.png";
import fishmarket04 from "../../assets/img/landing/fishmarket04.png";
import fishmarket05 from "../../assets/img/landing/fishmarket05.png";
import fishmarket06 from "../../assets/img/landing/fishmarket2_03.png";
import fishmarket07 from "../../assets/img/landing/fishmarket06.png";
import fishmarketButton from "../../assets/img/landing/fishmarketButton.png";
import floatingButton from "../../assets/img/landing/floatingButton.png";



const Content = (oProps) => {

    useEffect(() => {
        setTimeout(() => {
            oProps.fSetDocumentTitle("스루X노량진수산시장 만원회 행복");
        }, 150);
    })

    return (
        <LandingStyle>
            <div className="landging">
                <div className="container">
                    <img src={fishmarket01} alt="만원회이벤트 메인배너" width="100%" />
                    <img src={fishmarket02} alt="만원회이벤트 상품안내" width="100%" />
                    <img src={fishmarket03} alt="만원회이벤트 매장안내" width="100%" />
                    <div class="appInstall">
                        <img src={fishmarket04} alt="노량진수산시장 검색 안내" width="100%" />
                        <button><a href="https://throo.page.link/apoY"><img src={fishmarketButton} alt="스루앱바로가기 버튼" width="90%" /></a></button>
                    </div>
                    <img src={fishmarket05} alt="경품이벤트 안내" width="100%" />
                    <img src={fishmarket06} alt="만원회이벤트 안내사항" width="100%" />
                    <img src={fishmarket07} alt="만원회이벤트 안내사항" width="100%" />
                </div>
                <div className="floating">
                    <button><a href="https://throo.page.link/apoY"><img src={floatingButton} alt="스루앱바로가기 버튼" width="100%" /></a></button>
                </div>
                
            </div>
        </LandingStyle>
    )
}
export default Content;

export const LandingStyle = styled.div`
    .container {
        margin: 0 auto;
        padding: 0;
        box-sizing: border-box;
        max-width: 1000px;
    }
    a {
        color: #fff;
        text-decoration: none;
    }
   .original_header {
        display: none;
    }
    footer {
        display: none;
    }

    img {
        margin-top: -8px;
    }

    .appInstall {
        position: relative;
    }

    button {
        position: absolute;
        bottom: 5%;
        left: 0;
        right: 0;
        border: none;
        outline: none;
    }
    .floating {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;

        button {
            background: none;
            outline: none;
            padding: 0;
        }
    }
   
    
` 