import React from 'react';
import { Result, Button, Typography } from 'antd';
import { useHistory } from "react-router-dom";
import { CloseCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import 'antd/dist/antd.css';

const { Paragraph } = Typography;

const ErrorScreen = () => {
    const history = useHistory();

    const goBack = () => {
        history.goBack();
    }

    return (
        <Style>
            <Result
                status="error"
                title="잘못된 접근입니다"
                extra={[
                    <Button type="primary" key="console" onClick={() => goBack()}>돌아가기</Button>
                ]}
            >
                <div className="desc">
                    <Paragraph>
                        <CloseCircleOutlined className="site-result-demo-error-icon" style={{marginRight: "1%"}}/> 
                        혹시 잘못된 주소를 입력하셨나요? 정확한 주소로 접근해주세요
                    </Paragraph>
                    <Paragraph>
                        <CloseCircleOutlined className="site-result-demo-error-icon" style={{marginRight: "1%"}}/> 
                        네트워크에러입니다 잠시 후 다시 시도바랍니다
                    </Paragraph>
                </div>
            </Result>
        </Style>
    )
};

const Style = styled.div`
    padding-top: 20%;
    margin-bottom: 20%;
    height: 80vh;
    justify-content: center;
    align-items: center;

    .desc {
        padding: 5%;
    }
`;

export default ErrorScreen;