import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import {
    isMobile,
    osName,
} from "react-device-detect";
import randtoken from 'rand-token';

import Landing20210410 from '../../../components/qrcode/20210410';

const QRCODEPage = (oProps) => {
    const [sPosition, setPosition] = useState("");

    const history = useHistory();

    const checkInfo = async () => {
        let oUserConfig = {};
        let token = oProps.UserConfigReducer.Token;
  
        const currentPath = window.location.pathname;
        if(token === ''){
           token = randtoken.generate(32);
           oUserConfig['Token'] = token;
           oProps.reduxSetUserConfig(oUserConfig);
        }
        await oProps.oManager.fDoAxios("/home/checkInfo", "post", null, {token, isMobile, osName, currentPath});
    }

    useEffect(() => {
        checkInfo();
        if (history.location.search != null && history.location.search != undefined) {
            let str = history.location.search;
            str = str.substr(2, str.length);
            setPosition(str.toString());
        }
    }, [])

    return (
        <div>
            {sPosition === "20210410" && <Landing20210410 />}
        </div>
    )
}

export default QRCODEPage;
