// 사장님창구랜딩페이지
//route/promotion?=kblife-landing-managePage

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "../../assets/css/font.css";
import axios from "axios";

// IMG
import logo from "../../assets/img/landing/kbLife/kb_logo.png";
import banner from "../../assets/img/landing/kbLife/kb_bannre.png";
import moblieBanner from "../../assets/img/landing/kbLife/mob_kb_bannre.png";
import CustomAlert from "../../components/custom_alert";

const Content = (oProps) => {
  const [classValue, setClassValue] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [phoneNumberValue, setPhoneNumberValue] = useState("");
  const [showContent, setShowContent] = useState(true);
  const [showSubmitTxt, setShowSubmitTxt] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [privacy, set_Privacy] = useState(false);

  const phoneClassChange = (event) => {
    setClassValue(event.target.value);
  };
  const phoneNameChange = (event) => {
    setNameValue(event.target.value);
  };
  const phoneNumberChange = (event) => {
    const value = event.target.value;
    const regex = /^[0-9]*$/;

    //if (value === '' || regex.test(value)) {
    let modPhone = value
      .trim()
      .replace(/[^0-9]/g, "")
      .replace(
        /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
        "$1-$2-$3"
      )
      .replace("--", "-");
    setPhoneNumberValue(modPhone);
    //}
  };

  const PrivacyCheckboxChange = (event) => {
    set_Privacy(event.target.checked);
  };

  console.log("privacy", privacy);
  const handleCouponLinkSubmit = async () => {
    try {
      if (classValue === "") {
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 1000);
      } else if (nameValue === "") {
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 1000);
      } else if (phoneNumberValue === "") {
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 1000);
      } else if (privacy === false) {
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 1000);
      } else {
        const response = await axios.post(
          "https://api.throo.kr/invite/marketing/kblifelink",
          {
            class: classValue,
            name: nameValue,
            phone_number: phoneNumberValue.replace(/-/g, ""),
          }
        );
        if (response && response.data.success === true) {
          console.log("response", response.data);
          setShowContent(false);
        } else {
          console.log("등록실패 사유:", response.data.message);

          setShowSubmitTxt(response.data.message);
          setShowContent(true);
        }
      }
      setTimeout(() => {
        setShowSubmitTxt("");
      }, 1000);
    } catch (error) {
      console.log("API 요청 오류:", error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      oProps.fSetDocumentTitle("kb라이프파트너스 스루 전용쿠폰팩 발급");
    }, 150);
  });
  return (
    <LandingStyle>
      <div className="kb_life_wrap">
        <div className="header">
          <img src={logo} className="kb_logo" alt="kblife로고" width="100%" />
        </div>
        <div className="banner">
          <img
            className="web_banner"
            src={banner}
            alt="kblife전용 쿠폰팩 발급 배너"
            width="100%"
          />
          <img
            className="moblie_banner"
            src={moblieBanner}
            alt="kblife전용 쿠폰팩 발급 배너"
            width="100%"
          />
        </div>
        <div className="content_wrap">
          <div className="content">
            <img src={logo} className="kb_logo" alt="kblife로고" width="100%" />
            <h2>전용 쿠폰팩 발급</h2>
            <h3>담당자 자격확인</h3>
            {showContent ? (
              <div className="submit_before">
                <div className="customer_info">
                  <h2>소속명</h2>
                  <input
                    type="text"
                    placeholder="소속을 입력하세요"
                    value={classValue}
                    onChange={phoneClassChange}
                  />
                </div>
                <div className="customer_info">
                  <h2>이름</h2>
                  <input
                    type="text"
                    placeholder="한글 이름을 입력하세요"
                    value={nameValue}
                    onChange={phoneNameChange}
                  />
                </div>
                <div className="customer_info">
                  <h2>휴대전화번호</h2>
                  <input
                    type="text"
                    inputMode="numeric"
                    placeholder="- 없이 입력해주세요"
                    value={phoneNumberValue}
                    onChange={phoneNumberChange}
                  />
                </div>
                <div>
                  <label className="checkBoxWrap">
                    <input
                      type="checkbox"
                      checked={privacy}
                      onChange={PrivacyCheckboxChange}
                    />
                    <p className="checkBoxText">
                      <strong>개인 정보 활용 동의</strong>
                      <br />
                      수집된 개인정보는 쿠폰 발급 관련하여 관리,확인 용도로
                      사용되며 이외의 목적으로는 사용되지 않습니다.
                    </p>
                  </label>
                </div>
                <button
                  className="cupon_submit"
                  onClick={handleCouponLinkSubmit}
                >
                  <h1>링크 생성하기</h1>
                </button>
              </div>
            ) : (
              <div style={{ margin: "48px 0" }}>
                <div className="close_ment_wrap">
                  <h1>링크 생성 완료!</h1>
                </div>
              </div>
            )}
            <div className="notice">
              <h2>안내사항</h2>
              <ul>
                <li>
                  해당 쿠폰은 스루 고객 앱 다운로드 진행 후 로그인시 바로 발급
                  됩니다.
                </li>
                <li>해당 쿠폰은 발급후 6개월 동안 사용 가능합니다.</li>
                <li>해당 쿠폰은 2,000원 짜리 5개로 구성되어 있습니다.</li>
                <li>쿠폰 발급 관련 문의는 고객센터로 연락주세요(1670-5324)</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {showAlert && (
        <AlertMessage
          classValue={classValue}
          nameValue={nameValue}
          phoneNumberValue={phoneNumberValue}
          privacy={privacy}
        />
      )}
      {showSubmitTxt !== "" && <CustomAlert message={showSubmitTxt} />}
    </LandingStyle>
  );
};

const AlertMessage = ({ classValue, nameValue, phoneNumberValue, privacy }) => {
  if (classValue === "") {
    return <CustomAlert message={"소속을 입력해주세요"} />;
  }
  if (nameValue === "") {
    return <CustomAlert message={"한글 이름을 입력해주세요"} />;
  }
  if (phoneNumberValue === "") {
    return <CustomAlert message={"전화번호를 입력해주세요"} />;
  }
  if (!privacy) {
    return <CustomAlert message={"개인 정보 활용 동의를 체크해주세요."} />;
  }
  return null;
};

export default Content;

export const LandingStyle = styled.div`
  p,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  li,
  a,
  button,
  input {
    font-family: "NanumSquare";
    transform: skew(-0.1deg);
  }
  .moblie_banner {
    display: none;
  }

  .kb_life_wrap {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .header {
    width: 100%;
    padding: 28px 36px;
  }
  .kb_logo {
    width: 100%;
    max-width: 255px;
  }
  .content_wrap {
    width: 100%;
    background: #f6f6f6;
    padding: 64px 0 128px 0;
  }
  .content {
    width: 70%;
    margin: 0 auto;
    padding: 96px 128px;
    background: #fff;
    box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.12);
    border-radius: 4px;

    h2 {
      margin-top: 20px;
      font-size: 28px;
      font-weight: 700;
      color: #000;
    }
    h3 {
      margin: 48px 0;
      font-size: 20px;
      font-weight: 700;
      color: #111;
    }
  }
  .customer_info {
    margin-bottom: 16px;

    h2 {
      font-size: 20px;
      font-weight: 700;
      color: #111;
    }
    input {
      width: 100%;
      padding: 12px 17px;
      border: 1px solid #d8d5d2;
      border-radius: 8px;
      outline: none;
      color: #000;
    }
    input::placeholder {
      color: #333333;
      font-weight: 600;
    }
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .customer_info:nth-child(3) {
    margin-bottom: 32px;
  }
  .cupon_submit,
  .close_ment_wrap {
    display: flex;
    margin: 0 auto;
    padding: 12px 64px;
    border-radius: 999px;
    background: #ffcc23;
    border: none;
    outline: none;

    h1 {
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      color: #111;
      margin: 0;
    }
  }
  .close_ment_wrap {
    width: 100%;
    max-width: 300px;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: 1px solid #ffcc23;
  }
  .notice {
    padding: 24px;
    margin-top: 48px;
    background: #f6f0e7;
    border-radius: 4px;

    h2 {
      margin: 0 0 8px 0;
      font-size: 14px;
      font-weight: 700;
      color: #666;
    }
    ul {
      margin: 0;
      padding: 0 24px;

      li {
        padding-bottom: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #666;
      }
    }
  }

  /* 개인정보동의 */
  .checkBoxWrap {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    input {
      width: 20px;
      height: 20px;
    }
    .checkBoxText {
      font-size: 16px;
      margin-left: 10px;
      line-height: 24px;
    }
  }

  @media (max-width: 990px) {
    .content_wrap {
      padding: 0;
      background: #fff;
    }
    .content {
      width: 100%;
      padding: 59px 3vw;
      box-shadow: 0;
      border-radius: 0;
    }
    .notice {
      padding: 24px 16px;

      ul {
        padding: 0 16px;
      }
    }
  }
  @media (max-width: 700px) {
    .web_banner {
      display: none;
    }
    .moblie_banner {
      display: block;
    }
  }
`;
