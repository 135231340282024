// 사장님창구랜딩페이지
//route/promotion?=throoCeo-landingPage

import React, { useEffect } from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import '../../../assets/css/font.css';

// IMG
import CeoLandingBanner from "../../../assets/img/landing/ceoLandingBanner.jpg";
import CeoLandingLogo from "../../../assets/img/landing/ceoLandingLogo.png";
import CeoLandingTitle01 from "../../../assets/img/landing/ceoLandingTitle01.png";
import CeoLandingTitle02 from "../../../assets/img/landing/ceoLandingTitle02.png";
import CeoLanding01 from "../../../assets/img/landing/ceoLanding01.png";
import CeoLanding02 from "../../../assets/img/landing/ceoLanding02.png";
import CeoLanding03 from "../../../assets/img/landing/ceoLanding03.png";
import CeoLanding04 from "../../../assets/img/landing/ceoLanding04.png";
import CeoLanding05 from "../../../assets/img/landing/ceoLanding05.jpg";
import CeoLanding06 from "../../../assets/img/landing/m_ceoLanding06.png";
import CeoLandingstore01 from "../../../assets/img/landing/ceoLandingstore01.png";
import CeoLandingstore02 from "../../../assets/img/landing/ceoLandingstore02.png";
import CeoLandingpartner from "../../../assets/img/landing/ceoLandingPartner.png";


const Content = (oProps) => {

    useEffect(() => {
        setTimeout(() => {
            oProps.fSetDocumentTitle("스루소개");
        }, 150);

        window.scrollSection();
        window.scrollPoint();
    })

    const promotionPage = () => {
        window.open('https://ceo.throo.co.kr/ceotip?promotion20220701', '_blank');
    }
    const throoJoinPage = () => {
        window.open('https://throo.page.link/RgRQ', '_blank');
    }

    return (
        <LandingStyle>
            <div className="ceoLandingPage">
                <div className="banner">
                    <img className='bannerImg' src={CeoLandingBanner} alt="스루소개메인사진" width="100%" />
                    <div className="title">
                        <h2 >포장 매출 올리는<br />가장 쉬운 방법</h2>
                        <img className='throoLogo hide fade_in' src={CeoLandingLogo} alt="스루 로고" width="100%" style={{ transition: '3s' }} />
                        <img className='bannerText' src={CeoLandingTitle01} alt="스루 로고" width="100%" />
                        <img className='bannerText' src={CeoLandingTitle02} alt="스루 로고" width="100%" />
                    </div>
                </div>
                <img src={CeoLanding01} alt="스루소개이미지" width="100%" />
                <img src={CeoLanding02} alt="스루소개이미지" width="100%" />
                <img src={CeoLanding03} alt="스루소개이미지" width="100%" />
                <img src={CeoLanding04} alt="스루소개이미지" width="100%" />
                <div className="ceoLandingPartner">
                    <h2>전국 많은 매장들이<br />스루를 이용하고 있어요.</h2>
                    <div className="logo-slider-top">
                        <div>
                            <img src={CeoLandingstore01} alt="스루 가입 매장" />
                        </div>
                        <div>
                            <img src={CeoLandingstore01} alt="스루 가입 매장" />
                        </div>
                    </div>
                    <div className="logo-slider-bottoom">
                        <div>
                            <img src={CeoLandingstore02} alt="스루 가입 매장" />
                        </div>
                        <div>
                            <img src={CeoLandingstore02} alt="스루 가입 매장" />
                        </div>
                    </div>
                    <div className="partnerReview">
                        <div className="review">
                            <div className="pReview" >
                                <h2>파트너 브랜드 Y카페 매출사례</h2>
                                <div className="example">
                                    <h3>추가주문</h3>
                                    <h1>899잔</h1>
                                </div>
                                <div className="example">
                                    <h3 style={{ color: '#1A7CFF' }}>추가매출</h3>
                                    <h1 style={{ color: '#1A7CFF' }}>3,596,000원</h1>
                                </div>
                                <p>*22년 7월 1일-19일</p>
                            </div>
                            <div className="pReview" >
                                <h2>파트너 A카페 매출사례</h2>
                                <div className="example">
                                    <h3>추가주문</h3>
                                    <h1>170잔</h1>
                                </div>
                                <div className="example">
                                    <h3 style={{ color: '#1A7CFF' }}>추가매출</h3>
                                    <h1 style={{ color: '#1A7CFF' }}>850,000원</h1>
                                </div>
                                <p>*22년 7월 1일-19일</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="promotion" style={{ backgroundColor: "#1A7CFF" }}>
                    <img src={CeoLanding06} alt="사장님 프로모션" width="30%" />
                </div>
                <div className="floating">
                    <button onClick={throoJoinPage}>스루 무료 도입하기</button>
                </div>
            </div>
        </LandingStyle>
    )
}

export default Content;

export const LandingStyle = styled.div`
    /* 텍스트 애니메이션 */
    .in_view {
        opacity: 1;
        transition: all 1s ease-in-out;
    }
    .fade_in {
        opacity: 0;
        transform: translate3d(0, 0, 0);
    }
    .fade_up {
        opacity: 0;
        transform: translate3d(0, 15%, 0);
    }
    .in_view.fade_in,
    .in_view.fade_up {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    *{
        padding: 0;
        box-sizing: border-box;
        font-family: 'Pretendard';
        text-align: center;
    }
    .banner {
        position: relative;
        margin: 0 auto;
        width: 100%;

        .bannerImg {
            min-height: 100vh; 
            max-height: 100vh; 
        }
        .title {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            
            h2 {
                margin: 170px 0 27px 0;
                font-size: 38px;
                font-weight: 600;
                line-height: 47px;
                color: #fff;
            }
            .throoLogo {
                max-width: 202px;
                margin-bottom: 70px;
            }
            .bannerText {
                max-width: 736px;
                margin-top: 40px;
            }
        }
    }

    /* ceoLandingPartner */
    .ceoLandingPartner {
        background: #E9F0FE;
        min-height: 1326px;
        padding: 160px 0;

        h2 {
            margin-bottom: 80px;
            color: #191F28;
            font-size: 38px;
            font-weight: 700;
            line-height: 47px;
            text-align: center;
        }
    }
    
    .review {
        max-width: 26%;
        margin: 0 auto;
        margin-top: 100px;
    }
    .pReview {
        padding: 20px 26px;
        margin-bottom: 16px;
        background: #fff;
        border-radius: 10px;
        
        h2, h3, h1 {
            text-align: left;
            color: #191F28;
            font-size: 16px;
            font-family: 'Pretendard';
        }
        h2 {
            margin-bottom: 23px;
            font-weight: 700;
            padding: 0
        }
        p {
            margin-top: 16px;
            font-size: 12px;
            font-weight: 500;
            color: #79828C;
            text-align: right;
        }
        .example {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 6px;

            h1 {
                text-align: right;
                font-size: 22px;                
                font-weight: 700;
            }
        }
    }
    .logo-slider-top, .logo-slider-bottoom {
        overflow: hidden;
        width: 100%;
        margin: 0 auto;
        margin-top: 40px;
        position: relative;
        display: flex;
    }
    .logo-slider-top div {
        display: flex;
        position: relative;
        animation: marquee 50s linear infinite;
        justify-content: space-around;
    }
    .logo-slider-bottoom div {
        display: flex;
        position: relative;
        animation: marquee 50s linear infinite;
        justify-content: space-around;
        animation-direction: reverse;
    }
    .logo-slider-top img, .logo-slider-bottoom img {
        display: block;
        margin: 0 20px;
        width: 2170px;
        height: 90px;
    }
    .partnerReview img {
        max-width: 1121px;
        margin-top: 104px;
    }

    @keyframes marquee {
        0% {
            transform: translateX(0%);
        }
        100% {
            transform: translateX(-100%);
        }
    } 

    /* promotion */
    .promotion {
        position: relative;
        width: 100%;
        margin: 0 auto;
        text-align: center;

        button {
            margin: 0 auto;
            position: absolute;
            bottom: 15%;
            left: 0;
            right: 0;
            max-width: 418px;
            border: none;
            outline: none;
            background: #BFF0EB;
            border-radius: 10px;
            padding: 23px 49px;
            font-size: 28px;
            font-weight: 600;
            color:#000;
        }
    }

    /* floating */
    .floating {
        position: fixed;
        bottom: 0;
        right: 0;
        margin: 0 42px 36px 0;
        max-height: 67px;
        max-width: 299px;

        button {
            padding: 20px 75px;
            background: #1A7CFF;
            color: #fff;
            font-size: 20px;
            font-weight: 600;
            border: none;
            outline: none;
            border-radius: 33px;
        }
    }
   
`