//스루 7월 럭키세븐 이벤트
//route/promotion?=20220701-luckyEvent

import React, { useEffect } from 'react';
import styled from 'styled-components';
import '../../assets/css/font.css';

const Content = (oProps) => {

    useEffect(() => {
        setTimeout(() => {
            oProps.fSetDocumentTitle("스루 7월 럭키세븐 이벤트");
        }, 150);
    })

    return (
        <LandingStyle>
            <div className="landging">
                <div className="container">
                    <img src="https://prd-throo-store-product.s3.ap-northeast-2.amazonaws.com/store-events/202207-001-event/luckyEvent01.png" alt="경품이벤트 메인배너" width="100%" />
                    <img src="https://prd-throo-store-product.s3.ap-northeast-2.amazonaws.com/store-events/202207-001-event/luckyEvent02.png" alt="경품이벤트 메인배너" width="100%" />
                    <img src="https://prd-throo-store-product.s3.ap-northeast-2.amazonaws.com/store-events/202207-001-event/luckyEvent03.png" alt="경품이벤트 메인배너" width="100%" />
                    <img src="https://prd-throo-store-product.s3.ap-northeast-2.amazonaws.com/store-events/202207-001-event/luckyEvent04.png" alt="경품이벤트 메인배너" width="100%" />
                    <div style={{height: '50px', background: '#F8F8F8'}}></div>
                </div>
                <div className="floating">
                    <button><a href="https://throo.page.link/3V2K"><h2>스루 주문하러 가기</h2></a></button>
                </div>

            </div>
        </LandingStyle>
    )
}
export default Content;

export const LandingStyle = styled.div`
    * {
        font-family: 'Pretendard';
    }
    .container {
        margin: 0 auto;
        padding: 0;
        box-sizing: border-box;
        max-width: 1000px;
    }
    a {
        color: #fff;
        text-decoration: none;
    }
   .original_header {
        display: none;
    }
    footer {
        display: none;
    }

    img {
        margin-top: -8px;
    }

    .appInstall {
        position: relative;
    }

    button {
        position: absolute;
        bottom: 5%;
        left: 0;
        right: 0;
        border: none;
        outline: none;
    }
    .floating {
        position: fixed;
        bottom: 2.5%;
        right: 0;
        left: 0;

        button {
            margin: 0 auto;
            width: 90%;
            background: #001E62;
            outline: none;
            padding: 17px 0;
            border-radius: 4px;
            /* box-shadow: 0 2.5px 2.5px 2.5px rgba(0, 30, 98, .2); */
        }
        h2 {
            margin: 0;
            font-size: 17px;
            font-weight: 600;
            color: #fff;
        }
    }
   
    
` 