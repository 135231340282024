import React from 'react';
import {
    isMobile
} from "react-device-detect";

import M_serviceCenter from "./mobile/M_service";
import D_serviceCenter from "./desktop/D_service";

const MainServiceCenter = (oProps) => {
    const ServiceRender = () => {
        if (isMobile) {
            return <M_serviceCenter {...oProps} />
        }

        return <D_serviceCenter {...oProps} />
    }

    return (
        <>
            {ServiceRender()}
        </>
    )
}

export default MainServiceCenter;