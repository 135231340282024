import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import {
    isMobile,
    osName,
} from "react-device-detect";
import randtoken from 'rand-token';

import Landing20210527 from '../../../components/event/20210527';
import LandingBBQ from '../../../components/event/landingBBQ';
import LandingPizzaHut from '../../../components/event/landingPizzaHut';
import LandingDessert39 from '../../../components/event/landingDessert39';
import LandingBurger from '../../../components/event/landingBurger';
import LandingWelcar from '../../../components/event/welcarLanding';
import LandingStore from '../../../components/event/landingStore';
import LandingCafe from '../../../components/event/cafeLanding';
import LandingChicken from '../../../components/event/landingChicken';
import LandingFishmarket from '../../../components/event/landingFishmarket';
import LandingFishmarket02 from '../../../components/event/landingFishmarket02';
import LandingFishmarket03 from '../../../components/event/landingFishmarket03';
import LandingLucky from '../../../components/event/landingLucky';
import CeoLanding from '../../../components/event/ceoPageLanding/Main';
import CofficturesLanding from '../../../components/event/landingcoffictures';
import YoutubeLanding from '../../../components/event/landing_youtube';
import KblifeLanding from '../../../components/event/landing_kblife';
import KblifeLandingManage from '../../../components/event/landing_kblifeManager';


const EventPage = (oProps) => {
    const [sPosition, setPosition] = useState("");

    const history = useHistory();

    const checkInfo = async () => {
        let oUserConfig = {};
        let token = oProps.UserConfigReducer.Token;
  
        const currentPath = window.location.pathname;
        if(token === ''){
           token = randtoken.generate(32);
           oUserConfig['Token'] = token;
           oProps.reduxSetUserConfig(oUserConfig);
        }
        await oProps.oManager.fDoAxios("/home/checkInfo", "post", null, {token, isMobile, osName, currentPath});
    }

    useEffect(() => {
        document.title = "첫주문 고객이벤트";
        document.querySelector('meta[name="keywords"]').setAttribute("content", '스루, throo, 드라이브스루, 드라이브스루 솔루션, drive thru, throo, drive through');
        document.querySelector('meta[name="description"]').setAttribute("content", '드라이브스루 무료 솔루션 - 스루의 홈페이지 입니다.');
        document.querySelector('link[rel="canonical"]').setAttribute("href", 'https://throo.co.kr');
        document.querySelector('meta[property="og:title"]').setAttribute("content", "'스루'만 있으면 모든 곳이 드라이브스루");
        document.querySelector('meta[property="og:description"]').setAttribute("content", '드라이브스루 무료 솔루션 - 스루의 홈페이지 입니다.');
    })

    const setDocumentTitle = (sTitle) => {
        document.title = sTitle;
    }
    
    useEffect(() => {
        checkInfo();
        if (history.location.search != null && history.location.search != undefined) {
            let str = history.location.search;
            str = str.substr(2, str.length);
            setPosition(str.toString());
        }
    }, [])

    return (
        <div>
            {sPosition.startsWith("20210527") && <Landing20210527 fSetDocumentTitle={setDocumentTitle} />}
            {sPosition.startsWith("20210713-bbq") && <LandingBBQ fSetDocumentTitle={setDocumentTitle} />}
            {sPosition.startsWith("20210713-pizzahut") && <LandingPizzaHut fSetDocumentTitle={setDocumentTitle} />}
            {sPosition.startsWith("20210713-dessert39") && <LandingDessert39 fSetDocumentTitle={setDocumentTitle} />}
            {sPosition.startsWith("20210810-burger") && <LandingBurger fSetDocumentTitle={setDocumentTitle} />}
            {sPosition.startsWith("20210906-welcar") && <LandingWelcar fSetDocumentTitle={setDocumentTitle} />}
            {sPosition.startsWith("20211119-store") && <LandingStore fSetDocumentTitle={setDocumentTitle} />}
            {sPosition.startsWith("20220216-cafe") && <LandingCafe fSetDocumentTitle={setDocumentTitle} />}
            {sPosition.startsWith("20220216-chicken") && <LandingChicken fSetDocumentTitle={setDocumentTitle} />}
            {sPosition.startsWith("20220429-mayEvent") && <LandingFishmarket fSetDocumentTitle={setDocumentTitle} />}
            {sPosition.startsWith("20220429-fishmarketEvent") && <LandingFishmarket02 fSetDocumentTitle={setDocumentTitle} />}
            {sPosition.startsWith("20220511-fishmarketEvent02") && <LandingFishmarket03 fSetDocumentTitle={setDocumentTitle} />}
            {sPosition.startsWith("throoCeo-landingPage") && <CeoLanding fSetDocumentTitle={setDocumentTitle} />}
            {sPosition.startsWith("20220701-luckyEvent") && <LandingLucky fSetDocumentTitle={setDocumentTitle} />}
            {sPosition.startsWith("20221013-cofficturesEvent") && <CofficturesLanding fSetDocumentTitle={setDocumentTitle} />}
            {sPosition.startsWith("20230131-youtubeEvent") && <YoutubeLanding fSetDocumentTitle={setDocumentTitle} />}
            {sPosition.startsWith("kblife-landingPage") && <KblifeLanding fSetDocumentTitle={setDocumentTitle} />}
            {sPosition.startsWith("kblife-landing-managePage") && <KblifeLandingManage fSetDocumentTitle={setDocumentTitle} />}
        </div>
    )
}

export default EventPage;
