import React from 'react';
import {
    isMobile
} from "react-device-detect";

import M_company from "./mobile/M_company";
import D_company from "./desktop/D_company";

const MainCompany = (oProps) => {
    const companyRender = () => {
        if (isMobile) {
            return <M_company {...oProps} />
        }

        return <D_company {...oProps} />
    }

    return (
        <>
            {companyRender()}
        </>
    )
}

export default MainCompany;