// 스루스토어랜딩
// /route/promotion?=20211119-store

import React, { useEffect } from 'react';
import styled from 'styled-components';
import '../../assets/css/font.css';

// IMG
import storeBanner from "../../assets/img/landing/storeBanner.png";
import store01 from "../../assets/img/landing/store01.png";
import store02 from "../../assets/img/landing/store02.png";
import store03 from "../../assets/img/landing/store03.png";
import store04 from "../../assets/img/landing/store04.png";
import store05 from "../../assets/img/landing/store05.png";
import store06 from "../../assets/img/landing/store06.png";
import store07 from "../../assets/img/landing/store07.png";
import storeApp01 from "../../assets/img/landing/storeApp01.png";
import storeApp02 from "../../assets/img/landing/storeApp02.png";
import storeApp03 from "../../assets/img/landing/storeApp03.png";
import storeApp04 from "../../assets/img/landing/storeApp04.png";
import store08 from "../../assets/img/landing/store08.png";
import store09 from "../../assets/img/landing/store09.png";
import store10 from "../../assets/img/landing/store10.png";
import googlePlay from "../../assets/img/landing/googlePlay.png";
import appleStore from "../../assets/img/landing/appleStore.png";

const Content = (oProps) => {
    useEffect(() => {
        setTimeout(() => {
            oProps.fSetDocumentTitle("throo store");
        }, 150);
    })
    const playStore = () => {
        window.open('https://play.google.com/store/apps/details?id=com.throo_ceo', '_blank');
    }

    const appStore = () => {
        window.open('https://apps.apple.com/us/app/id1594134264', '_blank');
    }

    return (
        <LandingStyle>
            <div className="throoStore">
                <div className="banner">
                    <img src={storeBanner} alt="스루스토어랜딩 메인 이미지" width="100%" />
                    <div className="bannerTitle">
                        <h2>농수산물<br />정육점<br />꽃가게도<br />드라이브스루</h2>
                        <p>앱 하나로 우리 매장도<br />드라이브스루 매장이 될 수 있어요!</p>
                    </div>
                </div>
                <div className="section">
                    <div className="container">
                        <h2>스루는 이런 서비스입니다</h2>
                        <div className="content">
                            <img src={store01} alt="스루서비스이미지" width="78px" height="78px" />
                            <div className="contText">
                                <h3>매장 앞 고객의<br />차까지만 가져다주세요</h3>
                                <p>주문<span>&#38;</span>결제, 고객 도착 알림까지 스루에서 확인할 수 있어요.</p>
                            </div>
                        </div>
                        <div className="content">
                            <img src={store02} alt="스루서비스이미지" width="78px" height="78px" />
                            <div className="contText">
                                <h3>상품에 제한받지 않고<br />무엇이든 가능해요</h3>
                                <p>포장만 가능하다면 어떤 상품이든 드라이브 픽업이 가능해요.</p>
                            </div>
                        </div>
                        <div className="content">
                            <img src={store03} alt="스루서비스이미지" width="78px" height="78px" />
                            <div className="contText">
                                <h3>스루로 더 많은 고객을<br />만나보세요</h3>
                                <p>드라이브 픽업 시스템으로 운전자 고객을 사로잡아보세요.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="container">
                        <h2>이런 매장에 추천드립니다</h2>
                        <div className="store">
                            <h3>우리 매장에<br />포스가 없어요</h3>
                            <p>매장에 포스가 없어도 앱으로<br />주문접수,상품관리가 가능해요</p>
                            <img src={store04} alt="스루스토어 추천이미지" width="118px" />
                        </div>
                        <div className="store">
                            <h3>배달 외 매출을<br />늘리고 싶어요.</h3>
                            <p>새로운 시장인 드라이브 픽업으로<br />운전자 고객을 선점해보세요.</p>
                            <img src={store05} alt="스루스토어 추천이미지" width="132px" />
                        </div>
                        <div className="store">
                            <h3>상품의 종류나<br />가격이 자주 변동돼요</h3>
                            <p>상품의 종류나 가격이 자주 변동되어도<br />앱에서 손쉽게 관리할 수 있어요.</p>
                            <img src={store06} alt="스루스토어 추천이미지" width="173px" />
                        </div>
                        <div className="store">
                            <h3>배달이 어려운<br />매장이에요</h3>
                            <p style={{ marginBottom: "0" }}>신선도가 필수인 농수산시장, 정육점, 꽃집<br />모두 드라이브스루 가능해요.</p>
                            <img src={store07} alt="스루스토어 추천이미지" width="158px" />
                        </div>
                    </div>
                </div>
                <div className="section"><h2>사장님 매장에 최적화된<br />드라이브스루 주문관리 앱</h2></div>
                <div className="section">
                    <div className="container">
                        <div className="storeApp">
                            <h2>포스 없이도<br /><span>앱으로 운영</span>가능</h2>
                            <p>매장 운영 상황, 매장 준비 시간, 상품 재고관리 등 포스 기능을 모두 앱에서 사용할 수 있어요.</p>
                            <img src={storeApp01} alt="스루스토어어플 이미지" width="100%" />
                        </div>
                        <div className="storeApp">
                            <h2>주문 NO! 결제 NO!<br />고객 <span>응대시간 절약</span>효과</h2>
                            <p>선결제 시스템으로 주문, 결제 시간이 절약되며 간단하게 주문 확인이 가능해요.</p>
                            <img src={storeApp02} alt="스루스토어어플 이미지" width="100%" />
                        </div>
                        <div className="storeApp">
                            <h2>고객 도착 시 자동 알림,<br /><span>상품 전달만</span>하면 끝.</h2>
                            <p>고객이 픽업 존에 도착했을 때 알람이 울려요.<br /> 픽업 존으로 가서 상품을 전달해 주세요.</p>
                            <img src={storeApp03} alt="스루스토어어플 이미지" width="100%" />
                        </div>
                        <div className="storeApp">
                            <h2>바쁜 사장님을 위한<br /><span>쉽고 빠른 상품관리</span></h2>
                            <p>매장에서 판매 중인 상품을 촬영하고,<br />터치 한 번이면 상품이 등록돼요.</p>
                            <img src={storeApp04} alt="스루스토어어플 이미지" width="100%" />
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="container">
                        <h2><span>진짜 쉬워요!</span><br />3단계만 거치면 끝</h2>
                        <div className="joinContent">
                            <div className="contText">
                                <h3>하나! 회원가입</h3>
                                <p>앱 다운로드하고<br />회원가입해 주세요.</p>
                            </div>
                            <img src={store08} alt="스루스토어 입점과정" width="78px" height="78px" />
                        </div>
                        <div className="joinContent">
                            <div className="contText">
                                <h3>둘! 사업자 인증</h3>
                                <p>스루 입점에 필요한<br />사업자 정보를 등록해 주세요.</p>
                            </div>
                            <img src={store09} alt="스루스토어 입점과정" width="78px" height="78px" />
                        </div>
                        <div className="joinContent">
                            <div className="contText">
                                <h3>셋! 매장 정보 입력</h3>
                                <p>우리 매장의 정보와<br />메뉴<span>&#38;</span>가격을 입력해 주세요!</p>
                            </div>
                            <img src={store10} alt="스루스토어 입점과정" width="78px" height="78px" />
                        </div>
                        <h3><span>간편하게 앱으로 3단계 입력</span>후<br />바로 영업이 가능해요!</h3>
                    </div>
                </div>
                <div className="section">
                    <div className="container">
                        <h2><span>스루스토어</span>다운받고<br />드라이브스루<br />시작해 보세요!</h2>
                    </div>
                </div>
                {/* <div className="floating" onClick={throoStore}>
                    <div className="container">
                        <button><h2>스루스토어 설치하기</h2></button>
                    </div>
                </div> */}
                <div className="floating" style={{ zIndex: "999" }}>
                    <div className="topGradation"></div>
                    <div className="bottomBtn">
                        <div className="pBtn" onClick={playStore}><img src={googlePlay} alt="구글플레이스토어 다운로드" width="95%" style={{minHeight:"45px"}} /></div>
                        <button onClick={appStore}><img src={appleStore} alt="구글플레이스토어 다운로드"  width="95%" style={{minHeight:"45px"}}  /></button>
                    </div>
                </div>
            </div>
        </LandingStyle>
    )
}

export default Content;

export const LandingStyle = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    .throoStore {
        width: 100%;
    }
    .container {
        padding: 0 5vw;
        margin: 0 auto;
    }
    p, a, h1, h2, h3, h4, h5, h6, li, a {
        font-family: 'NanumSquare';
        transform: skew(-0.1deg);
    }

    /* 공통 */
    .section {
        width: 100%;

        h2 {
            font-size: 30px;
            font-weight: 900;
            line-height: 40px;
            color: #000;
            text-align: center;
        }
    }

    /* banner */
    .banner img {
        position: relative;
        background-repeat: no-repeat;
        background-size: contain;
        min-height: 100vh;
        max-height: 100vh;
    }
    .bannerTitle {
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        min-height: 100vh;
        max-height: 100vh;
        color: #fff;

        h2 {
            margin-bottom: 52px;
            font-size: 40px;
            font-weight: 900;
            line-height: 48px;
            color: #fff;
        }
        p {
            margin-bottom: 72px;
            font-size: 22px;
            font-weight: 700;
            line-height: 32px;
        }
    }

    /* 스루 서비스 소개 */
    .section:nth-child(2) {
        padding: 74px 0 52px 0; 

        h2 {
            margin-bottom: 48px;
        }
        .content {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 16px 0;
            border-bottom: 1px solid #E4E4E4;

            img {
              margin-right: 20px;
            }
            .contText {
                color: #333;

                h3 {
                    font-size: 18px;
                    font-weight: 900;
                    line-height: 24px;
                }
                p {
                    margin-top: 4px;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 22px;
                    word-break: keep-all;

                    color: #333;
                }
            }
        }
    }

    /* 스루스토어 추천매장 */
    .section:nth-child(3) {
        padding: 60px 0 97px 0;
        background-color: #F8F9FA;

        h2 {
            margin-bottom: 46px;
        }
        .store {
            width: 296px;
            min-height: 296px;
            border-radius: 50%;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            border: 2px solid #F0F1F5;
            margin-bottom: 30px;

            h3 {
                margin-top: 16px;
                font-size: 18px;
                font-weight: 900;
                line-height: 24px;
                color: #333;
                text-align: center;
            }
            p {
                margin: 8px 0 12px 0;
                font-size:14px;
                font-weight: 700;
                line-height: 22px;
                color: #333;
                text-align: center;
            }
        }
    }

    /* 스루스토어 앱 설명 */
    .section:nth-child(4) {
        padding: 53px 0;
        background-color: #EBFF00;
        
        h2 {
            color: #24252A;
        }
    }

    /* 스루스토어 앱 */
    .section:nth-child(5) {
        padding: 70px 0 20px 0;
        background-color: #24252A;
        
        h2 {
            margin-bottom: 12px;
            color: #fff;
        }
        span {
            color: #24252A;
            background-color: #EBFF00;
            padding: 5px;
        }
        p {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color: #fff;
            text-align: center;
            word-break: keep-all;
            color: #ECECEC;
        }
        img {
            margin: 30px 0 100px 0;
        }
    }

    /* 스루스토어 입점과정 */
    .section:nth-child(6) {
        padding: 41px 0 30px 0;
        background-color: #EEFAF8;

        h2 {
            margin-bottom: 27px;
            font-weight:700;
            color: #001915;

            span {
                font-weight: 900;
                color: #001915;
            }
        }
        .joinContent {
            display: flex;
            align-self: center;
            justify-content: space-between;
            padding: 16px;
            border-bottom: 1px solid #DAEAE8;

            .contText {
                h3 {
                    margin-top: 0;
                    font-size: 18px;
                    font-weight:900;
                    line-height: 24px;
                    color: #37526E;
                    text-align: left;
                }
                p {
                    margin-top: 2px;
                    font-size: 16px;
                    font-weight:700;
                    color: #333;
                }
            }            
        }
        h3 {
            margin-top: 31px;
            font-size: 16px;
            font-weight: 900;
            line-height: 25px;
            color: #333;
            text-align: center;

            span {
                background-color: #EBFF00;
                padding: 5px;
            }
        }
    }

    /* 스루스토어 입점신청하기 */
    .section:nth-child(7){
        padding: 76px 0;
        background-color: #212634;

        h2 {
            margin-bottom: 40px;
            color : #fff;
            line-height: 44px;
            font-weight: 700;

            span {
                background-color: #1A7CFF;
                padding: 5px;
                font-weight: 900;
            }
        }
    }

    /* 입점신청 플러팅 */
    .floating {
        position: fixed;
        flex-direction: column;
        bottom:0;
        width: 100%;
        display: flex;
        align-items: center;
        
        .topGradation {
            height: 34px;
            width: 100%;
            background: linear-gradient(to top, rgb(255, 255, 255), rgba(255, 255, 255, 0));
        }
        .bottomBtn {
            padding: 0px 5vw 15px 5vw ;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;

            button {
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 60px;
                border-radius: 6px;
                font-size: 16px;
                font-weight: 900;
                background-color: #fff;
                border:none;
                outline: none;

            }
        } 
    }

`