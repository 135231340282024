import React, { useState, useEffect } from "react";
// import { Modal } from 'antd';
import { isMobile, osName } from "react-device-detect";
import randtoken from "rand-token";
import ModalPage from "../../components/modal/ModalDesk";
import moment from "moment-timezone";
import "moment/locale/ko";

import M_ContentsPage from "../../components/home/mobile/M_ContentsPage";
import D_ContentsPage from "../../components/home/desktop/D_ContentsPage";

const MainPage = (oProps) => {
  const [modalOpen, setModalOpen] = useState(true);

  const checkInfo = async () => {
    let oUserConfig = {};
    let token = oProps.UserConfigReducer.Token;

    const currentPath = window.location.pathname;
    if (token === "") {
      //token = randtoken.generate(32);
      //oUserConfig['Token'] = token;
      //oProps.reduxSetUserConfig(oUserConfig);
    }
    await oProps.oManager.fDoAxios("/home/checkInfo", "post", null, {
      token,
      isMobile,
      osName,
      currentPath,
    });
  };

  const fSavePopupToday = async () => {
    try {
      setTimeout(() => {
        // let oUserConfig = {};
        // oUserConfig['PopUpClose'] = moment().format("YYYYMMDD");
        // oProps.reduxSetUserConfig(oUserConfig);
        // setModalOpen(false);
      }, 250);
    } catch (error) {}
  };

  useEffect(() => {
    document.title = "'스루'만 있으면 모든 곳이 드라이브스루";
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        "스루, throo, 드라이브스루, 드라이브스루 솔루션, drive thru, throo, drive through"
      );
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "드라이브스루 무료 솔루션 - 스루의 홈페이지 입니다."
      );
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", "https://throo.co.kr");
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", "'스루'만 있으면 모든 곳이 드라이브스루");
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute(
        "content",
        "드라이브스루 무료 솔루션 - 스루의 홈페이지 입니다."
      );
    if (
      oProps.UserConfigReducer !== undefined &&
      oProps.UserConfigReducer.PopUpClose !== undefined
    ) {
      let sToday = moment().format("YYYYMMDD");
      if (oProps.UserConfigReducer.PopUpClose === sToday) {
        setModalOpen(false);
      }
    }
    checkInfo();
    window.scrollSection();
    window.scrollPoint();
    window.textRolling();
  }, []);

  const contentRender = () => {
    if (isMobile) {
      return (
        <div style={{ zIndex: 0, position: "relative" }}>
          <M_ContentsPage {...oProps} />
        </div>
      );
    }
    return (
      <div style={{ zIndex: 0, position: "relative" }}>
        <D_ContentsPage {...oProps} />
      </div>
    );
  };

  return <>{contentRender()}</>;
};

export default MainPage;
