import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import {
   isMobile,
   osName,
} from "react-device-detect";
import randtoken from 'rand-token';

import FoodieBoosterCompany from '../../../components/event/foodieBoosterHomepage/foodie_company';

const EventPage = (oProps) => {
   const [sPosition, setPosition] = useState("");

   const history = useHistory();

   const checkInfo = async () => {
      let oUserConfig = {};
      let token = oProps.UserConfigReducer.Token;

      const currentPath = window.location.pathname;
      if (token === '') {
         token = randtoken.generate(32);
         oUserConfig['Token'] = token;
         oProps.reduxSetUserConfig(oUserConfig);
      }
      await oProps.oManager.fDoAxios("/home/checkInfo", "post", null, { token, isMobile, osName, currentPath });
   }

   useEffect(() => {
      document.title = "foodiebooster홈페이지";
      document.querySelector('meta[name="keywords"]').setAttribute("content", '스루, throo, 드라이브스루, 드라이브스루 솔루션, drive thru, throo, drive through');
      document.querySelector('meta[name="description"]').setAttribute("content", '드라이브스루 무료 솔루션 - 스루의 홈페이지 입니다.');
      document.querySelector('link[rel="canonical"]').setAttribute("href", 'https://throo.co.kr');
      document.querySelector('meta[property="og:title"]').setAttribute("content", "'스루'만 있으면 모든 곳이 드라이브스루");
      document.querySelector('meta[property="og:description"]').setAttribute("content", '드라이브스루 무료 솔루션 - 스루의 홈페이지 입니다.');
   })

   const setDocumentTitle = (sTitle) => {
      document.title = sTitle;
   }

   useEffect(() => {
      checkInfo();
      if (history.location.search != null && history.location.search != undefined) {
         let str = history.location.search;
         str = str.substr(2, str.length);
         setPosition(str.toString());
      }
   }, [])

   return (
      <div>
         {<FoodieBoosterCompany fSetDocumentTitle={setDocumentTitle} {...oProps} />}
      </div>
   )
}

export default EventPage;
