import React, { useState, useEffect } from "react";
import CafeShowThroo from "../../../components/event/landing_cafeshow_throo";
import CafeShowBehind from "../../../components/event/landing_cafeshow_behind";
import CafeShowOwn from "../../../components/event/landing_cafeshow_own";
import CafeShowMarketing01 from "../../../components/event/landing_cafeshow_marketing01";
import CafeShowMarketing02 from "../../../components/event/landing_cafeshow_marketing02";
import CafeShowMarketing03 from "../../../components/event/landing_cafeshow_marketing03";
import CafeShowMarketing04 from "../../../components/event/landing_cafeshow_marketing04";

const EventPage = (oProps) => {
  useEffect(() => {
    document.title = "첫주문 고객이벤트";
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        "스루, throo, 드라이브스루, 드라이브스루 솔루션, drive thru, throo, drive through"
      );
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "드라이브스루 무료 솔루션 - 스루의 홈페이지 입니다."
      );
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", "https://throo.co.kr");
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", "'스루'만 있으면 모든 곳이 드라이브스루");
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute(
        "content",
        "드라이브스루 무료 솔루션 - 스루의 홈페이지 입니다."
      );
  });

  const setDocumentTitle = (sTitle) => {
    document.title = sTitle;
  };

  useEffect(() => {}, []);

  if (oProps && oProps.props && oProps.props.location) {
    if (oProps.props.location.pathname.indexOf("/cafeshow/behind") > -1) {
      return (
        <div>
          <CafeShowBehind fSetDocumentTitle={setDocumentTitle} {...oProps} />
        </div>
      );
    }
    if (oProps.props.location.pathname.indexOf("/cafeshow/own") > -1) {
      return (
        <div>
          <CafeShowOwn fSetDocumentTitle={setDocumentTitle} {...oProps} />
        </div>
      );
    }
    if (oProps.props.location.pathname.indexOf("/cafeshow/marketing01") > -1) {
      return (
        <div>
          <CafeShowMarketing01 fSetDocumentTitle={setDocumentTitle} {...oProps} />
        </div>
      );
    }
    if (oProps.props.location.pathname.indexOf("/cafeshow/marketing02") > -1) {
      return (
        <div>
          <CafeShowMarketing02 fSetDocumentTitle={setDocumentTitle} {...oProps} />
        </div>
      );
    }
    if (oProps.props.location.pathname.indexOf("/cafeshow/marketing03") > -1) {
      return (
        <div>
          <CafeShowMarketing03 fSetDocumentTitle={setDocumentTitle} {...oProps} />
        </div>
      );
    }
    if (oProps.props.location.pathname.indexOf("/cafeshow/marketing04") > -1) {
      return (
        <div>
          <CafeShowMarketing04 fSetDocumentTitle={setDocumentTitle} {...oProps} />
        </div>
      );
    }
  }

  return (
    <div>
      <CafeShowThroo fSetDocumentTitle={setDocumentTitle} {...oProps} />
    </div>
  );
};

export default EventPage;
