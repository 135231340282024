//foodiebooster?=homepage
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Header from './header';
import SVG from 'react-inlinesvg';

// IMG
import foodieBooster01 from "../../../assets/img/foodieBooster/foodieBooster01.png";
import foodieBooster02 from "../../../assets/img/foodieBooster/foodieBooster02.png";
import foodieBooster03 from "../../../assets/img/foodieBooster/foodieBooster03.png";
import foodieBooster04 from "../../../assets/img/foodieBooster/foodieBooster04.png";
import foodieBooster05 from "../../../assets/img/foodieBooster/foodieBooster05.png";
import foodieBooster06 from "../../../assets/img/foodieBooster/foodieBooster06.png";
import foodieBooster07 from "../../../assets/img/foodieBooster/foodieBooster07.png";
import foodieBooster08 from "../../../assets/img/foodieBooster/foodieBooster08.png";
import foodieBooster09 from "../../../assets/img/foodieBooster/foodieBooster09.png";
import foodieBooster10 from "../../../assets/img/foodieBooster/foodieBooster10.png";
import foodieBooster11 from "../../../assets/img/foodieBooster/foodieBooster11.png";
import foodieBooster12 from "../../../assets/img/foodieBooster/foodieBooster12.png";
import foodieBooster13 from "../../../assets/img/foodieBooster/foodieBooster13.png";
import foodieBoosterBtn01 from "../../../assets/img/foodieBooster/foodieBoosterBtn01.png";
import foodieBoosterBtn02 from "../../../assets/img/foodieBooster/foodieBoosterBtn02.png";
import foodieBoosterBtn03 from "../../../assets/img/foodieBooster/foodieBoosterBtn03.png";
import foodieBoosterBtn04 from "../../../assets/img/foodieBooster/foodieBoosterBtn04.png";

// 모바일IMG
import m_foodieBooster01 from "../../../assets/img/foodieBooster/m_foodieBooster01.png";
import m_foodieBooster02 from "../../../assets/img/foodieBooster/m_foodieBooster02.png";
import m_foodieBooster03 from "../../../assets/img/foodieBooster/m_foodieBooster03.png";
import m_foodieBooster04 from "../../../assets/img/foodieBooster/m_foodieBooster04.png";
import m_foodieBooster05 from "../../../assets/img/foodieBooster/m_foodieBooster05.png";
import m_foodieBooster06 from "../../../assets/img/foodieBooster/m_foodieBooster06.png";
import m_foodieBooster07 from "../../../assets/img/foodieBooster/m_foodieBooster07.png";
import m_foodieBooster08 from "../../../assets/img/foodieBooster/m_foodieBooster08.png";
import m_foodieBooster09 from "../../../assets/img/foodieBooster/m_foodieBooster09.png";
import m_foodieBooster10 from "../../../assets/img/foodieBooster/m_foodieBooster10.png";
import m_foodieBooster11 from "../../../assets/img/foodieBooster/m_foodieBooster11.png";
import m_foodieBooster12 from "../../../assets/img/foodieBooster/m_foodieBooster12.png";
import m_foodieBooster13 from "../../../assets/img/foodieBooster/m_foodieBooster13.png";
import m_price01 from "../../../assets/img/foodieBooster/price01.png";
import m_price02 from "../../../assets/img/foodieBooster/price02.png";
import m_price03 from "../../../assets/img/foodieBooster/price03.png";
import rightArrow from '../../../assets/svg/rightArrow.svg'

const Homepage = (oProps) => {
    console.log(oProps)
    const naverStore = () => {
        window.open('https://smartstore.naver.com/throo/products/8177958036');
    };
    const Report_web_link = () => {
        window.open('https://bit.ly/3Kt4rki');
    };
    const fReport_moblie_link = () => {
        window.open('https://bit.ly/3nGNjyE');
    };

    useEffect(() => {
        setTimeout(() => {
            oProps.fSetDocumentTitle("foodiebooster homepage");
        }, 150);
    })
    const productRef = useRef(null)
    const reportRef = useRef(null)
    const scrollToProduct = () => {
        if (productRef.current) {
            productRef.current.scrollIntoView({ behavior: 'auto' });
        }
    };

    const scrollToReport = () => {
        if (reportRef.current) {
            reportRef.current.scrollIntoView({ behavior: 'auto' });
        }
    };
    useEffect(() => {
        if (oProps?.location?.state?.scrollTo === 'product') {
            scrollToProduct()
        } else if (oProps?.location?.state?.scrollTo === 'report') {
            scrollToReport()
        }
    }, [oProps.location?.state?.scrollTo])

    return (
        <LandingStyle>
            <div className="foodieBooster" >
                <div className='header_sticky' >
                    <Header scrollToProduct={scrollToProduct} scrollToReport={scrollToReport} />
                </div>
                <div className="fWeb">
                    <img src={foodieBooster01} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" />
                    <img src={foodieBooster02} ref={productRef} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" id="product" style={{ marginTop: '-60px', paddingTop: '60px' }} />
                    <img src={foodieBooster03} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" />
                    <div className='fWrap'>
                        <img src={foodieBooster04} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" />
                        <button>
                            <Link to={{ pathname: "/foodiebooster/company" }} >
                                <img src={foodieBoosterBtn01} alt="기업 소개 바로가기" />
                            </Link>
                        </button>

                    </div>
                    <img src={foodieBooster05} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" />
                    <img src={foodieBooster06} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" />
                    <div className="fWrap02" ref={reportRef} style={{ marginTop: '-60px', paddingTop: '60px' }}>
                        <img src={foodieBooster07} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" />
                    </div>
                    <img src={foodieBooster08} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" />
                    <img src={foodieBooster09} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" />
                    <img src={foodieBooster10} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" />
                    <div className="fWrap03">
                        <img src={foodieBooster11} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" />
                        <button onClick={Report_web_link}>
                            <img src={foodieBoosterBtn03} alt="foodiebooster무료체험하기 버튼" />
                        </button>
                        <button onClick={Report_web_link}>
                            <img src={foodieBoosterBtn03} alt="foodiebooster무료체험하기 버튼" />
                        </button>
                        <button onClick={Report_web_link}>
                            <img src={foodieBoosterBtn04} alt="foodiebooster무료체험하기 버튼" />
                        </button>
                    </div>
                    <img src={foodieBooster12} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" />
                    <div className='fWrap04'>
                        <img src={foodieBooster13} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" />
                        <button onClick={Report_web_link}>
                            <img src={foodieBoosterBtn02} alt="foodiebooster무료체험하기 버튼" />
                        </button>
                    </div>
                </div>

                {/* 모바일 */}
                <div className="fMobile"  >
                    <img src={m_foodieBooster01} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" />
                    <img src={m_foodieBooster02} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" style={{ marginTop: '-60px', paddingTop: '60px' }} />
                    <img src={m_foodieBooster03} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" />
                    <div className='fWrap'>
                        <img src={m_foodieBooster04} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" />
                        <button>
                            <Link to={{ pathname: "/foodiebooster/company" }} >
                                <img src={foodieBoosterBtn01} alt="기업 소개 바로가기" />
                            </Link>
                        </button>
                    </div>
                    <img src={m_foodieBooster05} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" />
                    <img src={m_foodieBooster06} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" />
                    <div className="fWrap02" style={{ marginTop: '-60px', paddingTop: '60px' }}>
                        <img src={m_foodieBooster07} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" />
                    </div>
                    <img src={m_foodieBooster08} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" />
                    <img src={m_foodieBooster09} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" />
                    <img src={m_foodieBooster10} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" />
                    <div className="fWrap03">
                        <img src={m_foodieBooster11} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" />
                        <div  className="foodiebooster_price">
                                <img src={m_price01} alt="foodiebooster무료체험하기" onClick={fReport_moblie_link} />
                                <img src={m_price02} alt="foodiebooster무료체험하기" onClick={fReport_moblie_link} />
                                <img src={m_price03} alt="foodiebooster무료체험하기" onClick={fReport_moblie_link} />
                        </div>
                    </div>
                    <img src={m_foodieBooster12} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" />
                    <img src={m_foodieBooster13} alt="외식업 최초 인공지능기반 마케팅 솔루션 홈페이지" />
                    <div className='floatingButton'>
                        <button onClick={fReport_moblie_link}>
                            <p>AI 솔루션 무료 체험하기</p>
                            <SVG src={rightArrow} width="15px" height="15px" title="클릭버튼" />
                        </button>
                    </div>
                </div>
            </div>
        </LandingStyle>
    )
}

export default Homepage;

export const LandingStyle = styled.div`
    /* 공통css */
    .original_header {
        display: none;
    }
    .footer {
        background: red!important;
        display: none!important;
    }

    /* header고정 */
    .header_sticky {
        position: sticky;
        top: 0;
        z-index: 999;
    }
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    h1, h2, h3, h4, p, button, a, ul, li, textarea {
        font-family: 'Pretendard';
    }

    .foodieBooster {
        width: 100%;
    }    
    .fWeb{
        height: auto;
    }
    img {
        width: 100%;
    }
    .fWrap,.fWrap03,.fWrap04 {
        width: 100%;
        position: relative;

        button {
            position: absolute;
            border: none;
            outline: none;
            background: none;
        }
    }
    .fWrap button {
        bottom: 35%;
        left: 12%;
        text-align: left;

        img {
            width: 25%;
        }
    }
    .fWrap03 button:nth-child(2) {
        bottom : 8.5%;
        left: 25.5%;
        width: 14%;
    }
    .fWrap03 button:nth-child(3) {
        bottom : 8.5%;
        left: 43.2%;
        width: 14%;
    }
    .fWrap03 button:nth-child(4) {
        bottom : 8.5%;
        left: 60.9%;
        width: 14%;
    }
    .fWrap04 {
        width: 100%;
        margin: 0 auto;
    }
    .fWrap04 button {
        min-width: 100%;
        bottom: 18%;
        left: 0%;
        text-align: center;

        img {
            width: 18%;
        }
    }

    /* 모바일 */
    .fMobile{
        display:none;
    }
    @media screen and (max-width: 950px) {
        .fWeb {
            display: none;
        }
        .fMobile{
            display:block;
        }
        .fWrap button {
            bottom: 55%;
            left: 5.5%;

            img {
                width: 40%;
            }
        }
        .fWrap02 button:nth-child(2) {
            bottom: 48%;
        }
        .fWrap02 button:nth-child(3) {
            bottom: 7%;
        }
        .fWrap03 button:nth-child(2) {
            bottom: 44%;
        }
        .fWrap03 button:nth-child(3) {
            bottom: 3%;
        }
        .fWrap04 button {
            bottom: 17%;
        }

        /* 가격 */
        .foodiebooster_price {
            width: 100%;
            max-width: 100%;
            display: flex;
            position: absolute;
            overflow-x: scroll;
            bottom: 10%;
            left: 0;
            padding: 0 6vw ;
            
 
            
            img {
                max-width: 60%;
            }
        }

        /* floatingButton */
        .floatingButton{
            width: 100%;
            position:fixed;
            bottom: 0;

            button {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 15px 0;
                background: #1A7CFF;
                border: none;
                outline: none;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;

                p {
                    font-size: 18px;
                    font-weight: 700;
                    color: #fff;
                }
                svg {
                    margin-left: 3px;
                    path {
                        stroke: #fff;
                    }
                }
            }
        }
    }
    
   
`