import React, { useEffect } from 'react';
import styled from 'styled-components';
import randtoken from 'rand-token';
import {
    isMobile,
    osName,
} from "react-device-detect";
import '../../../assets/css/font.css';

const PageService = (oProps) => {
    const checkInfo = async () => {
        let oUserConfig = {};
        let token = oProps.UserConfigReducer.Token;

        const currentPath = window.location.pathname;
        if (token === '') {
            token = randtoken.generate(32);
            oUserConfig['Token'] = token;
            oProps.reduxSetUserConfig(oUserConfig);
        }
        await oProps.oManager.fDoAxios("/home/checkInfo", "post", null, { token, isMobile, osName, currentPath });
    }

    const openKakao = () => {
        if (isMobile) {
            window.open('https://pf.kakao.com/_sxhlvK/chat', '_blank');
        } else {
            window.open('https://pf.kakao.com/_sxhlvK/chat', '_blank', "height=900,width=500,location=no,directories=no,resizable=no,status=no,toolbar=no,menubar=no");
        }
    }

    useEffect(() => {
        checkInfo();
        document.title = "고객센터";
        document.querySelector('meta[name="keywords"]').setAttribute("content", '스루 고객센터');
        document.querySelector('meta[name="description"]').setAttribute("content", '불편사항 접수, 장애신고');
        document.querySelector('link[rel="canonical"]').setAttribute("href", 'https://throo.co.kr/service');
        document.querySelector('meta[property="og:title"]').setAttribute("content", '스루 고객센터');
        document.querySelector('meta[property="og:description"]').setAttribute("content", '불편사항 접수, 장애신고');
    }, []);

    return (
        <ServiceStyle>
            <div className="servicePage">
                <h2>스루 사용 중<br />문제가 발생하셨나요?</h2>
                <p>고객님 걱정마세요!<br />시스템의 오류나 장애로 인한 피해라면 반드시 보상해드립니다.</p>
                <div className="serviceLink">
                    <div className="sLink">
                        <h3>전화 상담</h3>
                        <a href='tel:1670-5324'><p>1670-5324</p></a>
                    </div>
                    <div className="line"></div>
                    <div className="sLink">
                        <h3>카톡 상담</h3>
                        <p onClick={openKakao}>스루 고객센터</p>
                    </div>
                </div>
                <div className="time">
                    <p>평일 9시-18시</p>
                </div>
            </div>
        </ServiceStyle>
    )
}
export default PageService;

export const ServiceStyle = styled.aside`
    p, a, h1, h2, h3, h4, h5, h6, button, input, label {
        transform: skew(-0.1deg);
        color: #212329;
        font-family: 'NanumSquare';
    }

    html,
    body {
        margin: 0;
        padding: 0;
    }
    .container {
        width: 1120px;
        margin: 0 auto;
    }

    .servicePage {
        min-height: 100vh;
        max-height: 100vh;
        background-color: #EEF6F3;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    h2 {
        margin: 60px 0 0 0 ;
        font-size: 60px;
        font-weight: 900;
        line-height: 78px;
    }
    p {
        margin: 26px 0 72px 0;
        font-size: 22px;
        font-weight: 700;
    }

    .serviceLink {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        cursor: pointer;

        .line {
            border: 1px solid #D6E0E0;
            max-height: 52px;
            min-height: 52px;
            margin: 0 100px;
        }
        h3 {
            font-size: 18px;
            font-weight: 700;
            line-height: 36px;
        }
        p {
           margin: 0; 
           font-size: 26px;
           font-weight: 900;
        }
    }
    .time{
        margin-top: 60px;
        p {
            margin: 0 auto;
            max-width: 260px;
            padding: 21px 51px;
            font-size: 22px;
            font-weight: 900;
            line-height: 34px;
            background-color: #E3EEEF;
            border-radius: 50px;
        } 
    }
`

