import React from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import '../../../screens/home/MainPage';
import '../../../assets/css/font.css';

import { WebRoute } from '../../../navigation/WebRoutes';

// SVG
import logo from "../../../assets/svg/homeMainSvg/throologo.svg";

const HeaderPage = ({ toggle }) => {

   const history = useHistory();
   const moveToPath = async (sIndex) => {
      if (sIndex === "CEO") {
         window.open('https://ceo.throo.co.kr', '_blank');
      } else if (sIndex === "HOME") {
         history.push(WebRoute.HOME);
      } else if (sIndex === "POSDOWNLOAD") {
         var ua = navigator.userAgent;
         if (ua.match(/Win(dows )?(NT 5\.1|XP)/)) {
            //os = "Windows XP";
            window.open('pos-download-xp.html', '_self');
            return;
         } else if ((ua.indexOf("Windows NT 5.1") != -1) || (ua.indexOf("Windows XP") != -1)) {
            //os = "Windows XP";
            window.open('pos-download-xp.html', '_self');
            return;
         }
         window.open('https://ceo.throo.co.kr/posdownload', '_self');
      }
   }

   return (
      <D_headerStyle>
         <div className="header">
            <div className="hContainer">
               <div className="heaerContent">
                  <SVG onClick={() => moveToPath("HOME")} src={logo} title="스루로고" style={{cursor :"pointer"}} />
                  <ul className="menu">
                     <li><Link to={WebRoute.COMPANY}>회사소개</Link></li>
                     <li><Link to={WebRoute.SERVICE}>고객센터</Link></li>
                     <li onClick={() => moveToPath("CEO")}>사장님창구</li>
                     <li><a href="https://ceo.throo.co.kr/landing" target="_blank">입점문의</a></li>
                     <li onClick={() => moveToPath("POSDOWNLOAD")}>포스설치</li>
                  </ul>
               </div>
            </div>
         </div>
      </D_headerStyle>
   )
}

export default HeaderPage;

export const D_headerStyle = styled.aside`

    /* reset */
    ul,ol,li {
        text-decoration: none;
        list-style: none;
        font-family: 'NanumSquare';
    }
    p, a, h1, h2, h3, h4, h5, h6, li, a {
        transform: skew(-0.1deg);
    }

    html,
    body {
        display: block;
        max-width: 100%;
        min-width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        font-family: 'NanumSquare';
    }

    a {
        font-family: 'NanumSquare';
        text-decoration: none;
        color: #333;
    }
    
    /* container */
    .hContainer {
        max-width: 1296px;
        height: 60px;
        margin: 0 auto;
    }

    /* header */
    .header {
        position: fixed;
        max-width: 100%;
        min-width: 100%;
        height: 60px;
        background-color: #fff;
        top: 0;
        border-bottom: 1px solid rgba(238, 238, 238, .3);
        z-index: 10;
    }
    .heaerContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    ul {
        margin: 0;
        height: 60px;
        display: flex;
        align-items: center;
    }
    .menu li {
        margin-left: 56px;
        font-size: 16px;
        font-weight: 700;
        color: #333;
        padding: 8px 10px;
        cursor: pointer;
    }
    .menu li:hover {
        background-color: rgba(196, 196, 196, .5);
        border-radius: 6px;
    }
`