import React, { useEffect } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import '../../assets/css/font.css';
import { isMobile } from "react-device-detect";

import img01 from "../../assets/img/qr_01.jpg";
import img02 from "../../assets/img/qr_02.jpg";
import img03 from "../../assets/img/qr_03.jpg";
import img04 from "../../assets/img/qr_04.jpg";
import img05 from "../../assets/img/qr_05.jpg";
import imgM01 from "../../assets/img/qr_m01.jpg";
import imgM02 from "../../assets/img/qr_m02.png";
import imgM03 from "../../assets/img/qr_m03.jpg";
import imgM04 from "../../assets/img/qr_m04.jpg";
import imgM05 from "../../assets/img/qr_m05.jpg";
import guide01 from "../../assets/img/guide01.png";
import guide02 from "../../assets/img/guide02.png";
import guide03 from "../../assets/img/guide03.png";
import guide04 from "../../assets/img/guide04.png";

import posSVG from '../../assets/svg/pos.svg';
import phoneSVG from '../../assets/svg/phone.svg';
import cardSVG from '../../assets/svg/card.svg';
import shopSVG from '../../assets/svg/shop.svg';
import carSVG from '../../assets/svg/car.svg';
import nextSVG from '../../assets/svg/next.svg';
import kakaologoSVG from '../../assets/svg/kakaologo.svg';


const QuickResponse = (oProps) => {
    const openThrooCEO = () => {
        window.open('https://ceo.throo.co.kr/selfmanage/signup', '_blank');
    }

    const openCEOKakao = () => {
        if (isMobile) {
           window.open('https://pf.kakao.com/_tzxkNK/chat', '_blank');
        } else {
           window.open('https://pf.kakao.com/_tzxkNK/chat', '_blank', "height=900,width=500,location=no,directories=no,resizable=no,status=no,toolbar=no,menubar=no");
        }
    }

    useEffect(() => {
      document.title = "배달대안 솔루션";
      document.querySelector('meta[name="keywords"]').setAttribute("content", '인비저블아이디어, 스루');
      document.querySelector('meta[name="description"]').setAttribute("content", '스루의 비전, 인비저블아이디어 소개.');
      document.querySelector('link[rel="canonical"]').setAttribute("href", 'https://throo.co.kr/qrcode');
      document.querySelector('meta[property="og:title"]').setAttribute("content", '배달대안 솔루션');
      document.querySelector('meta[property="og:description"]').setAttribute("content", '스루의 비전, 인비저블아이디어 소개.');
   }, []);

    return (
        <Style>
            <div className="quickRespnse">
                <div className="content1">
                    <img className="deskImg" src={img01} alt="사장님 매장도 밸달하시나요? 이미지" width="100%" />
                    <img className="deskImg" src={img02} alt="중개/배달 수수료 이미지" width="100%" />
                    <img className="deskImg" src={img03} alt="배달 대안 이미지" width="100%" />
                    <img className="deskImg" src={img04} alt="배달대안 솔루션, 스루 이미지" width="100%" />
                    <img className="mobileImg" src={imgM01} alt="사장님 매장도 밸달하시나요? 모바일이미지" width="100%" />
                    <img className="mobileImg" src={imgM02} alt="중개/배달 수수료 모바일이미지" width="100%" />
                    <img className="mobileImg" src={imgM03} alt="배달 대안 모바일이미지" width="100%" />
                    <img className="mobileImg" src={imgM04} alt="배달대안 솔루션, 스루 모바일이미지" width="100%" />
                </div>
                <div className="content2">
                    <div className="container">
                        <div className="content2Step">
                            <h2>사장님</h2>
                            <div className="stepContent">
                                <div className="step">
                                    <div className="stepSvg">
                                        <SVG src={posSVG} width="3vw" height='100%' title="posSVG" />
                                    </div>
                                    <div className="stepText">
                                        <h2>포스 설치</h2>
                                        <p>우리 매장에<br />스루 포스 설치하고</p>
                                        <div className="moblieSubtitle">
                                            <p>우리 매장에<br />스루 포스 설치하고</p>
                                        </div>
                                    </div>
                                </div>
                                <SVG src={nextSVG} width="6px" height="12px" title="nextSVG" />
                                <div className="step">
                                    <div className="stepSvg">
                                        <SVG src={cardSVG} width="3vw" height='100%' title="cardSVG" />
                                    </div>
                                    <div className="stepText">
                                        <h2>자동 주문 결제</h2>
                                        <p>고객 이동시간에 맞춰서<br />조리 (위치 실시간 제공)</p>
                                        <div className="moblieSubtitle">
                                            <p>고객 이동시간에<br />맞춰서 조리</p>
                                        </div>
                                    </div>
                                </div>
                                <SVG src={nextSVG} width="6px" height="12px" title="nextSVG" />
                                <div className="step">
                                    <div className="stepSvg">
                                        <SVG src={shopSVG} width="3vw" height='100%' title="shopSVG" />
                                    </div>
                                    <div className="stepText">
                                        <h2>자동 호출</h2>
                                        <p>매장 반경 설정/<br />고객 근접, 도착 시 알림</p>
                                        <div className="moblieSubtitle">
                                            <p>매장 반경 설정/<br />고객 도착 시 알림</p>
                                        </div>
                                    </div>
                                </div>
                                <SVG src={nextSVG} width="6px" height="12px" title="nextSVG" />
                                <div className="step">
                                    <div className="stepSvg">
                                        <SVG src={carSVG} width="3vw" height='100%' title="carSVG" />
                                    </div>
                                    <div className="stepText">
                                        <h2>상품 전달</h2>
                                        <p>지정한 픽업 존<br />상품 전달</p>
                                        <div className="moblieSubtitle">
                                            <p>지정한 픽업 존<br />상품 전달</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content2Step">
                            <h2>고객</h2>
                            <div className="stepContent">
                                <div className="step">
                                    <div className="stepSvg">
                                        <SVG src={phoneSVG} width="3vw" height='100%' title="phoneSVG" />
                                    </div>
                                    <div className="stepText">
                                        <h2>어플 설치</h2>
                                        <p>스루다운받고</p>
                                        <div className="moblieSubtitle">
                                            <p>스루다운받고</p>
                                        </div>
                                    </div>
                                </div>
                                <SVG src={nextSVG} width="6px" height="12px" title="nextSVG" />
                                <div className="step">
                                    <div className="stepSvg">
                                        <SVG src={cardSVG} width="3vw" height='100%' title="cardSVG" />
                                    </div>
                                    <div className="stepText">
                                        <h2>간편 결제</h2>
                                        <p>어플에서 주문결제</p>
                                        <div className="moblieSubtitle">
                                            <p>어플에서 주문결제</p>
                                        </div>
                                    </div>
                                </div>
                                <SVG src={nextSVG} width="6px" height="12px" title="nextSVG" />
                                <div className="step">
                                    <div className="stepSvg">
                                        <SVG src={shopSVG} width="3vw" height='100%' title="shopSVG" />
                                    </div>
                                    <div className="stepText">
                                        <h2>자동 호출</h2>
                                        <p>도착하면 자동호출</p>
                                        <div className="moblieSubtitle">
                                            <p>도착하면 자동호출</p>
                                        </div>
                                    </div>
                                </div>
                                <SVG src={nextSVG} width="6px" height="12px" title="nextSVG" />
                                <div className="step">
                                    <div className="stepSvg">
                                        <SVG src={carSVG} width="3vw" height='100%' title="carSVG" />
                                    </div>
                                    <div className="stepText">
                                        <h2>바로 받기</h2>
                                        <p>차 안에서 받기!</p>
                                        <div className="moblieSubtitle">
                                            <p>차 안에서 받기!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content3">
                    <div className="container">
                        <div className="content3Text">
                            <h2>배달 대행 40분 배달<br /><span>스루는 3초 배달</span></h2>
                            <p>잠시라도 정차가 가능한 매장이라면,<br />어디든 드라이브스루 할 수 있습니다.</p>
                        </div>
                    </div>
                </div>
                <div className="content4">
                    <img className="deskImg" src={img05} alt="수수료 비교 이미지" width="100%" />
                    <img className="mobileImg" src={imgM05} alt="수수료 비교 이미지" width="100%" />
                </div>
                <div className="content5">
                    <div className="container">
                        <div className="content5Text">
                            <h2>배달말고<br /><span>스루</span>하세요!</h2>
                            <p>스루는 사장님이 돈을<br />더 잘 벌기 위한 솔루션입니다.</p>
                        </div>
                        <div className="manual">
                            <div className="manualTitle">
                                <h2>어떻게 신청하나요?</h2>
                                <p>놓치고 있던 차량 이용 고객들께 적극적으로 어필해보세요!</p>
                            </div>
                            <div className="throoGuide">
                                <div className="guide">
                                    <div className="img" onClick={openThrooCEO}>
                                    <span>
                                        <img className="guideImg" src={guide01} alt="guide01" width="100%"/>
                                        <em>바로가기</em>
                                    </span>
                                    </div>
                                    <h3>3분 셀프 설치</h3>
                                    <p>빠르고 간편하게 설치하기</p>
                                </div>
                                <div className="guide">
                                    <div className="img">
                                        <img className="guideImg" src={guide02} alt="guide02" width="100%"/>
                                    </div>
                                    <h3>스루 기본제공 홍보 KIT</h3>
                                    <p>기본 제공 홍보 KIT를 드려요</p>
                                </div>
                                <div className="guide">
                                    <div className="img">
                                        <img className="guideImg" src={guide03} alt="throoex03" width="100%"/>
                                    </div>
                                    <h3>홍보물 추가 신청</h3>
                                    <p>매장 특성에 따라 선택가능!</p>
                                </div>
                                <div className="guide">
                                    <div className="img">
                                        <img className="guideImg" src={guide04} alt="throoex01" width="100%"/>
                                    </div>
                                    <h3>스루로 비용없는 추가매출!</h3>
                                    <p>매출이 UP! UP!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content6">
                    <div className="container">
                        <div className="supportPage">
                            <div className="question">
                                <h2>스루가 궁금하신 사장님이신가요?</h2>
                                <div className="deskText">
                                    <p>사장님 매장에 설치 문의를 원하시면 사장님 창구 채널을 추가하신 후 문의 글을 남겨주세요.</p>
                                    <p>또는 직접 간단하게 전자로 계약 후 매장을 입력할 수 있는 셀프 등록 시스템을 이용하실 수 있습니다.</p>
                                </div>
                                <div className="mobileText">
                                    <p>사장님 매장에 설치 문의를 원하시면 사장님 창구</p>
                                    <p>채널을 추가하신 후 문의 글을 남겨주세요.</p>
                                </div>
                            </div>
                            <div className="questionBtn">
                                <div className="kakakoBtn">
                                    <button onClick={openCEOKakao} className="throoCeo">
                                        <SVG src={kakaologoSVG} width="4vw" height='100%' title="kakaologoSVG" />
                                        <div className="buttonText">
                                            <h2>스루 사장님창구</h2>
                                            <p>설치 문의하기</p>
                                        </div>
                                    </button>
                                </div>
                                <div className="selfBtn">
                                    <button onClick={openThrooCEO} className="self">3분 셀프 설치하기</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Style>
    )
}
export default QuickResponse;

export const Style = styled.aside`
    width: 100%;
    * {
        margin:0;
        padding:0;
        box-sizing:border-box;
        font-weight: 400;
        font-family: 'Noto Sans KR', sans-serif;
    }
    body {
        width: 100%;
    }
    /* container */
    .container {
        width: 1280px;
        margin: 0 auto;
    }
    /* desk */
    .mobileImg {
        display: none;
    }

    /* content1 */
    .content1 img:nth-child(1){
        margin-top:10vh;
    }

    /* content2 */
    .moblieSubtitle p {
        display: none;
    }
    .content2 {
        margin: 10vh 0;
    }
    .content2Step:nth-child(2){
        margin-top: 8vh;
    }
    .content2Step h2 {
        font-size: 33px;
        font-weight: 700;
    }
    .stepContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 4vh;
    }
    .step {
        display: flex;
        align-items: center;
        justify-content:  space-around;
        flex: 0 0 22%;
        background-color: rgba(97, 123, 227, 0.1);
        border-radius: 12px;
        padding: 14px;
    }
    .step h2 {
        font-size: 16px;
        color: #bbb;
        font-weight: 400;
        padding-bottom: .5vh;
    }
    .step p {
        font-size: 15px;
        color: #666;
        font-weight: 400;
        line-height: 21px;
    }

    /* content3 */
    .content3Text {
        margin: 35vh 0;
        text-align: center;
        line-height: 1.3;
    }
    .content3Text h2 {
        font-size: 80px;
        font-weight: 700;
        margin-bottom: 8vh;
        color: #000;
    }
    .content3Text h2 span {
        color: #13166b;
        font-weight: 700;
    }
    .content3Text p {
        font-size: 40px;
        font-weight: 500;
    }

    /* content5 */
    .content5 {
        margin: 15vh 0;
    }
    .content5Text {
        text-align: center;
        line-height: 1.3;
    }
    .content5Text h2 {
        font-size: 80px;
        font-weight: 700;   
        margin-bottom: 5vh;
        color: #000;
    }
    .content5Text p {
        font-size: 40px;
        font-weight: 500;
    }
    .content5Text span {
        color: #13166b;
        font-weight: 700;
    }

    .manual {
        margin: 20vh 0;
    }
    .manualTitle {
        display: flex;
        align-items: center;
        margin-bottom: 2vh;
    }
    .manualTitle h2 {
        margin-right: 1vw;
        font-size: 35px;
        font-weight: 700;
        color: #000;
    }
    .manualTitle p {
        font-size: 18px;
        font-weight: 400;
        color: #565656;
    }
    .throoGuide {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .throoGuide .img {
        width: 230px;
        min-height: 230px;
        margin: 3vh 0;
        pointer-events: cursor;
    }
    .throoGuide span {
        position: relative; 
        display: block; 
    }
    .throoGuide span em {
        position: absolute; 
        left: 0; 
        bottom: 0px; 
        background: rgba(0,0,0,0.7); 
        text-align: center; 
        width: 100%; 
        min-height: 70px; 
        color: #fff; 
        opacity:0;
        font-size: 15px;
        display: flex;
        justify-content: center;
        align-items:center;
        font-style: normal;
    }
    .throoGuide span:hover em { 
        width: 230px;
        min-height: 230px;
        opacity:0.8;
        border-radius: 50%;
    }
    .throoGuide h3 {
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.38px;
        color: #3e3e3e;
    }
    .throoGuide p {
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.38px;
        color: #9f9f9f;
        margin-top: 5px;
    }

    /* content6 */
    .content6 {
        background-color: rgba(97, 123, 227, 0.1);
        padding: 10vh 0;
    }
    .supportPage {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .question h2 {
        margin-bottom: 1.5vh;
        font-size: 34px;
        font-weight: 700;
        color: #000;
    }
    .question p {
        font-size: 18px;
        font-weight: 500;
        color: #666;
        line-height: 1.4;
    }
    .kakakoBtn button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 280px;
        min-height: 75px;
        background-color: #fae100;
        border-radius: .5vw;
        outline: none;
        border: none;
        margin-bottom: 1.5vh;
    }
    button h2 {
        font-size: 25px;
        font-weight: 700;
        margin: 0 0 3px;
    }
    button p {
        color: #666;
        font-size: 15px;
    }
    .selfBtn button {
        width: 280px;
        min-height: 75px;
        background-color: #13166b;
        border-radius: .5vw;
        outline: none;
        border: none;
        color: #fff;
        font-size: 25px;
        font-weight: 700;
    }
    .mobileText {
        display: none;
    }
    
    @media (max-width: 1200px) {
        /* container */
        .container {
            width: 85vw;
            margin: 0 auto;
        }

        /* content2 */
        .content2Step h2 {
            font-size: 30px;
        }
        .stepSvg svg {
            width: 4.5vw;
        }
        .stepText h2 {
            font-size: 15px;
        }
        .stepText p {
            font-size: 12px;
            line-height: 1.3;
        }

        /* content3 */
        .content3Text {
            margin: 25vh 0;
        }

        /* content6 */
        .question h2 {
            font-size: 30px;
        }
        .question p {
            font-size: 16px;
        }
        .kakakoBtn button {
            width: 250px;
            min-height: 65px;
        }
        button h2 {
            font-size: 20px;
        }
        .selfBtn button {
            width: 250px;
            min-height: 65px;
            font-size: 22px;
        }
    }
    @media (max-width: 1100px) {
        /* content2 */
        .stepContent {
            flex-wrap: wrap;
            flex: 0 0 50%;
        }
        .stepContent svg {
            display: none;
        }
        .stepSvg svg {
            display: block;
        }
        .step {
            flex: 0 0 49%;
            margin-bottom: 1.5vh;
        }
        .stepSvg svg {
            width: 7vw;
        }
        .stepText {
            flex: 0 0 60%;
            text-align: left;
        }
        .stepText h2 {
            font-size: 18px;
        }
        .stepText p {
            font-size: 16px;
            line-height: 1.3;
        }

        /* content5 */
        .throoGuide .img {
            width: 200px;
            min-height: 200px;
        }
        .throoGuide span:hover em { 
            width: 200px;
            min-height: 200px;
        }

        /* content6 */
        .question p {
            font-size: 15px;
        }
        .kakakoBtn button {
            width: 200px;
        }
        .selfBtn button {
            width: 200px;
        }
    }
    @media (max-width: 1000px){
        /* container */
        .container {
            width: 85vw;
            margin: 0 auto;
        }

        /* mobile */
        .deskImg {
            display: none;
        }
        .mobileImg {
            display: inline;
        }

        /* content1 */
        .content1 img:nth-child(5){
            margin-top:10vh;
        }

        /* content3 */
        .content3Text h2 {
            font-size: 65px;
            margin-bottom: 5vh;
        }
        .content3Text p {
            font-size: 30px;
        }

        /* content5 */
        .content5Text h2 {
            font-size: 65px;  
            margin-bottom: 3vh;
        }
        .content5Text p {
            font-size: 30px;
        }
        .manualTitle h2 {
            font-size: 30px;
        }
        .manualTitle p {
            font-size: 15px;
        }
        .throoGuide .img {
            width: 160px;
            min-height: 160px;
        }
        .throoGuide span:hover em { 
            width: 160px;
            min-height: 160px;
        }

        /* content6 */
        .supportPage {
            flex-direction: column;
            text-align: center;
        }
        .question h2 {
            font-size: 35px;
        }
        .question p {
            font-size: 25px;
            color: #000;
            font-weight: 600;
        }
        .deskText {
            display: none;
        }
        .mobileText {
            display: block;
            padding: 1vh 0 4vh 0;
        }
        .kakakoBtn button {
            width: 350px;
            min-height: 80px;
        }
        .kakakoBtn button svg {
            width: 8vw;
            margin-right: 2vw;
        }
        button h2 {
            font-size: 30px;
        }
        button p {
            font-size: 15px;
        }
        .selfBtn button {
            width: 350px;
            min-height: 80px;
            font-size: 30px;
        }
    }
    @media (max-width: 780px){
        /* content1 */
        .content1 img:nth-child(5){
            margin-top:7vh;
        }

        /* content2 */
        .stepText h2 {
            font-size: 16px;
        }
        .stepText p {
            display: none;
        }
        .moblieSubtitle p {
            display: block;
        }

        /* content3 */
        .content3Text {
            margin: 18vh 0;
        }
        .content3Text h2 {
            font-size: 55px;
            margin-bottom: 3vh;
        }
        .content3Text p {
            font-size: 25px;
        }


        /* content5 */
        .content5Text h2 {
            font-size: 55px;
        }
        .content5Text p {
            font-size: 25px;
            font-weight: 500;
        }
        .manualTitle p {
            display: none;
        }

        .throoGuide { 
            flex-wrap: wrap;
        }
        .throoGuide .img {
            width: 250px;
            min-height: 250px;
        }
        .throoGuide span em {
            font-size: 23px;
        }
        .throoGuide span:hover em { 
            width: 250px;
            min-height: 250px;
            opacity:0.8;
            border-radius: 50%;
        }
        .throoGuide h3 {
            font-size: 18px;
            color: #000;
            text-align: center;
        }
        .throoGuide p {
            display: none;
        }
    }
    @media (max-width: 620px){
        /* container */
        .container {
            width: 80vw;
            margin: 0 auto;
        }

        /* content2 */
        .stepSvg svg {
            width: 6.5vw;
        }
        .stepText h2 {
            font-size: 15px;
        }
        .stepText p {
            font-size: 12px;
            line-height: 1.3;
        }

        /* content3 */
        .content3Text h2 {
            font-size: 50px;
        }
        .content3Text p {
            font-size: 25px;
        }

        /* content5 */
        .throoGuide .img {
            width: 190px;
            min-height: 190px;
        }
        .throoGuide span:hover em { 
            width: 190px;
            min-height: 190px;
        }

        /* content6 */
        .question h2 {
            font-size: 28px;
        }
        .question p {
            font-size: 20px;
        }
        .kakakoBtn button svg {
            width: 10vw;
        }
        .kakakoBtn button {
            width: 300px;
            border-radius: 2vw;
        }
        .selfBtn button {
            width: 300px;
            border-radius: 2vw;
        }
    }
    @media (max-width: 530px){
        /* content3 */
        .content3Text {
            margin: 15vh 0;
        }
        .content3Text h2 {
            font-size: 40px;
        }
        .content3Text p {
            font-size: 20px;
        }

        /* content5 */
        .content5 {
            margin: 10vh 0;
        }
        .content5Text h2 {
            font-size: 45px;
        }
        .content5Text p {
            font-size: 25px;
        }

        .manual {
            margin: 13vh 0;
        }
        .throoGuide .img {
            width: 160px;
            min-height: 160px;
            padding: 3vh 0;
            margin: 0 auto;
        }
        .throoGuide span em {
            font-size: 14px;
        }
        .throoGuide span:hover em { 
            width: 160px;
            min-height: 160px;
        }
    }
    @media (max-width: 500px){
        /* content2 */
        .stepSvg svg {
            width: 8vw;
        }
        .stepText h2 {
            font-size: 15px;
        }
        .stepText p {
            font-size: 11px;
            line-height: 1.3;
        }

        /* content6 */
        .question h2 {
            font-size: 25px;
            margin-bottom: 0vh;
        }
        .question p {
            font-size: 17px;
        }
    }
    @media (max-width: 480px){
        /* content2 */
        .content2Step h2 {
            font-size: 20px;
        }
        .stepText h2 {
            font-size: 13px;
        }

        /* content5 */
        .throoGuide .img {
            width: 140px;
            min-height: 140px;
        }
        .throoGuide span:hover em { 
            width: 140px;
            min-height: 140px;
        }
        .guide h3 {
            font-size: 16px;
        }
    }
    @media (max-width: 445px){
        /* content2 */
        .content2 {
           margin: 10vh 0;
        }
        .content2Step:nth-child(2){
            margin-top: 5vh;
        }
        .stepText p {
            font-size: 9px;
        }

        /* content3 */
        .content3Text {
            margin: 10vh 0;
        }
        .content3Text h2 {
            font-size: 30px;
            margin-bottom: 1.5vh;
        }
        .content3Text p {
            font-size: 18px;
        }

        /* content5 */
        .manualTitle h2 {
            font-size: 22px;
        }
        .content5Text h2 {
            font-size: 35px;
            margin-bottom: 1.5vh;
        }
        .content5Text p {
            font-size: 20px;
        }

        /* content6 */
        .question h2 {
            font-size: 23px;
        }
        .question p {
            font-size: 15px;
        }
        .kakakoBtn button svg {
            width: 15vw;
        }
        .kakakoBtn button {
            width: 250px;
            padding : 0 10px;
        }
        .selfBtn button {
            width: 250px;
        }
        .buttonText h2 {
            font-size: 18px;
        }
        .selfBtn button {
            font-size: 22px;
        }
    }
    @media (max-width: 410px){
        .container {
            width: 90vw;
        }
        .stepText h2 {
            font-size: 9px;
        }
        .stepText p {
            font-size: 8px;
        }
        .guide h3 {
            font-size: 14px;
        }
    }

`;